import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';
import { InfoComponent } from './components/info/info.component';
import { LanguageComponent } from './components/language/language.component';
import { LogComponent } from './components/log/log.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TextsComponent } from './components/texts/texts.component';
import { UrlsComponent } from './components/urls/urls.component';
import { ToolsComponent } from './tools.component';

export const ToolsRouting: Routes = [
    {
        path: ':idsite/tools', component: ToolsComponent, canActivate: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'activity-log', canActivate: [AuthGuard] },
            { path: 'activity-log', component: LogComponent, canActivate: [AuthGuard] },
            { path: 'site-information', component: InfoComponent, canActivate: [AuthGuard] },
            { path: 'site-languages', component: LanguageComponent, canActivate: [AuthGuard] },
            { path: 'site-settings', component: SettingsComponent, canActivate: [AuthGuard] },
            { path: 'translations', component: TextsComponent, canActivate: [AuthGuard] },
            { path: 'redirects', component: UrlsComponent, canActivate: [AuthGuard] },
        ],
    },
];
