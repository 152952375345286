import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PanelDataModel } from 'app/core/models/panelData.model';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';

@Component({
    selector: 'app-panel-form-module-highlight',
    templateUrl: './panel-form-module-highlight.component.html',
})
export class PanelFormModuleHighlightComponent implements OnInit {
    @Input() control: AbstractControl;
    @Input() element: PanelDataModel;
    @Input() disabledFields: boolean = false;
    @Output() updatedAll: EventEmitter<any> = new EventEmitter<any>();
    public list: any[] = [];

    constructor(private _api: ApiService, private _st: SingeltonService) {
    }

    ngOnInit(): void {
        const params: HttpParams = new HttpParams().append('filter_site_id', this._st.idSite);
        this._api.get(this.element.attributes.apiSource, { params: params }).subscribe(res => {
            this.list = res.filter(item => !item.page || (item.page && item.page.active)).map(item => {
                item.multi_option = this.element.attributes.multi_option;
                return item;
            });

            this.list = this.list.map((item, i) => {
                const a: any[] = this.control.value.filter(a => a.id === item.id);
                item.active = !!a.length;
                item.source = this.element.attributes.sourceId;
                return item;
            });
        });
    }

    onUpdatedAll(value: any[]): void {
        this.updatedAll.emit(value);
    }

    updateList(event, multiple: boolean): void {
        if (!this.disabledFields) {
            let isDeleting: boolean = false;
            this.control.value.map(a => event.id === a.id ? isDeleting = true : '');

            if (isDeleting && multiple) {
                const newArr: any[] = [];
                this.control.value.map(a => event.id === a.id ? '' : newArr.push(a));
                this.onUpdatedAll(newArr);
            } else {
                const newArr: any[] = multiple ? this.control.value.slice() : [];
                newArr.push({ id: event.id, sourceId: this.element.attributes.sourceId });
                this.onUpdatedAll(newArr);
            }
        }
    }

}
