import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDataModel } from 'app/core/models/loginData.model';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

    constructor(
        private _st: SingeltonService,
        private _api: ApiService,
        private _router: Router,
        private _session: SessionService,
    ) {
    }

    login(res: LoginDataModel): void {
        this._api.setToken(res);
        this._router.navigate(['select-site']);
    }

    logout(redirect: boolean = true): void {
        this._session.setAlertOff();
        this._st.resetAll();
        this._session.clearSession();

        if (redirect) {
            this._router.navigate(['login']);
        }
    }


}
