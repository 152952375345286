import { Component, OnInit } from '@angular/core';
import { AlertPopupService } from '../../services/alert-popup.service';

@Component({
    selector: 'app-alert-popup',
    templateUrl: './popup.component.html',
})
export class PopupComponent implements OnInit {

    public showPopup: Boolean = false;
    public data: Object;

    constructor(private alertPopupService: AlertPopupService) {
        this.alertPopupService.dataObservable.subscribe((data) => {

            switch (data.action) {
                case 'show':
                    this.data = data.data;
                    this.showPopup = true;
                    break;

                case 'hide':
                    this.closePopup();
                    break;
            }

        });
    }

    ngOnInit() {
    }

    public closePopup() {
        this.showPopup = false;
        this.data = {};
    }

    public buttonClicked(answer) {
        this.alertPopupService.answer(answer);
        this.closePopup();
    }
}
