import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PanelDataModel } from 'app/core/models/panelData.model';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ApiService } from 'app/core/services/api.service';
import { RightPanelService } from 'app/core/services/right-panel.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';

@Component({
    selector: 'app-panel-content',
    templateUrl: './panel-content.component.html',
})
export class PanelContentComponent implements OnInit {
    @Input() data: any;
    @Input() showDelete: boolean = false;
    @Input() showSave: boolean = true;
    @Output() delete: EventEmitter<void> = new EventEmitter<void>();
    public disableFields: boolean = false;
    public finalData: PanelDataModel;
    public form: FormGroup;
    public selectedLang: string = null;

    constructor(
        private _alert: AlertPopupService,
        private _api: ApiService,
        private _lng: TranslateService,
        private _rp: RightPanelService,
        private _st: SingeltonService,
        private _utils: UtilsService,
    ) {
    }

    ngOnInit(): void {
        this.finalData = JSON.parse(JSON.stringify(this.data.data));
        this.form = this._rp.buildForm(this.finalData);
        this.disableFields = this.data && this.data.list && this.data.list.type !== 'listPanel';
        if (this.showSave) {
            if( this.data && this.data.list && this.data.list.type !== 'listPanel' ) {
                this.showSave = this.data.especial;
            } else {
                this.showSave = !this.disableFields;
            }
        }
    }

    onClose(): void {
        this._st.setPanel(null);
    }

    onChangeLang(event: string): void {
        this.selectedLang = event;
    }

    onUpdateValue(event: any, control: any, keyChain: string, lang: string = null, multiple: boolean = false): void {
        multiple
            ? control.controls[event.key].setValue(event.value)
            : control.setValue(event);


        // Update value on finalData
        for (let i = 0; i < this.finalData.elements.length; i++) {
            if (this.finalData.elements[i].keyChain === keyChain) {
                multiple
                    ? this.finalData.elements[i].value[lang || this.selectedLang][event.key] = event.value
                    : this.finalData.elements[i].value[lang || this.selectedLang] = event;
                break;
            }
        }
    }

    onUpdateAllValues(event: any, control: any, keyChain: string): void {
        this._st.allData.languages.map(lng => this.onUpdateValue(
            event,
            control.controls[lng.language],
            keyChain, lng.language,
            event.hasOwnProperty('key'),
        ));
    }

    onUpdateGroup(event: { value: string, keyChain: string }, control: any, keyChain: string): void {
        control.controls[event.keyChain].controls[this.selectedLang].setValue(event.value);

        // Update value on finalData
        for (let i = 0; i < this.finalData.elements.length; i++) {
            if (this.finalData.elements[i].keyChain === keyChain) {
                for (let a = 0; a < this.finalData.elements[i].elements.length; a++) {
                    if (this.finalData.elements[i].elements[a].keyChain === event.keyChain) {
                        this.finalData.elements[i].elements[a].value[this.selectedLang] = event.value;
                        break;
                    }
                }
                break;
            }
        }
    }

    onDelete(): void {
        if (this.data.list) {
            const fun = resp => {
                if (resp) {
                    const newElements: any[] = [];
                    this.data.list.elements.map(item => {
                        if (item.keyChain !== this.data.data.keyChain) {
                            newElements.push(item);
                        }
                    });
                    this.data.list.elements = newElements.slice();

                    this._api.put(`pages/${ this.data.page }/content/${ this.data.ref }`, this.data.list)
                        .subscribe(() => {
                            this._st.setRefreshIframe();
                            this._st.setPanel(null);
                            this.form.reset();
                        });
                }
            };

            if (this.data.list.type !== 'listPanel') {
                this._lng.get('panels').subscribe(r => this._alert.show({
                    title: r.delete_highlight,
                    content: r.delete_highlight_text,
                    buttonConfirmType: 'delete',
                    buttonConfirmText: r.delete_highlight_confirm,
                    buttonCancelType: 'ghost',
                    buttonCancelText: r.delete_highlight_cancel,
                }, fun.bind(this)));
            } else {
                this._lng.get('panels').subscribe(res => this._alert.show({
                    title: res.page_edit.delete.title,
                    content: res.page_edit.delete.content,
                    buttonConfirmType: 'delete',
                    buttonConfirmText: res.page_edit.delete.buttonConfirmText,
                    buttonCancelType: 'ghost',
                    buttonCancelText: res.page_edit.delete.buttonCancelText,
                }, fun.bind(this)));
            }
        } else {
            this.delete.emit();
        }
    }

    onSave(): void {
        const obs: string = this.data.src
            ? `${ this.data.src }/${ this.data.ref }/highlight`
            : `pages/${ this.data.page }/content/${ this.data.ref }`;

        if (this.data.list) {
            const keyChains = this.data.list.elements.map(element => element.keyChain);
            const position = ( !this.finalData.keyChain ) ? -1 : keyChains.indexOf(this.finalData.keyChain);
            if ( position == -1 ) {
                this.data.list.elements.push(this.finalData);
            } else {
                this.data.list.elements[position] = JSON.parse(JSON.stringify(this.finalData));
            }

            this.data.list.page_id = this.data.page;
            this.finalData = JSON.parse(JSON.stringify(this.data.list));
        }

        this._api.put(obs, this.finalData).subscribe((result) => {
            this.finalData = null;
            this._st.setRefreshIframe();
            this._st.setPanel(null);
            this.form.reset();
        });
    }

    log() {
        console.log('form:', this.form);
        console.log('data:', this.data);
    }
}
