import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { AuthRoutes } from './auth.routing';
import { LoginComponent } from './components/login/login.component';
import { SelectSiteComponent } from './components/select-site/select-site.component';


@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        ReactiveFormsModule,
        RouterModule.forChild(AuthRoutes),
        TranslateModule,
    ],
    declarations: [
        LoginComponent,
        SelectSiteComponent,
    ],
    exports: [
        RouterModule,
    ],
})
export class AuthModule {
}
