import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterModel } from 'app/core/models/filter.model';

@Component({
    selector: 'app-table-paginator',
    templateUrl: './table-pagination.component.html',
})
export class TablePaginationComponent {
    @Input() data: FilterModel;
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() prev: EventEmitter<void> = new EventEmitter<void>();

    onNext() {
        this.next.emit();
    }

    onPrev() {
        this.prev.emit();
    }

}
