import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModuleModel } from 'app/core/models/allData.model';
import { SingeltonService } from 'app/core/services/singelton.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-media-tabs',
    templateUrl: './media-tabs.component.html',
})
export class MediaTabsComponent implements OnDestroy {
    public list: { route: string, name: string }[] = [];
    protected $subs: Subscription[] = [];

    constructor(private _route: ActivatedRoute, private _st: SingeltonService) {
        this.$subs.push(this._st.getAllDataObs().subscribe(res => {
            this.list = [];
            const media: ModuleModel = res.modules.find(item => item.key === 'media');
            if (media) {
                const pageList: ModuleModel[] = media && media.active ? media['sub-modules'] : [];
                pageList.filter(item => item['active']).map((page: ModuleModel) => {
                    if (page.key === 'images') {
                        this.list.push({ route: `/${ this._st.idSite }/media/img`, name: 'media.tabs.images' });
                    } else if (page.key === 'videos') {
                        this.list.push({ route: `/${ this._st.idSite }/media/video`, name: 'media.tabs.videos' });
                    } else if (page.key === 'documents') {
                        this.list.push({ route: `/${ this._st.idSite }/media/doc`, name: 'media.tabs.documents' });
                    }
                });
            }
        }));
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }
}
