import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-feed-instagram',
    templateUrl: './feed-instagram.component.html',
})
export class FeedInstagramComponent implements OnInit {
    @Input('item') item: any;

    ngOnInit(): void {
    }
}
