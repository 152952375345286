import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from 'app/core/services/api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MediaService {

    constructor(
        private _api: ApiService,
        private _sanitaizer: DomSanitizer,
    ) {
    }

    getFilteredItems(idSite: string, type: string, tags: any[], page: string, pendingFilter: boolean): Observable<any> {
        let params: HttpParams = new HttpParams()
            .append('filter_site_id', idSite)
            .append('filter_type', type);

        if (tags.length > 0) {
            const tagString = tags.join();
            params = params.append('filter_tags', tagString);
        }
        if (page) {
            params = params.append('page', page);
        }

        if (pendingFilter) {
            params = params.append('filter_pending', '1');
        }

        return this._api.get('media', { params: params });
    }

    getItems(idSite: string, params: HttpParams): Observable<any> {
        return this._api.get('media', { params: params });
    }

    getSiteTags(idSite: string): Observable<any> {
        const params = new HttpParams()
            .append('filter_site_id', idSite);

        return this._api.get('media/tags', { params: params });
    }

    createMediaItem(data, idSite: string, type: string): Observable<any> {
        let model: any;

        switch (type) {
            case 'image':
                model = { site_id: idSite, type: type, data: { upload_id: data.id, name: data.name, title: {}, alt: {} } };
                break;

            case 'video':
                model = { site_id: idSite, type: type, data: data.data, tags: data['tags'], pending: data.pending };
                break;

            case 'document':
                model = { site_id: idSite, type: type, data: { upload_id: data.id, name: data.name, title: {}, alt: {} }, tags: data['tags'] };
                break;
        }

        return this._api.post('media', model);
    }

    updateMediaItem(data, type: string): Observable<any> {
        const model: any = {
            site_id: data['site_id'],
            type: type,
            pending: data['pending'],
            data: data['data'],
            tags: data['tags'],
        };

        return this._api.put('media/' + data['id'], model);
    }

    deleteMediaItem(mediaId): Observable<any> {
        return this._api.delete('media/' + mediaId);
    }

    resetActiveTags(tags): any {
        tags.forEach(tag => tag.active = false);
        return tags;
    }

    generateIframeVideoUrl(item): any {
        item.data['urlparsed'] = this.parseIframeUrl(item.data.video_code, item.data.source);
        return item;
    }

    parseIframeUrl(video_code, type, controls = 0, autoplay = 1): SafeResourceUrl {
        return this._sanitaizer.bypassSecurityTrustResourceUrl(type === 'youtube'
            ? `https://www.youtube.com/embed/${ video_code }?autoplay=${ autoplay }&loop=1&controls=${ controls }&showinfo=0`
            : `https://player.vimeo.com/video/${ video_code }?autoplay=${ autoplay }&loop=1&autopause=0&controls=${ controls }&showinfo=0`,
        );
    }
}
