import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tabs-element',
    templateUrl: './tabs-element.component.html',
})
export class TabsElementComponent {
    @Input('title') title: string;
    @Input() active: boolean = false;
}
