import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UtilsService } from 'app/core/services/utils.service';

@Component({
    selector: 'app-panel-form-date',
    templateUrl: './panel-form-date.component.html',
})
export class PanelFormDateComponent implements OnInit {
    @Input() control: AbstractControl;
    @Input() disabledFields: boolean = false;
    @Input() element: any;
    @Output() updatedAll: EventEmitter<string> = new EventEmitter<string>();
    public model_data: any;
    public dpOptions: any;

    constructor(private _utils: UtilsService) {
        this.dpOptions = {
            dateFormat: 'dd/mm/yyyy',
            showClearDateBtn: false,
            editableDateField: false,
            openSelectorOnInputClick: true,
            openSelectorTopOfInput: true,
        };
    }

    ngOnInit(): void {
        if (this.control.value) {
            this.model_data = this._utils.parseStringDatepicker(this.control.value);
        }
    }

    onUpdateDate(event): void {
        const v: string = this._utils.parseDatepicker(event);
        this.updatedAll.emit(v);
    }

}
