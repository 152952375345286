import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterConfig } from 'angular2-toaster';
import { LoadingService } from 'app/core/services/loading.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    public agendaShow: boolean = false;
    public configPanel: boolean = false;
    public dataPanel: any = false;
    public docShow: boolean = false;
    public enviromentData: any = null;
    public imgShow: boolean = false;
    public isLoading: boolean = false;
    public toasterconfig: ToasterConfig;
    public videoShow: boolean = false;

    constructor(
        private _lng: TranslateService,
        private _loading: LoadingService,
        private _session: SessionService,
        private _st: SingeltonService,
    ) {
        this._lng.setDefaultLang('es');

        this._loading.getLoadingObs().pipe(delay(1)).subscribe((res: boolean) => this.isLoading = res);

        this._st.getPanelObs().subscribe(res => this.dataPanel = res === null ? false : res);
        this._st.getConfigPanelObs().subscribe(res => this.configPanel = res !== null);
        this._st.getImgObs().subscribe(res => this.imgShow = res !== null);
        this._st.getVideoObs().subscribe(res => this.videoShow = res !== null);
        this._st.getDocObs().subscribe(res => this.docShow = res !== null);
        this._st.getAgendaObs().subscribe(res => this.agendaShow = res !== null && res.hasOwnProperty('showST'));
        this._st.getEnviromentObs().subscribe(res => {
            this.enviromentData = res && (res.hasOwnProperty('landings') || res.hasOwnProperty('points') || res.hasOwnProperty('routes'))
                ? res
                : null;
        });


        this.toasterconfig = new ToasterConfig({
            showCloseButton: true,
            timeout: 2500,
            limit: 1,
            animation: 'fade',
        });

        this._session.setAlertOn();
    }
}
