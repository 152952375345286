import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-panel-form-map',
    templateUrl: './panel-form-map.component.html',
})
export class PanelFormMapComponent {
    @Input() control: FormGroup;
    @Input() disabledFields: boolean = false;
    @Input() element: any;
    @Output() updatedAll: EventEmitter<any> = new EventEmitter<any>();

    onUpdatedAll(key: string, value: string): void {
        this.updatedAll.emit({ key: key, value: value });
    }

    onDrag(event: any): void {
        this.onUpdatedAll('lat', event.coords.lat);
        this.onUpdatedAll('lng', event.coords.lng);
    }
}
