import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AllDataModel } from 'app/core/models/allData.model';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
})
export class NewsComponent implements OnDestroy {
    public pageList: any[] = [];
    public showTabs: boolean = true;
    public siteLangs: any[] = [];
    protected $subs: Subscription[] = [];

    constructor(
        private _lng: TranslateService,
        private _route: ActivatedRoute,
        private _st: SingeltonService,
        private _utils: UtilsService,
    ) {
        this._st.resetAll();
        this._st.setIdSite(this._route.snapshot.paramMap.get('idsite'));
        this.$subs.push(this._st.getShowModuleObs().subscribe(res => this.showTabs = res));
        this._utils.getAlldata(this._st.idSite).subscribe((res: AllDataModel) => {
            this.siteLangs = res['languages'];
            this._st.setAllData(res);
        });

        this._lng.get('news').subscribe(
            res => this.pageList.push({ 'ref': 'news-list', 'title': res.pages.news }),
        );
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }
}
