import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { ProfilePasswordComponent } from './components/profile-password/profile-password.component';
import { ProfileSiteComponent } from './components/profile-site/profile-site.component';
import { ProfileUserComponent } from './components/profile-user/profile-user.component';
import { ProfileComponent } from './profile.component';
import { ProfileRouting } from './profile.routing';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(ProfileRouting),
        TranslateModule,
    ],
    declarations: [
        ProfileComponent,
        ProfileUserComponent,
        ProfilePasswordComponent,
        ProfileSiteComponent,
    ],
    exports: [
        RouterModule,
    ],
})
export class ProfileModule {
}
