import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-text-section-lng',
    templateUrl: './text-section-lng.component.html',
})
export class TextSectionLngComponent implements OnInit {
    @Input() list: any[];
    public sectionList: any[] = [];
    public section: string = null;
    public lngCopy: string = null;
    public lngSelected: string = null;
    public lngList: any[] = [];
    public sectionPos: number = null;
    public edited: boolean = false;

    constructor(
        private _api: ApiService,
        private _st: SingeltonService,
        private _toast: ToastService,
        private _lng: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.getSectionList();
    }

    onSelectSection(pos: any): void {
        if (this.sectionList[pos]) {
            this.edited = false;
            this.sectionPos = pos;
            const params: HttpParams = new HttpParams()
                .append('filter_site_id', this._st.idSite);

            this._api.get(`translations/${ this.sectionList[this.sectionPos].value }`, { params: params })
                .subscribe(data => this.lngList = data);
        }

    }

    onSave(): void {
        const model: any = {
            site_id: this._st.idSite,
            texts: this.lngList,
        };

        this._api.put(`translations/${ this.sectionList[this.sectionPos].value }`, model)
            .pipe(
                mergeMap(() => this._lng.get('message.translations')),
            )
            .subscribe(res => {
                this._toast.showMessage(res);
                this.onSelectSection(this.sectionPos);
            });
    }

    private getSectionList(): void {
        const params: HttpParams = new HttpParams()
            .append('filter_site_id', this._st.idSite);

        this._api.get('translations/sections', { params: params })
            .subscribe(data => this.sectionList = data);
    }

}
