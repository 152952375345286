import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllDataModel } from 'app/core/models/allData.model';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.component.html',
})
export class AgendaComponent implements OnDestroy {
    public modules: any[] = [];
    public showTabs: boolean = true;
    public siteLangs: any[] = [];
    protected $subs: Subscription[] = [];

    constructor(
        private _route: ActivatedRoute,
        private _st: SingeltonService,
        private _utils: UtilsService,
    ) {
        this._st.resetAll();

        this.$subs.push(this._st.getShowModuleObs().subscribe(res => this.showTabs = res));

        this._st.setIdSite(this._route.snapshot.paramMap.get('idsite'));
        this._utils.getAlldata(this._st.idSite).subscribe((res: AllDataModel) => {
            this.siteLangs = res['languages'];
            const leads = res.modules.find(item => item.key === 'agenda');
            this.modules = leads && leads.active ? leads['sub-modules'] : [];
            this.modules = this.modules.filter(item => item['active']);
            this._st.setAllData(res);
        });
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }
}
