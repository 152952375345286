import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel } from 'app/core/models/allData.model';
import { FilterModel } from 'app/core/models/filter.model';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { DragulaService } from 'ng2-dragula';
import { Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-enviroment-list',
    templateUrl: './enviroment-list.component.html',
})
export class EnviromentListComponent implements OnInit, OnDestroy {
    @Input() select: boolean = false;
    @Input('cancelText') cancelText: string;
    @Input('categoriesURL') categoriesURL: string;
    @Input('content') content: string;
    @Input('newBtn') newBtn: string;
    @Input('placeHoderSearch') placeHoderSearch: string;
    @Input('selectTxt') selectTxt: string;
    @Input('type') type: string;
    @Input() dataCallback: any;
    @Input() lang: any;
    public activeElements: number = 0;
    public categories: any[] = [];
    public configObs$: Subscription = new Subscription();
    public data: any[] = [];
    public defaultLang: string;
    public dragulaObservable;
    public filtered: boolean = false;
    public formFilters: FormGroup;
    public generalLang: any;
    public pagination: FilterModel;
    public siteDomain;
    public siteLangs;
    public urlPreview: any = null;
    public zones: any[] = [];

    constructor(
        private _api: ApiService,
        private _dragula: DragulaService,
        private _fb: FormBuilder,
        private _lng: TranslateService,
        private _modal: AlertPopupService,
        private _session: SessionService,
        private _st: SingeltonService,
        private _utils: UtilsService,
    ) {
        this._lng.get('general').subscribe(res => this.generalLang = res);

        this.formFilters = this._fb.group({
            name: [null],
            categories: [null],
            zones: [null],
        });

        this._utils.getAlldata(this._st.idSite).subscribe(data => {
            this.siteLangs = data.languages;
            this.siteDomain = data.domain;
            const a: LanguageModel[] = data.languages.filter(l => l.default);
            this.defaultLang = a[0].language;
        });
    }

    ngOnInit(): void {
        this.getData();
        this.getCategoriesObs().pipe(mergeMap(() => this.getZonesObs())).subscribe();

        this.initDragula(['list']);
        this.dragulaObservable = this._dragula.dropModel('list').subscribe((value) => this.onDropModel(value));
    }

    ngOnDestroy(): void {
        this._dragula.destroy('list');
        this.dragulaObservable.unsubscribe();
        this.configObs$.unsubscribe();
    }

    getDataZones(item): string {
        if (item.zones) {
            const zones = item.zones.map((zon) => {
                return zon.name;
            });
            return zones.join(', ');
        }
        return '';
    }

    getDataCategories(item): string {
        if (item.categories) {
            const categorization = item.categories.filter(cat => cat.type === 'categories');
            const categories = categorization.map(zon => zon.label);
            return categories.join(', ');
        }
        return '';
    }

    onSearch(): void {
        this.pagination = null;
        this.getData();
    }

    onReset(): void {
        this.formFilters.reset();
        this.onSearch();
    }

    onNext(): void {
        this.pagination.current_page++;
        this.getData(true);
    }

    onCreate() {
        this._api.post(`environment/${ this.type }`, { site_id: this._st.idSite }).subscribe(data => {
            this._st.setConfigPanel({ ...data, alternates: data.urls, persistData: true });
            this.configObs$ = this._st.getConfigPanelObs().subscribe(res => {
                data.urls = res.slice(2);
                this.configObs$.unsubscribe();
                this._st.setConfigPanel(null);
                this.onEdit(data);
            });
        });
    }

    onEdit(data): void {
        const urlPreviewTemp = {};
        const lng = this._session.getLng();
        const filter = data.urls.filter(url => url.language === lng);
        if (filter.length) {
            data.urls.map(url => urlPreviewTemp[url.language] = url);
        }
        this._api.get(`environment/${ this.type }/${ data['id'] }/highlight`).subscribe(res => {
            this._st.setShowModule(false);
            this.urlPreview = urlPreviewTemp;
            this._st.setPanel({
                data: JSON.parse(JSON.stringify(res)),
                ref: res.source.split('|').pop(),
                src: `environment/${ this.type }`,
            });
        });
    }

    onClickPreview(event: any): void {
        this._st.setPanel(event);
    }

    onBackPreview(): void {
        this._st.setShowModule(true);
        this.getData();
        this.urlPreview = null;
    }

    onSelect(item): void {
        const special: boolean = this._st.enviroment && this._st.enviroment.special;
        this._api.get(`environment/${ this.type }/${ item.id }/highlight`)
            .subscribe(res => this._st.setEnviroment({ res: res, special: special }));
    }

    onBack(): void {
        this._st.setEnviroment(null);
    }

    onActivate(item, status): void {
        item.page.active = status;
        this._api.put(`pages/${ item.page.id }`, item.page).subscribe();
    }

    onRemove(item): void {
        const fun = answer => {
            if (answer) {
                this._api.delete(`environment/${ this.type }/${ item.id }`)
                    .subscribe(() => this.data = this.data.filter(e => e.id !== item.id));
            }
        };

        this._modal.show({
            title: this.lang.delete.title,
            content: this.lang.delete.content,
            buttonConfirmType: 'delete',
            buttonConfirmText: this.lang.delete.buttonConfirmText,
            buttonCancelType: 'ghost',
            buttonCancelText: this.lang.delete.buttonCancelText,
        }, fun.bind(this, item));

    }

    showAlertPopup(): void {
        this._modal.show({
            content: this.lang.disabled_reorder.content,
            buttonCancelType: 'ghost',
            buttonCancelText: this.lang.disabled_reorder.buttonCancelText,
        }, false);
    }

    private getData(concat: boolean = false): void {
        const values: any = this.formFilters.getRawValue();
        let params = new HttpParams().append('filter_site_id', this._st.idSite);

        if (values.name) {
            params = params.append('filter_name', values.name);
        }
        if (values.categories) {
            params = params.append('filter_categories', values.categories);
        }
        if (values.zones) {
            params = params.append('filter_zones', values.zones);
        }
        if (concat) {
            params = params.append('page', this.pagination.current_page.toString());
        }

        this._api.get(`environment/${ this.type }`, { params: params }).subscribe(data => {
            this.activeElements = 0;
            this.pagination = data;
            this.data = concat ? this.data.concat(data.data) : data.data;
            data.data.forEach(element => element.page.active ? this.activeElements++ : null);
        });
    }

    private getZonesObs(): Observable<any> {
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        return this._api.get('zones', { params: params }).pipe(
            map(data => this.zones = data['data']),
        );

    }

    private getCategoriesObs(): Observable<any> {
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        return this._api.get(`categories/environment/${ this.categoriesURL }`, { params: params }).pipe(
            map(data => {
                const categories = data.find((categorization) => categorization.name === 'categories');
                this.categories = categories ? categories.categories : [];
            }),
        );

    }

    private initDragula(list: string[]): void {
        list.forEach(name => {
            if (this._dragula.find(name) !== undefined) {
                this._dragula.destroy(name);
            }

            this._dragula.createGroup(name, {
                moves: (el, source, handle): any => {
                    if (this.filtered && handle.classList.contains('c-icon--move')) {
                        this.showAlertPopup();
                    } else {
                        return handle.classList.contains('c-icon--move');
                    }
                },
            });
        });
    }

    private onDropModel(value): void {
        const positions = value.sourceModel.map((item, index) => {
            return { id: item.id, position: index };
        });
        const model = {
            site_id: this._st.idSite,
            positions: positions,
        };

        this._api.put(`environment/${ this.type }/reorder`, model)
            .subscribe(() => {
            }, () => {
                this.data = this.data.sort(((a, b) => a['position'] - b['position']));
            });

    }
}
