import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { forkJoin, Observable } from 'rxjs';
import { MediaModel } from '../../models/media.model';
import { MediaService } from '../../services/media.service';

@Component({
    selector: 'app-media-doc',
    templateUrl: './media-doc.component.html',
})
export class MediaDocComponent implements OnInit, OnDestroy {
    @Input() select: boolean = false;
    public activeTags: any[] = [];
    public defaultLang: string;
    public docList: MediaModel[] = [];
    public showFilter: boolean = false;
    public siteSelected: string = '';
    public tagList: any = [];
    public totalRes: any;
    public dataDoc: any = null;

    constructor(
        private _service: MediaService,
        private _session: SessionService,
        private _st: SingeltonService,
        private _toast: ToastService,
    ) {
    }

    ngOnInit(): void {
        this.showFilter = false;
        setTimeout(() => {
            this.showFilter = true;
        });
        this.defaultLang = this._session.getLng();
        this._service.getSiteTags(this._st.idSite).subscribe(res => this.tagList = res);
        this.siteSelected = this._st.idSite;
        this.getFilteredDocuments(this.siteSelected, this.activeTags, 1, false);
    }

    ngOnDestroy(): void {
        document.body.style.overflow = 'auto';
    }

    onFilter(filter: { params: HttpParams, concat: boolean }): void {
        this._service.getItems(this._st.idSite, filter.params)
            .subscribe(res => this.generateMediaList(res, filter.concat));
    }

    onClosePanel(reset: boolean): void {
        this.dataDoc = null;
        if (reset) {
            this.ngOnInit();
        }
    }

    onDrop(data): void {
        const multipleObservable = [];

        data.list.forEach(element => multipleObservable.push(this.createMediaItemObservable(element)));

        forkJoin(multipleObservable).subscribe((res: any[]) => {
            this.tagList = this._service.resetActiveTags(this.tagList);

            res.length === 1
                ? this.onEdit(res[0])
                : this._toast.showMessage('message.document_upload_s', true);

            this.getFilteredDocuments(this.siteSelected, [], 1, false);
        });
    }

    onEdit(data): void {
        if (this.select) {
            this.selectDocument(data);
        } else {
            this.dataDoc = data;
        }
    }

    selectDocument(document: any): void {
        const d: { element: any, lng: string } = this._st.doc;
        d.element.value[d.lng].extension = document.data.extension;
        d.element.value[d.lng].path = document.data.name;
        d.element.value[d.lng].title = document.data.title && document.data.title[d.lng] || document['original_name'];
        d.element.value[d.lng].url = document.data.document;
        this._st.setDoc({ data: d.element, site: this.siteSelected });

    }

    backMedia(): void {
        this._st.setDoc(null);
        this._st.setShowIframe(true);
    }

    private getFilteredDocuments(idSite, tags, page, concat): void {
        this._service.getFilteredItems(idSite, 'document', tags, page, false)
            .subscribe(data => this.generateMediaList(data, concat));
    }

    private generateMediaList(data, concat): void {
        this.totalRes = data;
        this.docList = concat ? this.docList.concat(data.data) : data.data;
    }

    private createMediaItemObservable(data): Observable<any> {
        return this._service.createMediaItem(data, this.siteSelected, 'document');
    }

}
