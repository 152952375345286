import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { MyDatePickerModule } from 'mydatepicker';
import { NewsListComponent } from './components/news-list/news-list.component';
import { NewsComponent } from './news.component';
import { NewsRouting } from './news.routing';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        MyDatePickerModule,
        RouterModule.forChild(NewsRouting),
        TranslateModule,
        TranslateModule.forChild(),
    ],
    declarations: [
        NewsComponent,
        NewsListComponent,
    ],
    exports: [
        RouterModule,
    ],
})
export class NewsModule {
}
