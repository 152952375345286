import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from 'app/core/services/api.service';
import { AuthService } from 'app/core/services/auth.service';
import { SessionService } from 'app/core/services/session.service';
import { Observable } from 'rxjs';

const helper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {

    constructor(private _api: ApiService, private _auth: AuthService, private _session: SessionService) {
    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise<boolean>(resolve => {
            if (helper.isTokenExpired(this._session.getToken())) {
                this._auth.logout();
                this._api.getUrlApi().subscribe(() => resolve(true), () => resolve(false));
            } else {
                resolve(true);
            }
        });
    }

    canLoad(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate();
    }
}
