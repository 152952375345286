import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SingeltonService } from 'app/core/services/singelton.service';

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
})
export class ResetComponent implements OnInit {

    constructor(private _st: SingeltonService, private _router: Router) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this._st.resetAll();
        }, 100);
        const route: string = localStorage.getItem('route');
        localStorage.removeItem('route');
        this._router.navigate([route]);
    }

}
