import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SingeltonService } from 'app/core/services/singelton.service';

@Component({
    template: '<router-outlet></router-outlet>',
})
export class PreviewContainerComponent {

    constructor(private _route: ActivatedRoute, private _st: SingeltonService) {
        this._st.resetAll();

        this._st.setIdSite(this._route.snapshot.paramMap.get('idsite'));
    }

}
