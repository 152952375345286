import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';

@Component({
    selector: 'app-panel-form-link',
    templateUrl: './panel-form-link.component.html',
})
export class PanelFormLinkComponent implements OnInit, OnChanges {
    @Input() control: FormGroup;
    @Input() element: any;
    @Input() disabledFields: boolean = false;
    @Output() updated: EventEmitter<any> = new EventEmitter<any>();
    @Output() updatedAll: EventEmitter<any> = new EventEmitter<any>();
    public list: any[] = [];
    public modelDestination: string = null;
    public modelTarget: string = null;

    constructor(private _api: ApiService, private _st: SingeltonService) {
    }

    ngOnInit(): void {
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        this._api.get('pages/names', { params: params }).subscribe((res) => this.list = res);
        this.setData(this.control);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ( changes.control && changes.control.currentValue.value.destination == '' && changes.control.previousValue &&
            changes.control.previousValue.value.destination !== '' ) {
            this.updated.emit({ value: changes.control.previousValue.value.destination, key: 'destination' });
            this.setData(this.control);
        }
    }

    setData(control): void {
        this.modelDestination = control.controls['destination'].value;
        this.modelTarget = control.controls['target'].value;

        if (control.controls['destination'].value !== 'external') {
            this.updatedAll.emit({ key: 'href', value: null });
        }
    }

    onUpdated(key: string): void {
        if (this.control.controls[key].valid) {
            this.updated.emit({ value: this.control.controls[key].value, key: key });
        }
    }

    onUpdateAll(value: any, key: string): void {
        this.updatedAll.emit({ key: key, value: value });
        if (key === 'destination' && value !== 'external') {
            this.updatedAll.emit({ key: 'href', value: null });
        } else if (key === 'destination' && value === 'external') {
            this.updatedAll.emit({ key: 'href', value: null });
        }
    }

    onChangeModel(value: any, key: string): void {
        if (key === 'destination') {
            this.modelDestination = value;
        } else if (key === 'target') {
            this.modelTarget = value;
        }

        this.control.controls[key].setValue(value);
        this.onUpdateAll(value, key);
    }
}
