import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DateService {

    parseDatepicker(date: any): string {
        if (date) {
            const res = date.formatted.split('/');
            return res[2] + '-' + res[1] + '-' + res[0];
        } else {
            return '';
        }
    }
}
