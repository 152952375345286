import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiResolutionModel } from 'app/core/models/apiResolution.model';
import { LoginDataModel } from 'app/core/models/loginData.model';
import { LoadingService } from 'app/core/services/loading.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiService {

    constructor(
        private _http: HttpClient,
        private _lng: TranslateService,
        private _loading: LoadingService,
        private _router: Router,
        private _session: SessionService,
        private _st: SingeltonService,
        private _toast: ToastService,
    ) {
    }

    get(url: string, options: any = null): Observable<any> {
        this._loading.showLoading();
        return this.refreshToken(false).pipe(
            mergeMap(() => this.getClientApi()),
            mergeMap((api: string) => this.setHeaders(api, false, options)),
            mergeMap((opt: any) => this._http.get(opt.api + url, opt.headers)),
            mergeMap((res: any) => {
                this._loading.hideLoading();
                return of(res);
            }),
            catchError((err: any) => this.catchError(err)),
        );
    }

    post(url: string, model: any, options: any = null, isLogin: boolean = false): Observable<any> {
        this._loading.showLoading();
        return this.refreshToken(isLogin).pipe(
            mergeMap(() => this.getClientApi()),
            mergeMap((api: string) => this.setHeaders(api, isLogin, options)),
            mergeMap((opt: any) => this._http.post(opt.api + url, model, opt.headers)),
            mergeMap((res: any) => {
                this._loading.hideLoading();
                return of(res);
            }),
            catchError((err: any) => this.catchError(err)),
        );
    }

    put(url: string, model: any, options: any = null): Observable<any> {
        this._loading.showLoading();
        return this.refreshToken(false).pipe(
            mergeMap(() => this.getClientApi()),
            mergeMap((api: string) => this.setHeaders(api, false, options)),
            mergeMap((opt: any) => this._http.put(opt.api + url, model, opt.headers)),
            mergeMap((res: any) => {
                this._loading.hideLoading();
                return of(res);
            }),
            catchError((err: any) => this.catchError(err)),
        );
    }

    delete(url: string, options: any = null): Observable<any> {
        this._loading.showLoading();
        return this.refreshToken(false).pipe(
            mergeMap(() => this.getClientApi()),
            mergeMap((api: string) => this.setHeaders(api, false, options)),
            mergeMap((opt: any) => this._http.delete(opt.api + url, opt.headers)),
            mergeMap((res: any) => {
                this._loading.hideLoading();
                return of(res);
            }),
            catchError((err: any) => this.catchError(err)),
        );
    }

    getUrlApi(): Observable<any> {
        this._session.setLng('es');
        this._session.setApi(environment.urlAPI);
        this._lng.use('es');

        return of(this._session.getApi());
    }

    setToken(res: LoginDataModel): void {
        this._session.setLogin(res);
        this._session.setToken(res.token);
    }

    private catchError(err: any): Observable<null> {
        this._loading.hideLoading();
        if (this._session.getAlert() === 'enabled') {
            this._toast.showNotKnowError();
        }
        return of(null);
    }

    private getClientApi(): Observable<string> {
        const api: string = this._session.getApi();
        return of(api ? api : null);
    }

    private setHeaders(api: string, isLogin: boolean, options: any = null): Observable<{ api: string, headers: any }> {
        if (isLogin) {
            return of({
                api: api,
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            });
        } else if (options !== null) {
            const login: LoginDataModel = this._session.getLogin();
            return of({
                api: api,
                headers: {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${ login.token || null }`,
                    }),
                    ...options,
                },
            });
        } else {
            const login: LoginDataModel = this._session.getLogin();
            return of({
                api: api,
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ login.token || null }`,
                }),
            });
        }
    }

    private refreshToken(isLogin: boolean): Observable<void> {
        // if (!isLogin) {
        //     const login: LoginDataModel = this._session.getLogin();
        //     const api: string = this._session.getApi();
        //     const httpOtipons = {
        //         headers: new HttpHeaders({
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${login.token || null}`,
        //         }),
        //     };
        //     return this._http.post(api + 'users/refresh-login', { refresh_token: login.refresh_token }, httpOtipons)
        //         .map((res: any) => {
        //             login.refresh_token = res.refresh_token;
        //             login.token = res.token;
        //             this.setToken(login);
        //         });
        // } else {
        //     return of(null);
        // }

        return of(null);
    }

}
