import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-social-box-facebook',
    templateUrl: './social-box-facebook.component.html',
})
export class SocialBoxFacebookComponent implements OnInit {
    @Input() data: any = null;
    @Input() form: FormGroup;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.form.addControl('data', this._fb.group({
            facebook_account: ['', Validators.required],
            facebook_app_id: ['', Validators.required],
            facebook_app_secret: ['', Validators.required],
            facebook_access_token: ['', Validators.required],
        }));

        if (this.data !== null) {
            this.form.get('data').get('facebook_account').setValue(this.data.data.facebook_account);
            this.form.get('data').get('facebook_app_id').setValue(this.data.data.facebook_app_id);
            this.form.get('data').get('facebook_app_secret').setValue(this.data.data.facebook_app_secret);
            this.form.get('data').get('facebook_access_token').setValue(this.data.data.facebook_access_token);
        }
    }

    onUpdated(field: string, value: any, group: any = null): void {
        if (group) {
            this.form.get(group + '.' + field).setValue(value);
        } else {
            this.form.get(field).setValue(value);
        }

        this.update.emit({name: this.form.controls.name.value});
    }
}
