import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
    static web(control: FormControl): ValidationErrors | null {
        if (control.value === '' || control.value === null) {
            return null;
        } else if (control.value.match('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,})*)(/(.)*)?(\\?(.)*)?')) {
            return null;
        } else {
            return { web: true };
        }
    }

    static email(control: FormControl): ValidationErrors | null {
        // RFC 2822 compliant regex
        if (control.value == '' || control.value == null) {
            return null;
        } else if (
            control.value.match(
                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
            )
        ) {
            return null;
        } else {
            return { email: true };
        }
    }


    static equal(field: string, name: string = null): ValidatorFn | null {
        return (control: FormControl) => {
            if (control.parent && control.parent.controls && control.parent.controls[field]) {
                const parent: any = control.parent.controls[field].value;
                const value: any = control.value;

                if (name === null) {
                    name = field;
                }

                return value !== parent ? { equal: name } : null;
            } else {
                return null;
            }
        };
    }

}
