import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/core/services/api.service';
import { CustomValidators } from 'app/core/validators/custom.validators';

@Component({
    selector: 'app-profile-user',
    templateUrl: './profile-user.component.html',
})
export class ProfileUserComponent implements OnInit {
    @Input() user: any;
    @Input() disable: boolean;
    public form: FormGroup;

    constructor(private _fb: FormBuilder, private _api: ApiService) {
    }

    ngOnInit(): void {
        this.form = this._fb.group({
            name: [this.user.name, Validators.required],
            surname: [this.user.surname, Validators.required],
            email: [this.user.email, [Validators.required, CustomValidators.email]],
        });
    }

    onSave(): void {
        const model: any = {
            ...this.user,
            name: this.form.controls['name'].value,
            surname: this.form.controls['surname'].value,
            email: this.form.controls['email'].value,
        };
        this._api.put(`users/${ this.user.id }`, model)
            .subscribe();
    }

}
