import { Component, OnDestroy, OnInit } from '@angular/core';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-preview',
    templateUrl: './preview.component.html',
})
export class PreviewComponent implements OnInit, OnDestroy {
    public loaded: boolean = false;
    protected $subs: Subscription[] = [];

    constructor(private _utils: UtilsService, private _st: SingeltonService) {
        this.$subs.push(this._st.getIdSiteObs().subscribe(() => this.ngOnInit()));
    }

    ngOnInit(): void {
        this.loaded = false;
        this._utils.getAlldata(this._st.idSite).subscribe(res => {
            this._st.setAllData(res);
            this.loaded = true;
        });
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }

    onClick(event: any): void {
        this._st.setPanel(event);
    }
}
