import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from 'app/core/services/loading.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { Bounds, CropperSettings } from 'ng2-img-cropper';
import { forkJoin, Observable } from 'rxjs';
import { MediaModel } from '../../models/media.model';
import { MediaService } from '../../services/media.service';

@Component({
    selector: 'app-media-img',
    templateUrl: './media-img.component.html',
    animations: [
        trigger('fade', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(600)]),
            transition(':leave', animate(600, style({ opacity: 0 }))),
        ]),
    ],
})
export class MediaImgComponent implements OnInit, OnDestroy {
    @Input() select: boolean = false;
    @ViewChild('cropper', undefined) cropper;
    public animation: boolean = false;
    public croppedImage: any = {};
    public cropperSettings: CropperSettings = null;
    public cropSize: Bounds;
    public dataImg: any = null;
    public defaultLang: string;
    public imageList: MediaModel[] = [];
    public imageRes: any;
    public isCropping: boolean = false;
    public originalImage: any;
    public showFilter: boolean = false;
    public siteSelected: string = '';
    public tagList: any = [];
    public tooltipData: any;

    constructor(
        private _service: MediaService,
        private _session: SessionService,
        private _st: SingeltonService,
        private _toast: ToastService,
        private _loading: LoadingService,
    ) {
    }

    ngOnInit(): void {
        this._loading.showLoading(true);
        this.showFilter = false;
        setTimeout(() => {
            this.showFilter = true;
        });
        this.resetTooltip();
        this.defaultLang = this._session.getLng();
        this._service.getSiteTags(this._st.idSite).subscribe(res => this.tagList = res);
        this.siteSelected = this._st.idSite;
        this.getFilteredImages(this.siteSelected, [], 1, false);
    }

    ngOnDestroy(): void {
        document.body.style.overflow = 'auto';
    }

    onFilter(filter: { params: HttpParams, concat: boolean }): void {
        this._service.getItems(this._st.idSite, filter.params)
            .subscribe(res => this.generateMediaList(res, filter.concat));
    }

    onClosePanel(reset: boolean): void {
        this.dataImg = null;
        if (reset) {
            this.ngOnInit();
        }
    }

    onDrop(data): void {
        const multipleObservable = [];

        data.list.forEach(element => multipleObservable.push(this.createMediaItemObservable(element)));

        forkJoin(multipleObservable).subscribe((res: any[]) => {
                this.tagList = this._service.resetActiveTags(this.tagList);

                res.length === 1
                    ? this.onEdit(res[0])
                    : this._toast.showMessage('message.image_upload_s', true);

                this.getFilteredImages(this.siteSelected, [], 1, false);
            }, () => this._toast.showNotKnowError(),
        );
    }

    onEdit(data): void {
        if (this.select) {
            this.selectImage(data);
        } else {
            this.dataImg = data;
        }
    }

    showTooltip(data): void {
        this.tooltipData = data;
    }

    resetTooltip(): void {
        this.tooltipData = {
            data: { image: '', width: '', height: '', title: { 'es': '' } },
            tags: [],
        };
    }

    selectImage(image): void {
        const d: { element: any, lng: string } = this._st.img;
        this.originalImage = image.data;

        if (d.element.attributes.crop) {
            this.cropperSettings = new CropperSettings();
            if (d.element.attributes && d.element.attributes.thumbType && d.element.attributes.thumbType === 'exact') {
                this.cropperSettings.width = d.element.attributes.size.w;
                this.cropperSettings.height = d.element.attributes.size.h;
            }

            this.cropperSettings.canvasWidth = 700;
            this.cropperSettings.canvasHeight = 450;
            this.cropperSettings.noFileInput = true;

            const myImage: HTMLImageElement = new Image();
            myImage.crossOrigin = 'anonymous';
            myImage.src = image.data.image;
            myImage.onload = () => this.cropper.setImage(myImage);

            this.isCropping = true;
        } else {
            this.saveWithOutCrop();
        }
    }

    saveCrop(): void {
        const d: { element: any, lng: string } = { ...this._st.img };
        d.element.value[d.lng].alt = this.originalImage['alt'][d.lng] || '';
        d.element.value[d.lng].title = this.originalImage['title'][d.lng] || this.originalImage['original_name'];
        d.element.value[d.lng].original = this.originalImage['name'];
        d.element.value[d.lng].refresh = true;
        d.element.value[d.lng].cropped = true;
        d.element.value[d.lng].cropData.x = this.cropSize.left;
        d.element.value[d.lng].cropData.y = this.cropSize.top;
        d.element.value[d.lng].cropData.w = this.cropSize.width;
        d.element.value[d.lng].cropData.h = this.cropSize.height;
        d.element.value[d.lng].src.original = this.originalImage.image;
        d.element.value[d.lng].src.thumb = this.originalImage.thumb;
        this._st.setImg({ data: { ...d.element }, site: this.siteSelected });
    }

    saveWithOutCrop(): void {
        const d: { element: any, lng: string } = this._st.img;
        d.element.value[d.lng].alt = this.originalImage['alt'][d.lng] || '';
        d.element.value[d.lng].title = this.originalImage['title'][d.lng] || this.originalImage['original_name'];
        d.element.value[d.lng].original = this.originalImage['name'];
        d.element.value[d.lng].refresh = true;
        d.element.value[d.lng].cropped = false;
        d.element.value[d.lng].cropData.x = null;
        d.element.value[d.lng].cropData.y = null;
        d.element.value[d.lng].cropData.w = null;
        d.element.value[d.lng].cropData.h = null;
        this._st.setImg({ data: d.element, site: this.siteSelected });
    }

    backCrop(): void {
        this.isCropping = false;
    }

    backMedia(): void {
        this._st.setImg(null);
        this._st.setShowIframe(true);
    }

    private getFilteredImages(idSite, tags, page, concat): void {
        this._service.getFilteredItems(idSite, 'image', tags, page, false)
            .subscribe(data => this.generateMediaList(data, concat));
    }

    private generateMediaList(data, concat): void {
        this.imageRes = data;
        this.imageList = concat ? this.imageList.concat(data.data) : data.data;
        this.imageList.map((item, i) => this.imageList[i].data = this.setPreviewImgSize(item.data));
        this.animation = true;
        this._loading.hideLoading(true);
    }

    private createMediaItemObservable(data): Observable<any> {
        return this._service.createMediaItem(data, this.siteSelected, 'image');
    }

    private setPreviewImgSize(data: any): any {
        if (data.original_size) {
            let width = data.original_size.width;
            let height = data.original_size.height;
            const proportion = (width / height);

            if (width > height) {
                if (width > 420) {
                    width = 420;
                }
                height = Math.round(width / proportion);
            } else {
                if (height > 420) {
                    height = 420;
                }
                width = Math.round(height * proportion);
            }

            data.width = width;
            data.height = height;
        }

        return data;
    }
}
