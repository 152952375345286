// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    urlAPIResolution: 'https://api-resolution.fishhotels.com/api',
    apikey_gmap: 'AIzaSyA9MEitZptYgZ2yQm78ezBM0bEQORBGoTs',
    max_file_size: 8388608,
    max_media_size: 8388608,
    urlAPI: 'https://fishh-api.clintu.es/api/'
};
