import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BacDropInOutAnimation } from 'app/core/animations/backDropInOut.animation';
import { SlideInOutAnimation } from 'app/core/animations/slideInOut.animation';
import { MenuModel } from 'app/core/models/menu.model';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    animations: [SlideInOutAnimation, BacDropInOutAnimation],
})
export class MenuComponent implements OnInit, OnDestroy {
    @Input() event: any = null;
    @Input() openStart: boolean = true;
    @Output() refresh: EventEmitter<void> = new EventEmitter<void>();
    @Output() url: EventEmitter<string> = new EventEmitter<string>();
    public lngActive: string = null;
    public lngShow: boolean = false;
    public menuHome: MenuModel = null;
    public menuItems: MenuModel[] = [];
    public menuShow: boolean = false;
    public haveConfigOpenened: boolean = false;
    protected $subs: Subscription[] = [];
    private $dragula: Subscription = new Subscription();

    constructor(
        private _api: ApiService,
        private _st: SingeltonService,
        private _dragula: DragulaService,
        private _session: SessionService,
    ) {
    }

    ngOnInit(): void {
        this._st.lng = this._session.getLng();
        this.$subs.push(this._st.getShowIframeObs().subscribe(res => res ? this.refreshMenus() : ''));
        this.$subs.push(this._st.getPanelObs().subscribe(res => this.haveConfigOpenened = res !== null));
        this.$subs.push(this._st.getConfigPanelObs().subscribe(res => {
            this.haveConfigOpenened = res !== null;
            if (!this.haveConfigOpenened) {
                this.refreshMenus();
            }
        }));

        this.menuItems = [];
        this.lngActive = this.event.language;
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        this._api.get('menus', { params: params }).subscribe(res => this.buildMenuItems(res));
        this.menuShow = this.openStart;
    }

    ngOnDestroy(): void {
        this.$dragula.unsubscribe();
        this.$subs.map(sub => sub.unsubscribe());
    }

    onChangeLng(url: any): void {
        this.lngShow = false;
        this.menuShow = false;
        this.lngActive = url.language;
        this._st.lng = url.language;
        this.url.emit(url.home === 1 ? url.language : `${ url.language }/${ url.url }`);
    }

    onChangeMenu(url: any): void {
        this.lngShow = false;
        this.menuShow = false;
        this.url.emit(url && url[this.lngActive] ? url[this.lngActive].href : this.lngActive);
    }

    showConfigPanel(): void {
        this._st.setConfigPanel(this.event);
    }

    private refreshMenus(): void {
        this.menuShow = false;
        this.$dragula.unsubscribe();
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        this._api.get('menus', { params: params }).subscribe(res => {
            this.buildMenuItems(res);
            this.refresh.emit();
        });
    }

    private onDrag(element: any) {
        if (this.menuShow) {
            const split: string[] = element.name.split('.');
            const keyChain: string = split[0];
            let model: MenuModel;
            for (let i = 0; i < this.menuItems.length; i++) {
                if (this.menuItems[i].menu.key === keyChain) {
                    model = this.menuItems[i];
                    break;
                }
            }
            this._api.put(`menus/${ model.id }`, model).subscribe();
        }
    }

    private buildMenuItems(res: MenuModel[]): void {
        if (res) {
            const items: MenuModel[] = [];

            // Order menus
            ['top', 'home', 'main', 'footer', 'landing'].map((key: string) => {
                res.map((item: MenuModel) => {
                    if (item.name === key) {
                        items.push(item);
                    }
                });
            });


            // Dragula list recursive
            const drag: string[] = [];
            for (let i = 0; i < items.length; i++) {
                if (items[i].name !== 'home') {
                    drag.push(items[i].menu.key);
                    this.arrayMapRecursive(items[i].menu.elements, drag, items[i].menu.key);
                }
            }
            this.initDragulaList(drag);
            this.$dragula = this._dragula.drop().subscribe((value) => {
                this.onDrag(value);
            });

            this.menuHome = items.find( (item) => item.name == 'home');

            this.menuItems = items.filter( (item) => item.name !== 'home');
        }
    }

    private arrayMapRecursive(item: any[], arr: string[] = [], parent: string): string[] {
        for (let i = 0; i < item.length; i++) {
            if (item[i].hasOwnProperty('elements') && item[i].elements.length) {
                let name = parent + '.' + item[i].key;
                item[i].keyChain = name;
                arr.push(name);
                this.arrayMapRecursive(item[i].elements, arr, name);
            }
        }
        return arr;
    }

    private initDragulaList(list: string[]): void {
        list.map(name => {
            // Remove dragula bag in case that it already exists
            if (this._dragula.find(name) !== undefined) {
                this._dragula.destroy(name);
            }

            // Create dragula bag
            this._dragula.createGroup(name, { moves: (el, source, handle): any => handle.classList.contains('c-icon--move') });
        });
    }

}
