import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-panel-form-textarea',
    templateUrl: './panel-form-textarea.component.html',
})
export class PanelFormTextareaComponent implements OnInit {
    @Input() control: AbstractControl;
    @Input() element: any;
    @Input() disabledFields: boolean = false;
    @Input() hideRequired: boolean = false;
    @Input() label: string = null;
    @Output() updated: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit(): void {
        if (this.disabledFields || this.element.readOnly) {
            this.control.disable();
        }
    }

    onUpdated(): void {
        if (this.control.valid) {
            this.updated.emit(this.control.value);
        }
    }
}
