import { MenuTreeElementComponent } from 'app/core/components/menu/menu-tree-element/menu-tree-element.component';
import { MenuTreeComponent } from 'app/core/components/menu/menu-tree/menu-tree.component';
import { MenuComponent } from 'app/core/components/menu/menu.component';
import { MenuItemAdd } from './menu-item-add/menu-item-add.component';

export const MenuDirectives = [
    MenuItemAdd,
    MenuComponent,
    MenuTreeComponent,
    MenuTreeElementComponent,
];
