import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormOptionsModel } from 'app/core/models/formOptions.model';

@Component({
    selector: 'app-form-auto',
    templateUrl: './form-auto.component.html',
})
export class FormAutoComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() formOptions: FormOptionsModel = {};
    @Input() item: any;
    @Output() updated: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
        this.buildFormOptions();
    }

    onForm(value: any): void {
        this.updated.emit(value);
    }

    private buildFormOptions(): void {
        if (!this.formOptions.labelPosition) {
            this.formOptions.labelPosition = 'top';
        }
    }

}
