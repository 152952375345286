import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuModel } from 'app/core/models/menu.model';
import { ApiService } from 'app/core/services/api.service';
import { LoadingService } from 'app/core/services/loading.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { Subscription } from 'rxjs';
import {LanguageModel} from '../../../models/allData.model';

@Component({
    selector: 'app-menu-tree-element',
    templateUrl: './menu-tree-element.component.html',
})
export class MenuTreeElementComponent implements OnInit, OnDestroy {
    @Input() canDelete: boolean = false;
    @Input() element: any;
    @Input() subelement: boolean = false;
    @Input() first: boolean = false;
    @Input() parent: any;
    @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
    public isHome: boolean = false;
    public defaultLanguage: LanguageModel;
    private config$: Subscription = new Subscription();

    constructor(private _api: ApiService, private _st: SingeltonService, private _loading: LoadingService) {
    }

    ngOnInit(): void {
        this.isHome = this.element.data && this.element.data.page.is_home === 1;
        this.defaultLanguage = this._st.allData.languages.find( language => language.default == true );
    }

    ngOnDestroy(): void {
        this.config$.unsubscribe();
    }

    onClick(element: any, skipCheck: boolean = false): void {
        if (this.subelement) {
            this._st.setCanDelete({ can: this.canDelete, id: this.parent.id });
        } else if (!skipCheck && this.element.data.page.is_error404 !== 1 && this.element.data.page.is_error404 !== true) {
            this._st.setCanDelete({ can: this.canDelete, id: this.parent.id });
        }
        this.clicked.emit(element);
    }

    onActivate(item: any): void {
        item.active = !item.active;
        this._api.put(`pages/${ item.id }`, item).subscribe();
    }

    onConfig(): void {
        if (this.subelement) {
            this._st.setCanDelete({ can: this.canDelete, id: this.parent.id });
        } else if (this.element.data.page.is_error404 !== 1 && this.element.data.page.is_error404 !== true) {
            this._st.setCanDelete({ can: this.canDelete, id: this.parent.id });
        }

        this._st.setConfigPanel({
            ...this.element.data.page,
            page_id: this.element.data.page.id,
            alternates: [...this.element.data.page.urls],
        });
    }

    onNew(template: string): void {
        const key: string[] = this.element.keyChain.substring(this.element.keyChain.indexOf('.') + 1);
        const selectedElement = this.element;
        const model: any = {
            key: key,
            site_id: this._st.idSite,
            template: template,
        };
        this._api.post(`menus/${ this.parent.id }/add-page`, model).subscribe((res: MenuModel) => {

                const selected = this.getItemRecursive(selectedElement.keyChain, res.menu.elements);
                const item = {
                    alternates: [...selected.elements].pop().data.page.urls,
                    ...[...selected.elements].pop().data.page.urls.slice(-1).pop(),
                    persistData: true,
                };
                this._st.setConfigPanel(item);
                this._st.setShowIframe(false);

                this.config$ = this._st.getConfigPanelObs().subscribe((data: any[]) => {
                    if (data !== null) {
                        const ret = {};
                        ret[data[2]['language']] = data[2];
                        this._st.setRoute(ret);
                        this.getHighlight(data[2].page_id);
                    }
                    this.config$.unsubscribe();
                    this._st.setConfigPanel(null);
                    this._st.setShowIframe(true);
                });
            },
        );
    }

    private getItemRecursive(search: string, elements: any[]): any {
        for(let i = 0; i < elements.length; i++) {
            if(elements[i].keyChain == search) {
                return elements[i];
            } else if(elements[i]['elements'] !== undefined) {
                let selected = this.getItemRecursive(search, elements[i]['elements']);

                if(selected) {
                    return selected;
                }
            }
        }

        return false;
    }

    private getHighlight(page_id: string): void {
        if (this.element.sonsPageTemplate === 'offer-view-template' ||
            this.element.sonsPageTemplate === 'room-view-template' ||
            this.element.sonsPageTemplate === 'venue-view-template' ||
            this.element.sonsPageTemplate === 'hotel-view-template' ||
            this.element.sonsPageTemplate === 'event-view-template') {

            const splitLast: string = this.element.source.split('|').pop();
            this._api.get(`pages/${ splitLast }/content/module-list-element/${ page_id }`).subscribe(res => {
                this._loading.showLoading();
                const params = new HttpParams().append('filter_site_id', this._st.idSite);
                this._api.get('menus', { params: params }).subscribe(menus => {
                    for (let a = 0; a < menus.length; a++) {
                        if (this.parent.id === menus[a].id) {
                            for (let i = 0; i < menus[a].menu.elements.length; i++) {
                                if (menus[a].menu.elements[i].keyChain === this.element.keyChain) {
                                    this._loading.hideLoading();
                                    this._st.setPanel({
                                        data: res,
                                        page: splitLast,
                                        ref: res.keyChain,
                                    });
                                    break;
                                }
                            }
                            break;
                        }
                    }
                });
            });
        }
    }

}
