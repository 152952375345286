import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuModel } from 'app/core/models/menu.model';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-menu-tree',
    templateUrl: './menu-tree.component.html',
})
export class MenuTreeComponent implements OnInit, OnDestroy {
    @Input() item: MenuModel;
    @Input() showBar: boolean = false;
    @Input() showLabel: boolean = true;
    @Input() isHome: boolean = false;
    @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
    public showItem: boolean = false;
    private config$: Subscription = new Subscription();

    constructor(private _api: ApiService, private _st: SingeltonService) {
    }

    ngOnInit(): void {
        this.showItem = this.item.menu.elements[0] !== undefined; //this.item.menu.elements[0].data.page.is_home !== 1
    }

    ngOnDestroy(): void {
        this.config$.unsubscribe();
    }

    onClick(event: any): void {
        this.clicked.emit(event);
    }

    addNew(template: string, id: string): void {
        const model: any = {
            key: '',
            site_id: this._st.idSite,
            template: template,
        };

        this._api.post(`menus/${ id }/add-page`, model).subscribe((res: MenuModel) => {
                const item = {
                    alternates: [...res.menu.elements].pop().data.page.urls,
                    ...[...res.menu.elements].pop().data.page.urls.slice(-1).pop(),
                    persistData: true,
                };
                this._st.setConfigPanel(item);
                this._st.setShowIframe(false);

                this.config$ = this._st.getConfigPanelObs().subscribe((data: any[]) => {
                    if (data !== null) {
                        const ret = {};
                        ret[data[2]['language']] = data[2];
                        this._st.setRoute(ret);
                    }
                    this.config$.unsubscribe();
                    this._st.setConfigPanel(null);
                    this._st.setShowIframe(true);
                });
            },
        );
    }

}
