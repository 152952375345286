import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilsService } from 'app/core/services/utils.service';

@Component({
    selector: 'app-panel-form-date-event',
    templateUrl: './panel-form-date-event.component.html',
})
export class PanelFormDateEventComponent implements OnInit {
    @Input() control: FormGroup;
    @Input() element: any;
    @Input() disabledFields: boolean = false;
    @Output() updatedAll: EventEmitter<any> = new EventEmitter<any>();
    public dpOptionsFrom: any;
    public dpOptionsTo: any;
    public date_from: any;
    public date_to: any;
    public days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    public daysSelected: boolean[] = [];

    constructor(private _utils: UtilsService) {
        this.dpOptionsFrom = {
            dateFormat: 'dd/mm/yyyy',
            showClearDateBtn: false,
            editableDateField: false,
            openSelectorOnInputClick: true,
            openSelectorTopOfInput: true,
        };
        this.dpOptionsTo = {
            dateFormat: 'dd/mm/yyyy',
            showClearDateBtn: false,
            editableDateField: false,
            openSelectorOnInputClick: true,
            alignSelectorRight: true,
            openSelectorTopOfInput: false,
        };
    }

    ngOnInit(): void {
        this.date_from = this._utils.parseStringDatepicker(this.control.controls['date_from'].value);
        this.date_to = this._utils.parseStringDatepicker(this.control.controls['date_to'].value);
        this.buildDays();
    }

    onUpdateAll(value: any, key: string): void {
        this.updatedAll.emit({ key: key, value: value });
    }

    onUpdateDate(value: any, isFrom: boolean): void {
        if (isFrom) {
            this.date_from = this._utils.parseDatepicker(value);
            this.onUpdateAll(this.date_from, 'date_from');
        } else {
            this.date_to = this._utils.parseDatepicker(value);
            this.onUpdateAll(this.date_to, 'date_to');
        }
    }

    onPeriodicity(value: string): void {
        this.control.reset();
        this.date_from = null;
        this.date_to = null;
        this.daysSelected = [];
        this.onUpdateAll(value, 'periodicity');
    }

    onDay(pos: number, event: any): void {
        this.daysSelected[pos] = event;
        const selecteds: string[] = [];
        this.daysSelected.map((sts: boolean, i: number) => {
            if (sts === true) {
                selecteds.push(this.days[i]);
            }
        });

        this.onUpdateAll(selecteds.join(), 'week_day');
        this.buildDays();
    }

    private buildDays(): void {
        this.daysSelected = [];
        if (this.control.controls['week_day'].value) {
            const split: string[] = this.control.controls['week_day'].value.split(',');
            const ret: boolean[] = [];

            this.days.map(day => {
                const filter: string[] = split.filter(d => d === day);
                ret.push(!!filter.length);
            });

            this.daysSelected = ret.slice();
        }
    }

}
