import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FormService {

    constructor() {
    }

    setForm(allData: any[]): any {
        const controls: any = {};
        allData.map(data => {
            const control: any[] = [];
            if (data.required) {
                control.push(Validators.required);
            }
            if (data.hasOwnProperty('validation_rule')) {
                data.validation_rule.split('|')
                    .map(val => {
                        const split: string[] = val.split(':');
                        if (split.length > 1) {
                            // Validators with value
                            switch (split[0]) {
                                case 'max':
                                    control.push(Validators.maxLength(+split[1]));
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            switch (val) {
                                // Validators without value
                                case 'email':
                                    control.push(Validators.email);
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
            }


            controls[data.key] = [data.value, control];
        });
        return controls;
    }

    setCategories(allData: any[]): any[][] {
        const ret = [];
        const categories: string[] = [];

        allData.map(item => {
            const category: string = item.category;
            const exist = categories.filter(cat => cat === category);
            if (exist.length === 0) {
                categories.push(category);
                const newCat: any[] = allData.filter(a => category === a.category);
                ret.push(newCat);
            }
        });

        return ret;
    }

    sanitizeValues(form: FormGroup): any {
        const values = form.getRawValue();
        Object.keys(values)
            .map(key => {
                if (values[key] === 'false') {
                    values[key] = false;
                }
                if (values[key] === 'true') {
                    values[key] = true;
                }
            });
        return values;
    }

}
