import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteModel } from 'app/core/models/site.model';
import { ApiService } from 'app/core/services/api.service';
import { UtilsService } from 'app/core/services/utils.service';

@Component({
    selector: 'app-profile-site',
    templateUrl: './profile-site.component.html',
})
export class ProfileSiteComponent implements OnInit {
    public form: FormGroup;
    public sites: any[];
    private defaultSite: string = null;

    constructor(private _fb: FormBuilder, private _api: ApiService, private _utils: UtilsService) {
    }

    ngOnInit(): void {
        this._utils.getSites().subscribe(res => this.setSites(res));
    }

    onSave(): void {
        const model: any = { site_id: this.form.get('site').value };
        this._api.put('users/default-site', model)
            .subscribe(res => this.defaultSite = res.id);

    }

    private setSites(res: SiteModel): void {
        this.sites = res.data;
        this.sites.map(item => {
            if (item.default) {
                this.defaultSite = item.id;
            }
        });

        this.buildForm();
    }

    private buildForm(): void {
        this.form = this._fb.group({
            site: [this.defaultSite, Validators.required],
        });
    }

}
