import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'app/core/services/session.service';
import { ToastService } from 'app/core/services/toast.service';
import { environment } from 'environments/environment';
import { UploadEvent } from 'ngx-file-drop';
import { forkJoin, Observable } from 'rxjs';
import { MediaModel } from '../../models/media.model';

@Component({
    selector: 'app-media-file-drop',
    templateUrl: './media-file-drop.component.html',
})
export class MediaFileDropComponent implements OnInit, OnDestroy {
    @Input() filesList: MediaModel[] = [];
    @Input() filesType: string;
    @ViewChild('fileInput') fileInput: ElementRef;
    @Output() fileUpdated = new EventEmitter();

    public fileOverBox: boolean = false;
    public uploadingFile: boolean = false;
    public supportedFileTypes: string[];
    public linkText: string;
    public descText: string;
    public sizeError: boolean = false;
    public sizeErrorText: string;
    public typeError: boolean = false;
    public typeErrorText: string;
    public fileNameText: string;
    public errorList = [];
    private supportedFileTypesInput: string;
    private translateObservable;
    private documentLang;
    private imageLang;
    private maxSize: number;

    constructor(
        private _http: HttpClient,
        private _lng: TranslateService,
        private _session: SessionService,
        private _toast: ToastService,
    ) {
    }

    ngOnInit() {
        this.translateObservable = this._lng.get('media').subscribe(res => {
            this.documentLang = res.document;
            this.imageLang = res.image;
            this.setConfigTypes();
        });
    }

    ngOnDestroy() {
        this.translateObservable.unsubscribe();
    }

    setConfigTypes() {
        switch (this.filesType) {
            case 'image':
                this.supportedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
                this.supportedFileTypesInput = 'image/*';
                this.linkText = this.imageLang.new_image;
                this.descText = this.imageLang.new_image_desc;
                this.maxSize = environment.max_media_size;
                break;
            case 'document':
                this.supportedFileTypes = [
                    'application/pdf', 'application/msword', 'application/zip',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                ];
                this.supportedFileTypesInput = '.pdf, .doc, .zip, .docx';
                this.linkText = this.documentLang.new_document;
                this.descText = this.documentLang.new_document_desc;
                this.maxSize = environment.max_file_size;
                break;

            default:
                this.supportedFileTypes = null;
                break;
        }
    }

    fileOverLeave(value: boolean): void {
        this.fileOverBox = value;
    }

    changeListener(event: any): void {
        const files = event.target.files;
        this.processFilesToUpload(files);
    }

    async dropped(event: UploadEvent) {
        const files: any[] = [];

        for (const droppedFile of event.files) {
            if (droppedFile.fileEntry.isFile) {
                const file: File = await this.convertToFile(droppedFile.fileEntry);
                files.push(file);
            }
        }

        this.processFilesToUpload(files);
    }

    private convertToFile(fileEntry): Promise<File> {
        return new Promise<File>(resolve => {
            fileEntry.file((file: File) => resolve(file));
        });
    }

    private processFilesToUpload(files) {
        const filesLength: number = files.length;
        const multipleObservable = [];
        this.errorList = [];


        for (let index = 0; index < filesLength; index++) {
            const file: File = files[index];

            if (this.supportedFileTypes.indexOf(file.type) !== -1) {
                if (file.size < this.maxSize) {
                    multipleObservable.push(this.uploadFile(file));
                } else {
                    this.fileNameText = file.name;
                    this.errorList.push({ msg: this.showFileError(this.filesType, 'size', file.name) });
                }
            } else {
                this.fileNameText = file.name;
                this.errorList.push({ msg: this.showFileError(this.filesType, 'type', file.name) });
            }
        }

        if (!this.sizeError && !this.typeError) {
            this.uploadingFile = true;
            forkJoin(multipleObservable).subscribe(
                data => {
                    this.uploadingFile = false;
                    this.uploadedFile(data, (data.length > 1));
                },
                () => {
                    this.uploadingFile = false;
                    this._toast.showNotKnowError();
                },
            );
        } else {
            this.uploadingFile = false;
        }
    }

    private uploadFile(file): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this._http.post(this._session.getApi() + 'uploads', formData);
    }

    private uploadedFile(data, multiple) {
        const emitData = {};
        this.uploadingFile = false;
        if (multiple) {
            emitData['list'] = data;
            emitData['multiple'] = 1;
        } else {
            emitData['list'] = data;
            emitData['multiple'] = 0;
        }
        document.body.style.overflow = 'auto';
        this.fileUpdated.emit(emitData);
    }

    private showFileError(fileType, errorType, fileName) {

        let msgText: string = '';
        let sizeErrorText: string = '';
        let typeErrorText: string = '';

        switch (fileType) {
            case 'image':
                sizeErrorText = 'El tamaño de la imagen ' + fileName + ' es demasiado grande. El archivo no debería superar los 8MB.';
                typeErrorText = 'El formato de la imagen ' + fileName + ' no es compatible. Debería ser .jpg, .gif o .png.';
                break;
            case 'document':
                sizeErrorText = 'El tamaño del archivo ' + fileName + ' es demasiado grande. El archivo no debería superar los 8MB.';
                typeErrorText = 'El formato del archivo ' + fileName + ' no es compatible. Debería ser .pdf, .doc, .docx o .zip';
                break;
        }

        switch (errorType) {
            case 'size':
                msgText = sizeErrorText;
                break;
            case 'type':
                msgText = typeErrorText;
                break;
        }

        return msgText;
    }

}
