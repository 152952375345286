import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getAvailableText',
    pure: false,
})

export class GetAvailableText implements PipeTransform {
    transform(value: any, defaultLang: any, defaultValue: any, checkOtherLanguages: boolean = true): any {
        if (value[defaultLang] && value[defaultLang] !== '') {
            return value[defaultLang];
        } else if (checkOtherLanguages) {
            for (const key in value) {
                if (value.hasOwnProperty(key) && value[key] && value[key] !== '') {
                    return value[key];
                }
            }
        }

        return defaultValue;
    }
}
