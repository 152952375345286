import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AllDataModel } from 'app/core/models/allData.model';
import { LoginDataModel } from 'app/core/models/loginData.model';
import { SiteModel, SiteSingle } from 'app/core/models/site.model';
import { AuthService } from 'app/core/services/auth.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { Observable, of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
})

export class NavbarComponent implements OnDestroy {
    public allData: AllDataModel;
    public idSite: string;
    public isLogged: boolean = false;
    public modules: any = {};
    public openSiteSelector: boolean = false;
    public sites: SiteSingle[] = [];
    public user: LoginDataModel;
    protected $subs: Subscription[] = [];

    constructor(
        private _auth: AuthService,
        private _router: Router,
        private _session: SessionService,
        private _st: SingeltonService,
        private _utils: UtilsService,
    ) {
        this.$subs.push(this._st.getIdSiteObs().subscribe(i => {
            if (i) {
                this.user = this._session.getLogin();
                this.isLogged = !!this.user;
                this.setIdSite(i).pipe(
                    mergeMap(d => this.setAllData(d)),
                    mergeMap(s => this.setSites(s)),
                ).subscribe();
            }
        }));

    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }

    toggleSiteSelector(site: SiteSingle): void {
        this._st.setIdSite(site.id);
        setTimeout(() => {
            this._st.resetAll();
        }, 100);
        this.openSiteSelector = !this.openSiteSelector;
    }

    logOut(): void {
        this._auth.logout();
    }

    setShowModule(val: string): void {
        this._st.setShowModule(true);
        const route: string = '/' + this._st.idSite + '/' + val;
        localStorage.setItem('route', route);
        this._router.navigate(['reset']);
    }

    private setIdSite(idSite): Observable<AllDataModel> {
        this.idSite = idSite;
        this.user = this._session.getLogin();
        this.isLogged = !!this.user;
        return this._utils.getAlldata(this.idSite);
    }

    private setAllData(data: AllDataModel): Observable<SiteModel> {
        this.allData = data;
        this.modules = {};
        data.modules.map(item => this.modules[item.key] = item);
        return this._utils.getSites();
    }

    private setSites(sites: SiteModel): Observable<void> {
        this.sites = sites.data;
        return of(null);
    }

}
