import { SocialBoxComponent } from './social-box.component';
import {SocialBoxFacebookComponent} from './social-box-facebook/social-box-facebook.component';
import {SocialBoxInstagramComponent} from './social-box-instagram/social-box-instagram.component';
import {SocialBoxTwitterComponent} from './social-box-twitter/social-box-twitter.component';
import {SocialBoxLinkedinComponent} from './social-box-linkedin/social-box-linkedin.component';

export const SocialBoxDeclarations = [
    SocialBoxComponent,
    SocialBoxFacebookComponent,
    SocialBoxInstagramComponent,
    SocialBoxTwitterComponent,
    SocialBoxLinkedinComponent,
];
