import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PanelDataModel, PanelDataValueSrc, PanelDataValueSrcCropData } from 'app/core/models/panelData.model';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-panel-form-img',
    templateUrl: './panel-form-img.component.html',
})
export class PanelFormImgComponent implements OnInit, OnDestroy, OnChanges {
    @Input() control: FormGroup;
    @Input() disabledFields: boolean = false;
    @Input() element: PanelDataModel;
    @Input() hideInputs: boolean = false;
    @Input() lng: string = null;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    public cropWidth: number = null;
    public imgCrop: PanelDataValueSrcCropData = null;
    public imgSrc: PanelDataValueSrc = null;
    public imgUrl: string = null;
    protected $subs: Subscription[] = [];

    constructor(private _st: SingeltonService, private _session: SessionService) {
        this.$subs.push(this._st.getImgObs().subscribe(res => {
            if (res && !res.lng) {
                if (res.data.keyChain === this.element.keyChain) {
                    Object.keys(res.data.value[this.lng])
                        .map(key => this.update.emit({ value: res.data.value[this.lng][key], key: key }));
                    this.buildUrlImg(res.site);
                    this.update.emit({ value: this.imgUrl, key: 'image_crop' });
                    this._st.setShowIframe(true);
                    this._st.setImg(null);
                }
            }
        }));
    }

    ngOnInit(): void {
        this.buildCropWidth();
        this.buildUrlImg();
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }

    ngOnChanges(changes: any): void {
        if (changes.hasOwnProperty('lng')) {
            this.ngOnInit();
        }
    }

    onUpdated(value: any, key: string): void {
        if (this.control.valid) {
            this.update.emit({ value: value, key: key });
        }
    }

    onRemove(): void {
        this.update.emit({ key: 'alt', value: '' });
        this.update.emit({ key: 'title', value: '' });
        this.update.emit({ key: 'original', value: '' });
        this.update.emit({ key: 'path', value: '' });
        this.update.emit({ key: 'src', value: { original: '', thumb: '', xxl: '', xl: '', l: '', m: '', s: '' } });
        this.update.emit({ key: 'cropData', value: { x: null, y: null, w: null, h: null } });

        this.buildUrlImg();
    }

    onEdit(): any {
        this._st.setShowIframe(false);
        this._st.setImg({ element: this.element, lng: this.lng });
    }

    private buildUrlImg(site_id: string = null): void {
        this.imgCrop = this.control.controls['cropData'].value;
        this.imgSrc = this.control.controls['src'].value;

        const original = this.control.controls['original'].value || null;

        if (original) {
            this.imgUrl = `${ this._session.getApi() }media/cropped-thumb?token=${ this._session.getToken() }&site_id=${ site_id || this._st.idSite }&original_path=${ original }`;
            this.imgUrl += this.element.attributes.crop
                ? `&crop=1&crop_x=${ this.imgCrop.x }&crop_y=${ this.imgCrop.y }&crop_w=${ this.imgCrop.w }&crop_h=${ this.imgCrop.h }`
                : '&crop=0';
        } else {
            this.imgUrl = null;
        }
    }

    private buildCropWidth(): void {
        const imgW = +this.element.attributes.size.w;
        const imgH = +this.element.attributes.size.h;
        this.cropWidth = 145 * (imgW / imgH); // 145px fixed height
    }
}
