import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { UtilsService } from 'app/core/services/utils.service';

@Component({
    selector: 'app-text-import-export',
    templateUrl: './text-import-export.component.html',
})
export class TextImportExportComponent {
    @Input() list: any[] = [];
    public exportLang: string = null;
    public importLang: string = null;

    constructor(
        private _api: ApiService,
        private _st: SingeltonService,
        private _toast: ToastService,
        private _utils: UtilsService,
    ) {
    }

    exportCSV(): void {
        if (this.exportLang) {
            const httpParams: any = {
                params: new HttpParams().append('filter_site_id', this._st.idSite),
                responseType: 'blob',
            };

            this._api.get(`translations/export/${ this.exportLang }`, httpParams).subscribe(data => {
                this._utils.downloadFile(data, `translations_${ this.exportLang }.xlsx`);
                this._toast.showMessage('message.export', true);
                this.exportLang = null;
            });
        }
    }

    importCSV(e): void {
        if (this.importLang) {
            const file = e.target.files[0];

            const formData: FormData = new FormData();
            formData.append('file', file);

            this._api.post('uploads', formData).subscribe(data => this.uploadedFile(data, e));
        }
    }

    private uploadedFile(fileData, file): void {
        file.target.value = '';

        const model: any = {
            site_id: this._st.idSite,
            upload_id: fileData.id,
        };

        this._api.post('translations/import/' + this.importLang, model, { responseType: 'text' })
            .subscribe(() => this._toast.showMessage('message.import', true));
    }

}
