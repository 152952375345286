import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { DragulaModule } from 'ng2-dragula';
import { MediaModule } from '../media/media.module';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { ServicesComponent } from './services.component';
import { ServicesRouting } from './services.routing';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        DragulaModule,
        FormsModule,
        MediaModule,
        ReactiveFormsModule,
        RouterModule.forChild(ServicesRouting),
        TranslateModule,
    ],
    declarations: [
        ServicesComponent,
        ServicesListComponent,
    ],
    exports: [
        RouterModule,
    ],
})
export class ServicesModule {
}
