import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BacDropInOutAnimation } from 'app/core/animations/backDropInOut.animation';
import { SlideInOutAnimation } from 'app/core/animations/slideInOut.animation';
import { SingeltonService } from 'app/core/services/singelton.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-right-panel',
    templateUrl: './right-panel.component.html',
    animations: [BacDropInOutAnimation, SlideInOutAnimation],
})
export class RightPanelComponent implements OnInit, OnDestroy {
    @Input() dataMain: any;
    public showPanel: boolean = true;
    protected $subs: Subscription[] = [];

    constructor(private _st: SingeltonService) {
        this.$subs.push(this._st.getShowIframeObs().subscribe(res => this.showPanel = res));
    }

    ngOnInit(): void {
        this._st.setPanel(this.dataMain);
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }

    onClose(): void {
        this._st.setPanel(null);
    }

}
