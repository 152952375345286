import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';
import { PreviewComponent } from './components/preview/preview.component';
import { PreviewContainerComponent } from './preview.component';

// export const PreviewRouting: Routes = [
//     { path: ':idsite/preview', component: PreviewComponent, canActivate: [AuthGuard] },
// ];

export const PreviewRouting: Routes = [
    {
        path: ':idsite', component: PreviewContainerComponent, canActivate: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'preview', canActivate: [AuthGuard] },
            { path: 'preview', component: PreviewComponent, canActivate: [AuthGuard] },
        ],
    },
];
