import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';
import { NewsListComponent } from './components/news-list/news-list.component';
import { NewsComponent } from './news.component';

export const NewsRouting: Routes = [
    {
        path: ':idsite/news', component: NewsComponent, canActivate: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'news-list', canActivate: [AuthGuard] },
            { path: 'news-list', component: NewsListComponent, canActivate: [AuthGuard] },
        ],
    },
];
