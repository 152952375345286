import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { LeadsService } from 'app/modules/leads/services/leads.service';

@Component({
    selector: 'app-leads-items',
    templateUrl: './leads-items.component.html',
})
export class LeadsItemsComponent implements OnInit {
    @Input() idSite: string = null;
    @Input() item: any = null;
    @Input() lng: any = [];
    @Output() deleted: EventEmitter<string> = new EventEmitter<string>();
    @Output() back: EventEmitter<void> = new EventEmitter<void>();
    public notSupported: { key: string, value: any }[] = [];
    private hotelList: any;
    private listSupported: string[] = [];

    constructor(private _service: LeadsService, private _alert: AlertPopupService) {
        this.listSupported = [
            'acceptConditions',
            'accommodation',
            'address',
            'adults',
            'agreement',
            'babyies',
            'booking',
            'catering',
            'checked',
            'children',
            'comercial',
            'comments',
            'company',
            'country',
            'currentUrl',
            'date',
            'date_reservation',
            'date_reservation',
            'department',
            'email',
            'enterprise',
            'fax',
            'file',
            'group_name',
            'hotel',
            'hotel_names',
            'language',
            'last_name',
            'legal',
            'message',
            'name',
            'needs',
            'obra',
            'observations',
            'offer',
            'offerName',
            'people',
            'phone',
            'population',
            'postal_code',
            'product',
            'room_flex_dates',
            'room_from_date',
            'room_regime',
            'room_rooms',
            'room_to_date',
            'room_typology',
            'services',
            'subscribe',
            'surname',
            'treatment',
            'venue_date',
            'venue_equipment',
            'venue_extra',
            'venue_pax',
            'venue_schedule',
            'service',
            'workPermit',
            'availability',
            'link',
            'budgetCheckIn',
            'journeys',
            'assistants',
            'assembly',
            'lodging',
            'contact',
            'mail',
            'budgetLegal',
            'event',
            'restaurant',
            'phone_prefix',
            'date_in',
            'date_out',
            'room_type',
            'code',
            'status',
            'day',
            'hour',
            'comment',
            'amount',
            'payment_method',
            'payment_status',
            'payment_date',
            'type',
            'menu',
            'receiver_name',
            'receiver_email',
            'template',
            'delivery_method',
            'delivery_date',
            'invoice',
            'invoice_name',
            'invoice_surname',
            'invoice_nif',
            'invoice_email',
            'invoice_phone',
            'invoice_country',
            'invoice_city',
            'invoice_postal_code',
            'invoice_address',
            'booking_identifier',
            'booking_date',
            'booking_channel',
            'enhances'
        ];
    }

    ngOnInit(): void {
        this.getHotels();
        this.buildKeysNotSuported();
    }

    onBack(): void {
        this.back.emit();
    }

    getNeedsList(list): string {
        if (typeof list === 'string') {
            return this.lng['needs_' + list];
        }

        const newList = list.map(item => this.lng['needs_' + item]);
        return newList.join(', ');
    }

    getBookingList(list): string {
        if (typeof list === 'string') {
            return list;
        }

        const newList = list.map(item => this.lng['booking_' + item]);
        return newList.join(', ');
    }

    getExtrasList(list): string {
        if (typeof list === 'string') {
            return list;
        }

        const newList = list.map(item => this.lng['venue_extra_' + item]);
        return newList.join(', ');
    }

    getServicesList(list): string {
        if (typeof list === 'string') {
            return list;
        }

        const newList = list.map(item => this.lng['services_' + item]);
        return newList.join(', ');
    }

    getHotelsList(list): string {
        if (typeof list === 'string') {
            return list;
        }
        const newList = [];

        Object.keys(list).forEach(key => newList.push(list[key]));
        return newList.join(', ');
    }

    showDelete(id) {
        this._alert.show(
            {
                title: this.lng.delete.title,
                content: this.lng.delete.content,
                buttonConfirmType: 'delete',
                buttonConfirmText: this.lng.delete.buttonConfirmText,
                buttonCancelType: 'ghost',
                buttonCancelText: this.lng.delete.buttonCancelText,
            },
            this.showDeleteFunction.bind(this, id),
        );
    }

    private buildKeysNotSuported(): void {
        Object.keys(this.item.content).map(key => {
            const filter: any[] = this.listSupported.filter(a => a === key);
            if (!filter.length) {
                this.notSupported.push({ key: key, value: this.item.content[key] });
            }
        });
    }

    private getHotels(): void {
        this._service.getHotels(this.idSite).subscribe(res => this.hotelList = res['data']);
    }

    private showDeleteFunction(id: string, res: boolean): void {
        if (res) {
            this.deleted.emit(id);
        }
    }


}
