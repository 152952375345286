import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
    private persist: boolean = false;
    private loadingSubject: Subject<boolean> = new Subject<boolean>();


    getLoadingObs(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    showLoading(persist: boolean = false): void {
        this.persist = persist;
        this.loadingSubject.next(true);
    }

    hideLoading(persist: boolean = false): void {
        if (!this.persist || persist) {
            this.persist = false;
            this.loadingSubject.next(false);
        }
    }


}
