import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel } from 'app/core/models/allData.model';
import { FilterModel } from 'app/core/models/filter.model';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { IMyDpOptions } from 'mydatepicker';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-agenda-list',
    templateUrl: './agenda-list.component.html',
})
export class AgendaListComponent implements OnDestroy {
    @Input() select: boolean = false;
    public activeElements = 0;
    public agenda: any[];
    public agendaLang: any;
    public configObs$: Subscription = new Subscription();
    public defaultLang: string = '';
    public formFilters: FormGroup;
    public generalLang: any;
    public myDatePickerOptions: IMyDpOptions;
    public pagination: FilterModel;
    public urlPreview: any = null;
    private siteDomain;
    private siteLangs;

    constructor(
        private _api: ApiService,
        private _fb: FormBuilder,
        private _lng: TranslateService,
        private _modal: AlertPopupService,
        private _st: SingeltonService,
        private _utils: UtilsService,
        private _session: SessionService,
    ) {
        this._lng.get('general').subscribe(res => this.generalLang = res);
        this._lng.get('agenda').subscribe(res => this.agendaLang = res);

        this.myDatePickerOptions = {
            dateFormat: 'dd/mm/yyyy',
            openSelectorOnInputClick: true,
            editableDateField: false,
            firstDayOfWeek: 'mo',
            showSelectorArrow: false,
            alignSelectorRight: true,
        };

        this.formFilters = this._fb.group({
            name: [''],
            date_from: [''],
            date_to: [''],
        });

        this._utils.getAlldata(this._st.idSite).subscribe(data => {
            this._st.setAllData(data);
            this.siteLangs = data.languages;
            this.siteDomain = data.domain;
            const a: LanguageModel[] = data.languages.filter(l => l.default);
            this.defaultLang = a[0].language;
        });

        this.getAgenda();
    }

    ngOnDestroy() {
        this.configObs$.unsubscribe();
    }

    getDate(from, to) {
        if (to) {
            return from === to ? this.formatDate(from) : `${ this.formatDate(from) } - ${ this.formatDate(to) }`;
        } else {
            return this.formatDate(from);
        }
    }

    onSearch() {
        this.pagination = null;
        this.getAgenda();
    }

    onReset() {
        this.formFilters.reset();
        this.onSearch();
    }

    onNext() {
        this.pagination.current_page++;
        this.getAgenda();
    }

    onPrev() {
        this.pagination.current_page--;
        this.getAgenda();
    }

    onCreate() {
        this._api.post('agenda/events', { site_id: this._st.idSite }).subscribe(data => {
            this._st.setConfigPanel({ ...data, alternates: data.urls, persistData: true });
            this.configObs$ = this._st.getConfigPanelObs().subscribe(res => {
                data.urls = res.slice(2);
                this.configObs$.unsubscribe();
                this._st.setConfigPanel(null);
                this.onEdit(data);
            });
        });
    }

    onEdit(data) {
        const urlPreviewTemp = {};
        const lng = this._session.getLng();
        const filter = data.urls.filter(url => url.language === lng);
        if (filter.length) {
            data.urls.map(url => urlPreviewTemp[url.language] = url);
        }
        this._api.get('agenda/events/' + data['id'] + '/highlight').subscribe(res => {
            this._st.setShowModule(false);
            this.urlPreview = urlPreviewTemp;
            this._st.setPanel({ data: res, src: 'agenda/events', ref: data.id });
        });
    }

    onClickPreview(event: any): void {
        this._st.setPanel(event);
    }

    onBackPreview(): void {
        this._st.setShowModule(true);
        this.getAgenda();
        this.urlPreview = null;
    }

    onSelect(event): void {
        this._api.get(`agenda/events/${ event.id }/highlight`)
            .subscribe(res => this._st.setAgenda(res));
    }

    onBack(): void {
        this._st.setAgenda(null);
        this._st.setPanel(null);
    }

    onActivate(event, status) {
        if (status) {
            this.saveEventStatus(event, status);
            // this._api.get(`agenda/events/${ event.id }/highlight`).subscribe((highlightData) => {
            //     if (!this.checkLangsStatus(highlightData['languagesStatus'])) {
            //         this._modal.show({
            //             content: this.agendaLang.enable_event.content,
            //             buttonCancelType: 'ghost',
            //             buttonCancelText: this.agendaLang.enable_event.buttonCancelText,
            //         }, false);
            //
            //         event.page.active = !status;
            //     } else {
            //         this.saveEventStatus(event);
            //     }
            // });
        } else {
            this.saveEventStatus(event, status);
        }
    }

    onRemove(event) {
        const fun = (e, answer) => {
            if (answer) {
                this._api.delete('agenda/events/' + e.id)
                    .subscribe(() => this.agenda = this.agenda.filter((a) => a.id !== e.id));
            }
        };

        this._modal.show({
            title: this.agendaLang.delete.title,
            content: this.agendaLang.delete.content,
            buttonConfirmType: 'delete',
            buttonConfirmText: this.agendaLang.delete.buttonConfirmText,
            buttonCancelType: 'ghost',
            buttonCancelText: this.agendaLang.delete.buttonCancelText,
        }, fun.bind(this, event));

    }

    private getAgenda() {
        const values: any = this.formFilters.getRawValue();
        let params = new HttpParams().append('filter_site_id', this._st.idSite);

        if (values.name) {
            params = params.append('filter_name', values.name);
        }
        if (values.date_from) {
            params = params.append('filter_date_from', this._utils.parseDatepicker(values.date_from));
        }
        if (values.date_to) {
            params = params.append('filter_date_to', this._utils.parseDatepicker(values.date_to));
        }
        if (this.pagination && this.pagination.current_page) {
            params = params.append('page', this.pagination.current_page.toString());
        }

        this._api.get('agenda/events', { params: params }).subscribe((data: FilterModel) => {
            this.pagination = data;
            this.agenda = data.data;

            this.agenda.forEach(element => {
                if (element.page.active) {
                    this.activeElements = this.activeElements + 1;
                }

            });
        });
    }

    private formatDate(date) {
        const dateObj = new Date(date);
        return `${ dateObj.getDate() } ${ this.generalLang.months[(dateObj.getMonth() + 1)].substr(0, 3) }`;
    }

    private saveEventStatus(event, status): void {
        event.page.active = status;
        this._api.put(`pages/${ event.page.id }`, event.page).subscribe();
    }

    // private checkLangsStatus(languagesStatus) {
    //     let status = false;
    //
    //     Object.keys(languagesStatus)
    //         .map(function (index) {
    //             if (languagesStatus[index].filled) {
    //                 status = true;
    //             }
    //         });
    //
    //     return status;
    // }
}
