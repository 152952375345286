import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-buy',
    templateUrl: './buy.component.html',
})
export class BuyComponent {
    @Input() name: string = null;

    constructor() {
    }

}
