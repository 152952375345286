export enum ModerationType {
    BLACK_LIST = 'black-list',
    WHITE_LIST = 'white-list'
}

export enum SocialAccountType {
    INSTAGRAM = 'instagram',
    TWITTER = 'twitter',
    FACEBOOK = 'facebook',
    LINKEDIN = 'linkedin'
}

export interface SocialAccountModel {
    id: string,
    type: SocialAccountType;
    name: string;
    authorized: boolean;
    data: any;
    feed: any[];
    black_list: string[];
    white_list: string[];
    moderation_type: ModerationType;
    account_id: string;
    site_id: string;
    created_at: string;
    updated_at: string;
}
