import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';

@Injectable({ providedIn: 'root' })
export class ToastService {

    constructor(private toasterService: ToasterService, private _lng: TranslateService) {
    }

    showNotKnowError() {
        this.toasterService.pop('error', '', 'Se ha producido un error. Inténtalo de nuevo');
    }

    showError(error: string) {
        this.toasterService.pop('error', '', error);
    }

    showMessage(message: string, translateService: boolean = false, type: 'success' | 'error' = 'success') {
        translateService
            ? this._lng.get(message).subscribe(res => this.toasterService.pop(type, '', res))
            : this.toasterService.pop(type, '', message);
    }


}
