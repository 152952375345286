import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-panel-form-label',
    templateUrl: './panel-form-label.component.html',
})
export class PanelFormLabelComponent {
    @Input('type') type: string;
    @Input() br: boolean = true;
    @Input() control: AbstractControl;
    @Input() element: any;
    @Input() hideRequired: boolean = false;
    @Input() isDate: boolean = false;
    @Input() label: string = null;
    @Input() showLabel: boolean = true;
}
