import {FeedComponent} from './feed.component';
import {FeedInstagramComponent} from './feed-instagram/feed-instagram.component';
import {FeedFacebookComponent} from './feed-facebook/feed-facebook.component';
import {FeedTwitterComponent} from './feed-twitter/feed-twitter.component';
import {FeedLinkedinComponent} from './feed-linkedin/feed-linkedin.component';

export const FeedDeclarations = [
    FeedComponent,
    FeedInstagramComponent,
    FeedFacebookComponent,
    FeedTwitterComponent,
    FeedLinkedinComponent,
];
