import { animate, style, transition, trigger } from '@angular/animations';

export const BacDropInOutAnimation = trigger('backDropInOut', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 0.5 })),
    ]),
    transition(':leave', [
        animate('300ms ease-in-out', style({ opacity: 0 })),
    ]),
]);
