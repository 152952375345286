import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllDataModel } from 'app/core/models/allData.model';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';

@Component({
    templateUrl: './tools.component.html',
})
export class ToolsComponent {
    public pageList: any[] = [];

    constructor(
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _st: SingeltonService,
        private _utils: UtilsService,
    ) {
        this._st.resetAll();

        this._st.setIdSite(this._route.snapshot.paramMap.get('idsite'));

        this._utils.getAlldata(this._st.idSite).subscribe((res: AllDataModel) => {
            const settings = res.modules.find(element => element['key'] === 'settings');
            if (settings) {
                this.pageList = settings && settings.active
                    ? settings['sub-modules'].filter(item => item.active)
                    : [];
            }
        });
    }
}
