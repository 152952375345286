import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllDataModel, ModuleModel } from 'app/core/models/allData.model';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';

@Component({
    selector: 'app-leads',
    templateUrl: './leads.component.html',
})
export class LeadsComponent {
    public pageList: ModuleModel[] = [];
    public type: string = null;

    constructor(private _route: ActivatedRoute, private _st: SingeltonService, private _utils: UtilsService) {
        this._st.resetAll();

        this._st.setIdSite(this._route.snapshot.paramMap.get('idsite'));

        this._utils.getAlldata(this._st.idSite).subscribe((res: AllDataModel) => {
            const leads: ModuleModel = res.modules.find(item => item.key === 'leads');
            this.pageList = leads && leads.active ? leads['sub-modules'] : [];
            this.pageList = this.pageList.filter(item => item['active']);
            this.type = this.pageList[0] ? this.pageList[0].key : null;
        });

    }

    onSelect(item: ModuleModel): void {
        this.type = null;
        setTimeout(() => {
            this.type = item.key;
        });
    }
}
