import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';
import { MediaDocComponent } from './components/media-doc/media-doc.component';
import { MediaImgComponent } from './components/media-img/media-img.component';
import { MediaVideoComponent } from './components/media-video/media-video.component';
import { MediaComponent } from './media.component';

export const MediaRouting: Routes = [
    {
        path: ':idsite/media', component: MediaComponent, canActivate: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'img', canActivate: [AuthGuard] },
            { path: 'img', component: MediaImgComponent, canActivate: [AuthGuard] },
            { path: 'video', component: MediaVideoComponent, canActivate: [AuthGuard] },
            { path: 'doc', component: MediaDocComponent, canActivate: [AuthGuard] },
        ],
    },
];
