import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BacDropInOutAnimation } from 'app/core/animations/backDropInOut.animation';
import { SlideInOutAnimation } from 'app/core/animations/slideInOut.animation';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { MediaService } from 'app/modules/media/services/media.service';

@Component({
    selector: 'app-media-panel',
    templateUrl: './media-panel.component.html',
    animations: [BacDropInOutAnimation, SlideInOutAnimation],
})
export class MediaPanelComponent implements OnInit, OnDestroy {
    @Input() data: any;
    @Input() tagList: any[] = [];
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    public form: FormGroup;
    public formTag: FormGroup;
    public selectedLang: string = null;
    public showAddTag: boolean = false;
    public videoPlaceholder: string = '';

    constructor(
        private _alert: AlertPopupService,
        private _fb: FormBuilder,
        private _lng: TranslateService,
        private _service: MediaService,
        private _st: SingeltonService,
        private _toast: ToastService,
    ) {
    }

    ngOnInit(): void {
        window.scrollTo({ top: 0 });
        document.body.style.overflow = 'hidden';
        this.activeTags();
        this.buildForms();
        this.getPlaceholder();
    }

    ngOnDestroy(): void {
        document.body.style.overflow = 'auto';
    }

    onClose(reset: boolean = false): void {
        this.close.emit(reset);
    }

    onChangeLang(event: string): void {
        this.selectedLang = event;
    }

    onClosePendingAlert(): void {
        this.data.pending = 0;
        this._service.updateMediaItem(this.data, this.data.type).subscribe();
    }

    onTag(pos): void {
        this.tagList[pos].active = this.tagList[pos].hasOwnProperty('active') ? !this.tagList[pos].active : true;

        const newTags: string[] = [];
        this.tagList.map(tag => {
            if (tag.active) {
                newTags.push(tag.tag);
            }
        });
        this.data.tags = newTags;

    }

    onCancelNewTag(): void {
        this.showAddTag = false;
        this.formTag.controls['tag'].setValue('');
    }

    onAddNewTag(): void {
        const val: string = this.formTag.controls['tag'].value.toLowerCase();
        const existTag: any[] = this.tagList.filter(tag => tag.tag.toLowerCase() === val);
        if (existTag.length === 0) {
            this.tagList.push({ tag: val, active: true });
            this.data.tags.push(val);
            this.activeTags();
            this.onCancelNewTag();
        } else {
            this._toast.showMessage('panels.tags.repeat', true, 'error');
        }
    }

    onSave(): void {
        let msg: string;
        if (this.data.type === 'image') {
            msg = 'message.image_update';
        } else if (this.data.type === 'video') {
            msg = 'message.video_update';
        } else if (this.data.type === 'document') {
            msg = 'message.document_update';
        }

        const model: any = this.setValuesToData();

        if (this.data.source && this.data.type === 'video') {
            this._service.createMediaItem(model, model.site_id, this.data.type).subscribe(() => {
                this._toast.showMessage('message.video_upload', true);
                this.onClose(true);
            });
        } else {
            this._service.updateMediaItem(model, this.data.type).subscribe(() => {
                this._toast.showMessage(msg, true);
                this.onClose(true);
            });
        }
    }

    onDelete(): void {
        let key: string;
        let msg: string;
        if (this.data.type === 'image') {
            key = 'media_image';
            msg = 'message.image_remove';
        } else if (this.data.type === 'video') {
            key = 'media_video';
            msg = 'message.video_update';
        } else if (this.data.type === 'document') {
            key = 'document';
            msg = 'message.document_remove';
        }


        const fun: any = resp => {
            if (resp) {
                this._service.deleteMediaItem(this.data.id).subscribe(() => {
                    this._toast.showMessage(msg, true);
                    this.onClose(true);
                });
            }
        };


        this._lng.get('panels').subscribe(res => {
            this._alert.show({
                    title: res[key].delete.title,
                    content: res[key].delete.content,
                    buttonConfirmType: 'delete',
                    buttonConfirmText: res[key].delete.buttonConfirmText,
                    buttonCancelType: 'ghost',
                    buttonCancelText: res[key].delete.buttonCancelText,
                }, fun.bind(this),
            );
        });
    }

    getPreviewVideo(): any {
        const typeAndId = this.getVideoTypeAndId(this.data.source
            ? this.form.controls['url'].value
            : this.data.data.url,
        );

        this.data.data.video_code = typeAndId.id || '';

        if (typeAndId.type !== null) {
            return this._service.parseIframeUrl(typeAndId.id, typeAndId.type, 1, 1);
        } else {
            return '';
        }
    }

    private getVideoTypeAndId(url: string) {
        const returnData = { type: null, id: null };

        [/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i]
            .forEach(element => {
                const match = url.match(element);
                if (match) {
                    returnData.type = 'vimeo';
                    returnData.id = match[1];
                    return returnData;
                }
            });

        [/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/].forEach(element => {
            const match = url.match(element);
            if (match) {
                returnData.type = 'youtube';
                returnData.id = match[7];
                return returnData;
            }
        });

        return returnData;
    }

    private getPlaceholder(): void {
        if (this.data.type === 'video' && this.data.source) {
            this.videoPlaceholder = this.data.source === 'youtube'
                ? 'https://www.youtube.com/watch?v=XXXXXXXXXXX'
                : 'https://vimeo.com/XXXXXXXXX';
        }
    }

    private activeTags(): void {
        if (this.data.tags.length !== 0) {
            this.data.tags.map(tag => {
                for (let i = 0; this.tagList.length; i++) {
                    if (tag === this.tagList[i].tag) {
                        this.tagList[i].active = true;
                        break;
                    }
                }
            });
        }
    }

    private buildForms(): void {
        this.formTag = this._fb.group({ tag: ['', Validators.required] });
        this.form = new FormGroup({});

        // IMG
        if (this.data.type === 'image') {
            this._st.allData.languages.map(lng => {
                this.form.addControl(lng.language, new FormGroup({
                    alt: this._fb.control(this.data.data.alt[lng.language] || '', []),
                    title: this._fb.control(this.data.data.title[lng.language] || '', []),
                }));
            });
        }

        // DOC
        if (this.data.type === 'document') {
            this._st.allData.languages.map(lng => {
                this.form.addControl(lng.language, new FormGroup({
                    title: this._fb.control(this.data.data.title[lng.language] || '', []),
                }));
            });
        }

        // VIDEO
        if (this.data.type === 'video') {
            this._st.allData.languages.map(lng => {
                this.form.addControl(lng.language, new FormGroup({
                    name: this._fb.control(this.data.data.name[lng.language] || '', []),
                    description: this._fb.control(this.data.data.description[lng.language] || '', []),
                }));
            });
            if (this.data.source) {
                this.form.addControl('url', this._fb.control('', Validators.required));
            }
        }
    }

    private setValuesToData(): any {
        const formVals: any = this.form.getRawValue();
        const ret: any = JSON.parse(JSON.stringify(this.data));

        // IMG
        if (this.data.type === 'image') {
            ret.data.alt = {};
            ret.data.title = {};
            this._st.allData.languages.map(lng => {
                ret.data.alt[lng.language] = formVals[lng.language].alt;
                ret.data.title[lng.language] = formVals[lng.language].title;
                if (ret.pending !== 0 && formVals[lng.language].title) {
                    ret.pending = 0;
                }
            });
        }

        // DOC
        if (this.data.type === 'document') {
            ret.data.title = {};
            this._st.allData.languages.map(lng => {
                ret.data.title[lng.language] = formVals[lng.language].title;
                if (ret.pending !== 0 && formVals[lng.language].title) {
                    ret.pending = 0;
                }
            });
        }

        // VIDEO
        if (this.data.type === 'video') {
            ret.data.name = {};
            ret.data.description = {};
            this._st.allData.languages.map(lng => {
                ret.data.name[lng.language] = formVals[lng.language].name;
                ret.data.description[lng.language] = formVals[lng.language].description;
                if (ret.pending !== 0 && formVals[lng.language].name) {
                    ret.pending = 0;
                }
            });
            if (this.data.source) {
                ret.data.source = ret.source;
                ret.data.url = formVals.url;
            }
        }

        return ret;
    }
}
