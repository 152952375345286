import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
    public disabledAll: boolean = true;
    public user: any;
    public year: number;

    constructor(
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _session: SessionService,
        private _st: SingeltonService,
    ) {
        this._st.resetAll();

        this._st.setIdSite(this._route.snapshot.paramMap.get('idsite'));
    }

    ngOnInit(): void {
        const login: any = this._session.getLogin();
        this._api.get(`users/${ login.user.id }`)
            .subscribe(res => {
                this.user = res;
                this.disabledAll = res.source !== 'fishh';
            });

        this.year = new Date().getFullYear() + 1;
    }

}
