import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-social-input',
    templateUrl: './social-input.component.html',
})
export class SocialInputComponent {
    @Input() control: AbstractControl;
    @Input('label') label: string;
    @Input('error') error: string;
    @Output() updated: EventEmitter<any> = new EventEmitter<any>();

    onBlur() {
        this.updated.emit(this.control.value);
    }
}
