import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { TabsElementComponent } from 'app/core/components/tabs/tabs-element/tabs-element.component';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
})
export class TabsComponent implements AfterContentInit {
    @ContentChildren(TabsElementComponent) tabs: QueryList<TabsElementComponent>;

    ngAfterContentInit(): void {
        this.selectTab(0);
    }

    selectTab(pos: number): void {
        this.tabs.toArray().forEach((tab: TabsElementComponent) => tab.active = false);
        this.tabs.toArray()[pos].active = true;
    }


}
