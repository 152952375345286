import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BacDropInOutAnimation } from 'app/core/animations/backDropInOut.animation';
import { SlideInOutAnimation } from 'app/core/animations/slideInOut.animation';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ApiService } from 'app/core/services/api.service';
import { LoadingService } from 'app/core/services/loading.service';
import { RightPanelService } from 'app/core/services/right-panel.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-config-panel',
    templateUrl: './config-panel.component.html',
    animations: [BacDropInOutAnimation, SlideInOutAnimation],
})
export class ConfigPanelComponent implements OnInit, OnDestroy {
    public configData: any = null;
    public formConfig: FormGroup;
    public formSeo: FormGroup;
    public formSocial: FormGroup;
    public isHome: boolean = false;
    public selectedLang: string = null;
    public seoData: any = null;
    public showDelete: boolean = false;
    public showPanel: boolean = true;
    public socialData: any = null;
    public isAdd: boolean = false;
    public editedURl: boolean = false;
    protected $subs: Subscription[] = [];
    private configFixed: any;

    constructor(
        private _alert: AlertPopupService,
        private _api: ApiService,
        private _fb: FormBuilder,
        private _lng: TranslateService,
        private _loading: LoadingService,
        private _rp: RightPanelService,
        private _st: SingeltonService,
        private _toast: ToastService,
    ) {
        this.$subs.push(this._st.getShowIframeObs().subscribe(res => this.showPanel = res));
    }

    ngOnInit(): void {
        this.showDelete = this._st.canDelete && this._st.canDelete.hasOwnProperty('can') && this._st.canDelete.can === true;
        this._api.get(`pages/${ this._st.configPanel.page_id }/content`).subscribe((res: any[]) => {
            for (let i = 0; i < res.length; i++) {
                if (res[i].key === 'seo') {
                    this.seoData = res[i];
                }
                if (res[i].key === 'social') {
                    this.socialData = res[i];
                }

                if (this.seoData && this.socialData) {
                    break;
                }
            }
            this.buildForms();
        });
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }

    onClose(): void {
        this._st.setConfigPanel(null);
    }

    onUpdateconfig(key: string, control: any): void {
        if (key === 'name') {
            this.suggestPageURL();
        }

        if (key === 'url') {
            for (let i = 0; i < this.configFixed.alternates.length; i++) {
                if (this.configFixed.alternates[i].language === this.selectedLang &&
                    this.formConfig.get(this.selectedLang)
                        .get('url').value !== this.configFixed.alternates[i].url) {
                    this.editedURl = true;
                    break;
                } else {
                    this.editedURl = false;
                }
            }
        }

        for (let i = 0; i < this.configData.alternates.length; i++) {
            if (this.selectedLang === this.configData.alternates[i].language) {
                this.configData.alternates[i][key] = control.value;
                break;
            }
        }
    }

    onUpdateSeo(value: any, control: any, keyChain: string): void {
        control.setValue(value);
        for (let i = 0; i < this.seoData.elements.length; i++) {
            if (this.seoData.elements[i].keyChain === keyChain) {
                this.seoData.elements[i].value[this.selectedLang] = value;
                break;
            }
        }
    }

    onUpdateSocial(value: any, control: any, keyChain: string, parent: string): void {
        control.setValue(value);
        for (let i = 0; i < this.socialData.elements.length; i++) {
            if (this.socialData.elements[i].keyChain === parent) {
                for (let a = 0; a < this.socialData.elements[i].elements.length; a++) {
                    if (this.socialData.elements[i].elements[a].keyChain === keyChain) {
                        this.socialData.elements[i].elements[a].value[this.selectedLang] = value;
                        break;
                    }
                }
                break;
            }
        }
    }

    onUpdateSocialImg(event: any, control: any, keyChain: string, parent: string): void {
        control.controls[event.key].setValue(event.value);

        for (let i = 0; i < this.socialData.elements.length; i++) {
            if (this.socialData.elements[i].keyChain === parent) {
                for (let a = 0; a < this.socialData.elements[i].elements.length; a++) {
                    if (this.socialData.elements[i].elements[a].keyChain === keyChain) {
                        this.socialData.elements[i].elements[a].value[this.selectedLang][event.key] = event.value;
                        break;
                    }
                }
                break;
            }
        }
    }

    getSerpUrl(): string {
        return `${ this._st.allData.domain }/${ this.selectedLang }/${ this.formConfig.value.url || '' }`;
    }

    suggestPageURL(): void {
        if ((this.isAdd || !this.formConfig.get(this.selectedLang).get('url').value) && !this.isHome
            && this.formConfig.get(this.selectedLang).get('name').value) {
            let suggestPageData: any = {};
            let urlToPost: string = '';

            if (this.configData.menu_id) {
                suggestPageData = {
                    id: this.configData.menu_id,
                    site_id: this.configData.site_id,
                    language: this.selectedLang,
                    name: this.formConfig.get(this.selectedLang).get('name').value,
                };

                urlToPost = 'menus/' + this.configData.menu_id + '/suggest-url';
            } else {
                suggestPageData = {
                    site_id: this.configData.site_id,
                    language: this.selectedLang,
                    name: this.formConfig.get(this.selectedLang).get('name').value,
                };

                urlToPost = 'menus/suggest-url';
            }

            this._api.post(urlToPost, suggestPageData).subscribe(data => {
                this.formConfig.get(this.selectedLang).get('url').setValue(data.url);
                this.onUpdateconfig('url', this.formConfig.get(this.selectedLang).get('url'));
            });
        }
    }

    onSave(): void {
        this.formConfig.get(this.selectedLang).get('name').markAsDirty();
        this.formConfig.get(this.selectedLang).get('url').markAsDirty();

        const formValues = this.formConfig.getRawValue();

        /**
         * No podemos usar forkJoin para todas las promesas porqué la API
         * no permite peticiones concurrentes.
         * El resultado lo acumulamos de manera que el resultado de seoObs y 
         * socialObs esten al inicio del array de resultados... de no ser así
         * los modulos petan porqué hacen un slice(2) sobre la respuesta del
         * panel de configuracion
         */
        const seoObs$ = this._api.put(`pages/${ this._st.configPanel.page_id }/content/seo`, this.seoData);
        const socialObs$ = this._api.put(`pages/${ this._st.configPanel.page_id }/content/social`, this.socialData);
        const obs$: any[] = [];

        this._st.allData.languages.map(lng => {
            const haveAl: any[] = this.configData.alternates.filter(al => al.language === lng.language);
            if (haveAl.length !== 0) {
                obs$.push(this._api.post(`urls`, {
                    ...haveAl[0],
                    ...formValues[lng.language],
                    language: lng.language,
                }));
            } else {
                const model: any = {
                    ...this.configData.alternates[0],
                    ...formValues[lng.language],
                    language: lng.language,
                };
                delete model.id;
                if ((model.url || this.isHome) && model.name) {
                    obs$.push(this._api.post(`urls`, model));
                }
            }
        });

        this._loading.showLoading(true);
        this.checkCorrectSuggest().subscribe(res => {
            this._loading.showLoading(true);
            forkJoin(obs$).subscribe(resUrl => {
                seoObs$.subscribe(resSeo => {
                    socialObs$.subscribe(resSocial => {
                        /**
                         * ordeno los resultados antes de
                         * propagar
                         */
                        const res: any[] = [];
                        res.push(resSeo);
                        res.push(resSocial);
                        resUrl.map((item) => {
                            res.push(item);
                        });
                        this.configData.hasOwnProperty('persistData')
                            ? this._st.setConfigPanel(res)
                            : this._st.setConfigPanel(null);
                        this._loading.hideLoading(true);
                        this._toast.showMessage('message.page_conf', true);
                    });
                });
            });
        });
    }

    onDelete(): void {
        const fun = resp => {
            if (resp) {
                this._api.delete(`menus/${ this._st.canDelete.id }/delete-page/${ this._st.configPanel.page_id }`)
                    .subscribe(() => {
                        this._toast.showMessage('message.page_remove', true);
                        this._st.setCanDelete(null);
                        this._st.setConfigPanel(null);
                        this._st.setRoute(null);
                    });
            }
        };

        this._lng.get('panels').subscribe(res => {
            this._alert.show({
                    title: res.page_edit.delete.title,
                    content: res.page_edit.delete.content,
                    buttonConfirmType: 'delete',
                    buttonConfirmText: res.page_edit.delete.buttonConfirmText,
                    buttonCancelType: 'ghost',
                    buttonCancelText: res.page_edit.delete.buttonCancelText,
                }, fun.bind(this),
            );
        });
    }

    private buildForms(): void {
        this.configFixed = JSON.parse(JSON.stringify(this._st.configPanel));

        this.formSeo = this._rp.buildForm(this.seoData);
        this.configData = JSON.parse(JSON.stringify(this._st.configPanel));
        this.formConfig = new FormGroup({});

        this.configData.alternates.map(al => this.isHome = al.home === 1 || al.home === true);

        this._st.allData.languages.map(lng => {
            let exist: boolean = false;
            for (let i = 0; i < this.configData.alternates.length; i++) {
                if (this.configData.alternates[i].language === lng.language) {
                    this.formConfig.addControl(lng.language, new FormGroup({
                        name: this._fb.control(this.configData.alternates[i].name || '', Validators.required),
                        url: this._fb.control(this.configData.alternates[i].url || '', Validators.required),
                    }));
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                this.formConfig.addControl(lng.language, new FormGroup({
                    name: this._fb.control('', Validators.required),
                    url: this._fb.control('', Validators.required),
                }));
            }
        });

        this.formSocial = new FormGroup({});
        this.socialData.elements.map(element => {
            element.elements.map(el => {
                const tempForm = new FormGroup({});
                this._st.allData.languages.map(al => {
                    if (el.type !== 'image') {
                        tempForm.addControl(al.language, this._fb.control(el.value[al.language], []));
                    } else {
                        tempForm.addControl(al.language, new FormGroup({
                            alt: this._fb.control(el.value[al.language].alt, []),
                            cropData: this._fb.control(el.value[al.language].cropData, []),
                            cropped: this._fb.control(el.value[al.language].cropped, []),
                            original: this._fb.control(el.value[al.language].original, []),
                            path: this._fb.control(el.value[al.language].path, []),
                            refresh: this._fb.control(el.value[al.language].refresh, []),
                            src: this._fb.control(el.value[al.language].src, []),
                            title: this._fb.control(el.value[al.language].title, []),
                        }));

                    }
                });
                this.formSocial.addControl(el.keyChain, tempForm);
            });
        });

        this.isAdd = !this.formConfig.get(this.selectedLang).get('url').value;
    }

    private checkCorrectSuggest(): Observable<void> {
        if ((!this.isHome && this.editedURl) || this.isAdd) {
            let suggestPageData: any = {};
            let urlToPost: string = '';

            if (this.configData.menu_id) {
                suggestPageData = {
                    id: this.configData.menu_id,
                    site_id: this.configData.site_id,
                    language: this.selectedLang,
                    name: this.formConfig.get(this.selectedLang).get('url').value,
                };

                urlToPost = 'menus/' + this.configData.menu_id + '/suggest-url';
            } else {
                suggestPageData = {
                    site_id: this.configData.site_id,
                    language: this.selectedLang,
                    name: this.formConfig.get(this.selectedLang).get('url').value,
                };

                urlToPost = 'menus/suggest-url';
            }

            if (this.isHome) {
                return of(null);
            } else {
                return this._api.post(urlToPost, suggestPageData).pipe(map(data => {
                    this.formConfig.get(this.selectedLang).get('url').setValue(data.url);
                    this.onUpdateconfig('url', this.formConfig.get(this.selectedLang).get('url'));
                }));
            }
        } else {
            return of(null);
        }
    }

}
