import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-panel-form-dropdown',
    templateUrl: './panel-form-dropdown.component.html',
})
export class PanelFormDropdownComponent implements OnInit{
    @Input() control: AbstractControl;
    @Input() disabledFields: boolean = false;
    @Input() element: any;
    @Input() label: string = null;
    @Output() updated: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit() {
        if (this.element.attributes.defaultSelected && !this.control.value) {
            this.control.setValue(this.element.attributes.defaultSelected);
        }
    }

    onUpdated(): void {
        if (this.control.valid) {
            this.updated.emit(this.control.value);
        }
    }
}
