import { Injectable } from '@angular/core';
import { AllDataModel } from 'app/core/models/allData.model';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SingeltonService {
    public lng: string;
    public agenda: any = null;
    public allData: AllDataModel = null;
    public canDelete: any = false;
    public configPanel: any = null;
    public doc: any = null;
    public enviroment: any = null;
    public idSite: string = null;
    public img: any = null;
    public panel: any = null;
    public route: any = null;
    public showIframe: boolean = true;
    public showModule: boolean = true;
    public video: any = null;
    private agendaSubject = new Subject<any>();
    private allDataSubject = new Subject<AllDataModel>();
    private canDeleteSubject = new Subject<any>();
    private configPanelSubject = new Subject<any>();
    private docSubject = new Subject<any>();
    private enviromentSubject = new Subject<any>();
    private idSiteSubject = new Subject<string>();
    private imgSubject = new Subject<any>();
    private panelSubject = new Subject<any>();
    private refreshIframeSubject = new Subject<void>();
    private routeSubject = new Subject<any>();
    private showIframeSubject = new Subject<boolean>();
    private showModuleSubject = new Subject<boolean>();
    private videoSubject = new Subject<any>();

    // AllData
    getAllDataObs(): Observable<AllDataModel> {
        return this.allDataSubject.asObservable();
    }

    setAllData(value: AllDataModel): void {
        this.allData = value;
        this.allDataSubject.next(value);
    }


    // IdSite
    getIdSiteObs(): Observable<string> {
        return this.idSiteSubject.asObservable();
    }

    setIdSite(value: string): void {
        this.idSite = value;
        this.idSiteSubject.next(value);
    }


    // Panel
    setPanel(data: any): void {
        this.panel = data;
        this.panelSubject.next(data);
    }

    getPanelObs(): Observable<any> {
        return this.panelSubject.asObservable();
    }


    // Img
    setImg(value: any): void {
        this.img = value;
        this.imgSubject.next(value);
    }

    getImgObs(): Observable<any> {
        return this.imgSubject.asObservable();
    }


    // Video
    setVideo(value: any): void {
        this.video = value;
        this.videoSubject.next(value);
    }

    getVideoObs(): Observable<any> {
        return this.videoSubject.asObservable();
    }


    // Doc
    setDoc(value: any): void {
        this.doc = value;
        this.docSubject.next(value);
    }

    getDocObs(): Observable<any> {
        return this.docSubject.asObservable();
    }


    // Agenda
    setAgenda(value: any): void {
        this.agenda = value;
        this.agendaSubject.next(value);
    }

    getAgendaObs(): Observable<any> {
        return this.agendaSubject.asObservable();
    }


    // Enviroment
    setEnviroment(value: any): void {
        this.enviroment = value;
        this.enviromentSubject.next(value);
    }

    getEnviromentObs(): Observable<any> {
        return this.enviromentSubject.asObservable();
    }


    // Show Preview
    setShowIframe(value: boolean): void {
        this.showIframe = value;
        this.showIframeSubject.next(value);
    }

    getShowIframeObs(): Observable<boolean> {
        return this.showIframeSubject.asObservable();
    }


    // Show Module
    setShowModule(value: boolean): void {
        this.showModule = value;
        this.showModuleSubject.next(value);
    }

    getShowModuleObs(): Observable<boolean> {
        return this.showModuleSubject.asObservable();
    }


    // Config Panel
    setConfigPanel(value: any): void {
        this.configPanel = value;
        this.configPanelSubject.next(value);
    }

    getConfigPanelObs(): Observable<any> {
        return this.configPanelSubject.asObservable();
    }


    // Route
    setRoute(value: any): void {
        this.route = value;
        this.routeSubject.next(value);
    }

    getRouteObs(): Observable<any> {
        return this.routeSubject.asObservable();
    }

    // Refresh Iframe
    setRefreshIframe(): void {
        this.refreshIframeSubject.next(null);
    }

    getRefreshIframeObs(): Observable<void> {
        return this.refreshIframeSubject.asObservable();
    }


    // Can delete Config panel
    setCanDelete(value: any): void {
        this.canDelete = value;
        this.canDeleteSubject.next(value);
    }

    resetAll(): void {
        this.setAgenda(null);
        this.setCanDelete(null);
        this.setConfigPanel(null);
        this.setDoc(null);
        this.setEnviroment(null);
        this.setImg(null);
        this.setPanel(null);
        this.setVideo(null);
    }


}
