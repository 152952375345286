import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-feed-linkedin',
    templateUrl: './feed-linkedin.component.html',
})
export class FeedLinkedinComponent implements OnInit {
    @Input('item') item: any;

    ngOnInit(): void {
    }
}
