import { ConfigPanelComponent } from 'app/core/components/right-panel/config-panel/config-panel.component';
import { PanelFormCategoriesComponent } from 'app/core/components/right-panel/panel-form/panel-form-categories/panel-form-categories.component';
import { PanelFormCheckboxComponent } from 'app/core/components/right-panel/panel-form/panel-form-checkbox/panel-form-checkbox.component';
import { PanelFormDateEventComponent } from 'app/core/components/right-panel/panel-form/panel-form-date-event/panel-form-date-event.component';
import { PanelFormDateComponent } from 'app/core/components/right-panel/panel-form/panel-form-date/panel-form-date.component';
import { PanelFormDocComponent } from 'app/core/components/right-panel/panel-form/panel-form-doc/panel-form-doc.component';
import { PanelFormDropdownComponent } from 'app/core/components/right-panel/panel-form/panel-form-dropdown/panel-form-dropdown.component';
import { PanelFormGroupComponent } from 'app/core/components/right-panel/panel-form/panel-form-group/panel-form-group.component';
import { PanelFormHeadingComponent } from './panel-form/panel-form-heading/panel-form-heading.component';
import { PanelFormImgComponent } from 'app/core/components/right-panel/panel-form/panel-form-img/panel-form-img.component';
import { PanelFormInputComponent } from 'app/core/components/right-panel/panel-form/panel-form-input/panel-form-input.component';
import { PanelFormLabelComponent } from 'app/core/components/right-panel/panel-form/panel-form-label/panel-form-label.component';
import { PanelFormLangBarComponent } from 'app/core/components/right-panel/panel-form/panel-form-lang-bar/panel-form-lang-bar.component';
import { PanelFormLinkComponent } from 'app/core/components/right-panel/panel-form/panel-form-link/panel-form-link.component';
import { PanelFormMapComponent } from 'app/core/components/right-panel/panel-form/panel-form-map/panel-form-map.component';
import { PanelFormModuleHighlightComponent } from 'app/core/components/right-panel/panel-form/panel-form-module-highlight/panel-form-module-highlight.component';
import { PanelFormSelectComponent } from 'app/core/components/right-panel/panel-form/panel-form-select/panel-form-select.component';
import { PanelFormTextareaComponent } from 'app/core/components/right-panel/panel-form/panel-form-textarea/panel-form-textarea.component';
import { PanelFormTinyComponent } from 'app/core/components/right-panel/panel-form/panel-form-tiny/panel-form-tiny.component';
import { PanelFormVideoComponent } from 'app/core/components/right-panel/panel-form/panel-form-video/panel-form-video.component';
import { PanelContentComponent } from 'app/core/components/right-panel/panel-types/panel-content/panel-content.component';
import { PanelEnvironmentListComponent } from 'app/core/components/right-panel/panel-types/panel-environment-list/panel-environment-list.component';
import { PanelListComponent } from 'app/core/components/right-panel/panel-types/panel-list/panel-list.component';
import { RightPanelComponent } from 'app/core/components/right-panel/right-panel.component';

export const RightPanelDirectives = [
    RightPanelComponent,

    // Panel Types
    PanelContentComponent,
    PanelEnvironmentListComponent,
    PanelListComponent,

    // Components
    ConfigPanelComponent,

    // Panel Form
    PanelFormCategoriesComponent,
    PanelFormCheckboxComponent,
    PanelFormDateComponent,
    PanelFormDateEventComponent,
    PanelFormDocComponent,
    PanelFormDropdownComponent,
    PanelFormGroupComponent,
    PanelFormHeadingComponent,
    PanelFormImgComponent,
    PanelFormInputComponent,
    PanelFormLabelComponent,
    PanelFormLangBarComponent,
    PanelFormLinkComponent,
    PanelFormMapComponent,
    PanelFormModuleHighlightComponent,
    PanelFormSelectComponent,
    PanelFormTextareaComponent,
    PanelFormTinyComponent,
    PanelFormVideoComponent,
];
