import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'app/core/services/session.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private _session: SessionService, private _router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');

        if (token) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${ token }` },
            });
        }

        return next.handle(request).pipe(tap(() => null, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this._session.clearSession();
                    this._router.navigate(['login']);
                }
            }
        }));
    }
}
