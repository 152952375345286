import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormOptionsModel } from 'app/core/models/formOptions.model';
import { CountriesData } from 'countries-data/data/countries';

@Component({
    selector: 'app-form-select-country',
    templateUrl: './form-select-country.component.html',
})
export class FormSelectCountryComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() formOptions: FormOptionsModel = {};
    @Input() item: any;
    @Output() updated: EventEmitter<any> = new EventEmitter<any>();
    public countriesList: { key: string, value: string }[] = [];

    constructor() {
    }

    ngOnInit(): void {
        this.buildCountriesList();
    }

    onBlur(): void {
        this.updated.emit(this.form.controls[this.item.key].value);
    }

    private buildCountriesList(): void {
        this.countriesList = [];
        const countriesData: any = CountriesData;
        const countries: any[] = [];

        Object.keys(countriesData)
            .map(key => {
                const country: any = countriesData[key];

                countries.push({
                    key: country.iso_3166_1_alpha3,
                    value: country.translations && country.translations.spa && country.translations.spa.common || country.name.common,
                });
            });

        this.countriesList = countries.sort((a, b) => a.value > b.value ? 1 : -1);
    }

}
