import { Injectable } from '@angular/core';
import { AllDataModel } from 'app/core/models/allData.model';
import { SiteModel } from 'app/core/models/site.model';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UtilsService {
    constructor(private _api: ApiService, private _session: SessionService) {
    }

    getSites(): Observable<SiteModel> {
        return this._api.get('sites');
    }

    getAlldata(siteId): Observable<AllDataModel> {
        return this._api.get(`sites/${ siteId }/all-data`).pipe(map((res: AllDataModel) => {
            res.languages.map(lng => {
                if (lng.default === 1 || lng.default === true) {
                    this._session.setLng(lng.language);
                }
            });
            return res;
        }));
    }

    downloadFile(data: Blob, name: string): void {
        const blob: Blob = new Blob([data], { type: data.type });
        const file: File = new File([blob], name, { type: data.type });
        FileSaver.saveAs(file);
    }

    parseDatepicker(datepicker: any): string {
        return datepicker
        // ? `${ datepicker.date.year }-${ ('0' + datepicker.date.month).slice(-2) }-${ ('0' + datepicker.date.day).slice(-2) }`
        ? `${ datepicker.date.year }-${ datepicker.date.month }-${ datepicker.date.day }`
            : '';
    }

    parseStringDatepicker(val: any): any {
        if (val) {
            const date: Date = new Date(val);
            return {
                date: {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate(),
                },
            };
        } else {
            return null;
        }
    }

    resetJson(obj: any): any {
        if (obj) {
            for (const key of Object.keys(obj)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    obj[key] = this.resetJson(obj[key]);
                } else {
                    obj[key] = '';
                }
            }
        }
        return obj;
    }
}
