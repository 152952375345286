import { Component } from '@angular/core';

@Component({
    selector: 'app-urls',
    templateUrl: './urls.component.html',
})
export class UrlsComponent {
    public showTable: boolean = true;

    onImport(): void {
        this.showTable = false;
        setTimeout(() => {
            this.showTable = true;
        }, 10);
    }
}
