import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PanelDataModel } from 'app/core/models/panelData.model';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-panel-list',
    templateUrl: './panel-list.component.html',
})
export class PanelListComponent implements OnInit, OnDestroy {
    @Input() data: any;
    @Input() showAdd: string = null;
    public list: any[] = [];
    public listMax: number = null;
    protected $subs: Subscription[] = [];

    constructor(
        private _session: SessionService,
        private _api: ApiService,
        private _dragula: DragulaService,
        private _st: SingeltonService,
    ) {
        this.$subs.push(this._st.getAgendaObs().subscribe(res =>
            res && res.hasOwnProperty('showST') ? '' : this.onSelect(res, true),
        ));
    }

    ngOnInit(): void {
        this._dragula.createGroup('img-list-content', { moves: (el, source, handle) => handle.classList.contains('c-icon--move') });
        this.$subs.push(this._dragula.drop().subscribe(value => this.onDragula(value)));

        if (this.data.data.attributes.hasOwnProperty('length')) {
            this.listMax = +this.data.data.attributes.length || null;
        }

        if (this.data.data.elements.length > 0) {
            this.buildList();
        }
    }

    ngOnDestroy(): void {
        this._dragula.destroy('img-list-content');
        this.$subs.map(sub => sub.unsubscribe());
    }

    onClose(): void {
        this._st.setPanel(null);
    }

    onSelect(data: PanelDataModel, especial: boolean = false): void {
        this._st.setPanel({
            data: data,
            url: this.data.url,
            ref: this.data.ref,
            page: this.data.page,
            list: this.data.data,
            especial: especial,
        });
    }

    onActive(event, index): void {
        this.data.data.elements[index].active = event;
        this._api.put(`pages/${ this.data.url.page_id }/content/${ this.data.data.keyChain }`, this.data.data)
            .subscribe(() => {
                this._st.setPanel(null);
                this._st.setRefreshIframe();
            });
    }

    onAdd(pos: number): void {
        switch (this.data.data.type) {
            case 'agendaListPanel':
                this._st.setAgenda({ showST: true });
                break;

            default:
                const finalTemplate = JSON.parse(JSON.stringify(this.data.data.sonsTemplates[pos]));
                finalTemplate.key = '';
                finalTemplate.keyChain = '';
                this.onSelect(finalTemplate);
                break;
        }
    }

    onAddByInput(): void {
        switch (this.showAdd) {
            case 'landings':
                this._st.setEnviroment({ landings: true, special: true });
                break;

            case 'points':
                this._st.setEnviroment({ points: true, special: true });
                break;

            case 'routes':
                this._st.setEnviroment({ routes: true, special: true });
                break;

            default:
                break;
        }
    }

    private onDragula(value): void {
        if (value.name === 'panel-list-drag') {
            const ret: PanelDataModel = { ...this.data.data };
            ret.elements = [];
            this.list.map(item => {
                const t: any[] = this.data.data.elements.filter(f => f.key === item.key);
                ret.elements.push(t[0]);
            });
            this._api.put(`pages/${ this.data.url.page_id }/content/${ this.data.data.keyChain }`, ret).subscribe(
                () => {
                    this._st.setPanel(null);
                    this._st.setRefreshIframe();
                },
            );
        }
    }

    private buildList(): void {
        const lng: string = this._st.lng;
        const defaultLng: string = this._session.getLng();

        const keyImg: string = this.data.data.attributes.hasOwnProperty('image')
            ? this.data.data.attributes.image
            : null;
        const keyTitle: string = this.data.data.attributes.hasOwnProperty('title')
            ? this.data.data.attributes.title
            : null;
        this.data.data.elements.map((cont, i) => {
            const model: any = {
                index: i,
                key: cont.key,
            };
            cont.elements.map(item => {
                if (keyTitle && keyTitle === item.key) {
                    switch (item.type) {
                        case 'image':
                            model.name = item.value[lng].alt || item.value[lng].title
                                || item.value[defaultLng].alt || item.value[defaultLng].title;
                            break;
                        case 'document':
                            model.name = item.value[lng].title || item.value[lng].path
                                || item.value[defaultLng].title || item.value[defaultLng].path;
                            break;
                        default:
                            model.name = typeof item.value[lng] === 'string' || item.value[lng] === null
                            || item.value[defaultLng] === 'string' || item.value[defaultLng] === null
                                ? item.value[lng] || item.value[defaultLng]
                                : item.value[lng].title || item.value[defaultLng].title;
                            break;
                    }
                }
                if (keyImg && keyImg === item.key) {
                    model.img = item.value[lng].src.thumb || item.value[defaultLng].src.thumb;
                }
            });
            this.list.push(model);
        });

        if (this.listMax && this.listMax <= this.data.data.elements.length && this.data.data.elements.length === 1) {
            this.onSelect(this.data.data.elements[0]);
        }
    }

}
