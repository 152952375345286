import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel } from 'app/core/models/allData.model';
import { FilterModel } from 'app/core/models/filter.model';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { DragulaService } from 'ng2-dragula';
import { Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-agenda-landings-list',
    templateUrl: 'agenda-landings-list.component.html',
})
export class AgendaLandingsListComponent implements OnDestroy {
    @Input() select: boolean = false;
    public activeElements: number = 0;
    public categories: any[] = [];
    public configObs$: Subscription = new Subscription();
    public defaultLang: string;
    public dragulaObservable;
    public filtered: boolean = false;
    public formFilters: FormGroup;
    public generalLang: any;
    public landingLang: any;
    public landings: any[] = [];
    public pagination: FilterModel;
    public siteDomain;
    public siteLangs;
    public urlPreview: any = null;
    public zones: any[] = [];

    constructor(
        private _api: ApiService,
        private _dragula: DragulaService,
        private _fb: FormBuilder,
        private _lng: TranslateService,
        private _modal: AlertPopupService,
        private _session: SessionService,
        private _st: SingeltonService,
        private _utils: UtilsService,
    ) {
        this._lng.get('general').subscribe(res => this.generalLang = res);
        this._lng.get('landing').subscribe(res => this.landingLang = res);

        this.formFilters = this._fb.group({
            name: [null],
            categories: [null],
            zones: [null],
        });

        this._utils.getAlldata(this._st.idSite).subscribe(data => {
            this.siteLangs = data.languages;
            this.siteDomain = data.domain;
            const a: LanguageModel[] = data.languages.filter(l => l.default);
            this.defaultLang = a[0].language;
        });

        this.getLandings();
        this.getCategoriesObs().pipe(mergeMap(() => this.getZonesObs())).subscribe();

        this.initDragula(['list']);
        this.dragulaObservable = this._dragula.dropModel('list').subscribe((value) => this.onDropModel(value));
    }

    ngOnDestroy(): void {
        this._dragula.destroy('list');
        this.dragulaObservable.unsubscribe();
        this.configObs$.unsubscribe();
    }

    getCategories(landing): string {
        if (landing.categories) {
            const categorization = landing.categories.filter(cat => cat.type === 'categories');
            const categories = categorization.map(zon => zon.label);
            return categories.join(', ');
        }
        return '';
    }

    onSearch(): void {
        this.pagination = null;
        this.getLandings();
    }

    onReset(): void {
        this.formFilters.reset();
        this.onSearch();
    }

    onNext(): void {
        this.pagination.current_page++;
        this.getLandings(true);
    }

    onCreate() {
        this._api.post('agenda/landings', { site_id: this._st.idSite }).subscribe(data => {
            this._st.setConfigPanel({ ...data, alternates: data.urls, persistData: true });
            this.configObs$ = this._st.getConfigPanelObs().subscribe(res => {
                data.urls = res.slice(2);
                this.configObs$.unsubscribe();
                this._st.setConfigPanel(null);
                this.onEdit(data);
            });
        });
    }

    onEdit(data) {
        const urlPreviewTemp = {};
        const lng = this._session.getLng();
        const filter = data.urls.filter(url => url.language === lng);
        if (filter.length) {
            data.urls.map(url => urlPreviewTemp[url.language] = url);
        }
        this._api.get('agenda/landings/' + data['id'] + '/highlight').subscribe(res => {
            this._st.setShowModule(false);
            this.urlPreview = urlPreviewTemp;
            this._st.setPanel({ data: res, src: 'agenda/landings', ref: data.id });
        });
    }

    onClickPreview(event: any): void {
        this._st.setPanel(event);
    }

    onBackPreview(): void {
        this._st.setShowModule(true);
        this.getLandings();
        this.urlPreview = null;
    }

    onSelect(landing) {
        console.log(landing);
        // this.fishhStateService.agendaModal.next({ action: 'select', type: 'landings', data: landing });
    }

    onBack(): void {
        // this.fishhStateService.agendaModal.next(false);
    }

    onActivate(landing, status) {
        if (status) {
            this.saveLandingStatus(landing, status);
            // this._api.get(`agenda/landings/${ landing.id }/highlight`)
            //     .subscribe(highlightData => {
            //         if (!this.checkLangsStatus(highlightData['languagesStatus'])) {
            //             this._modal.show({
            //                 content: this.landingLang.enable_landing.content,
            //                 buttonCancelType: 'ghost',
            //                 buttonCancelText: this.landingLang.enable_landing.buttonCancelText,
            //             }, false);
            //
            //             landing.page.active = !status;
            //         } else {
            //             this.saveLandingStatus(landing, status);
            //         }
            //     });
        } else {
            this.saveLandingStatus(landing, status);
        }
    }

    onRemove(landing): void {
        const fun = (landing, answer) => {
            if (answer) {
                this._api.delete(`agenda/landings/${ landing.id }`)
                    .subscribe(() => this.landings = this.landings.filter(e => e.id !== landing.id));
            }
        };

        this._modal.show(
            {
                title: this.landingLang.delete.title,
                content: this.landingLang.delete.content,
                buttonConfirmType: 'delete',
                buttonConfirmText: this.landingLang.delete.buttonConfirmText,
                buttonCancelType: 'ghost',
                buttonCancelText: this.landingLang.delete.buttonCancelText,
            },
            fun.bind(this, landing),
        );

    }

    showAlertPopup() {
        this._modal.show({
            content: this.landingLang.disabled_reorder.content,
            buttonCancelType: 'ghost',
            buttonCancelText: this.landingLang.disabled_reorder.buttonCancelText,
        }, false);
    }

    private getCategoriesObs(): Observable<any> {
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        return this._api.get('categories/agenda/AgendaLanding', { params: params }).pipe(
            map(data => {
                const categories = data.find((categorization) => categorization.name === 'categories');
                this.categories = categories ? categories.categories : [];
            }),
        );
    }

    private getZonesObs(): Observable<any> {
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        return this._api.get('zones', { params: params }).pipe(
            map(data => this.zones = data['data']),
        );
    }

    private getLandings(concat: boolean = false): void {
        const values: any = this.formFilters.getRawValue();
        let params = new HttpParams().append('filter_site_id', this._st.idSite);

        if (values.name) {
            params = params.append('filter_name', values.name);
        }
        if (values.categories) {
            params = params.append('filter_categories', values.categories);
        }
        if (values.zones) {
            params = params.append('filter_zones', values.zones);
        }
        if (concat) {
            params = params.append('page', this.pagination.current_page.toString());
        }

        this._api.get('agenda/landings', { params: params }).subscribe(data => {
            this.activeElements = 0;
            this.landings = concat ? this.landings.concat(data.data) : data.data;
            this.pagination = data;
            data.data.forEach(element => element.page.active ? this.activeElements + 1 : null);
        });
    }

    private saveLandingStatus(landing, status) {
        landing.page.active = status;
        this._api.put(`pages/${ landing.page.id }`, landing.page).subscribe();
    }

    private initDragula(list: string[]) {
        list.forEach(name => {
            if (this._dragula.find(name) !== undefined) {
                this._dragula.destroy(name);
            }

            this._dragula.createGroup(name, {
                moves: (el, source, handle): any => {
                    return this.filtered && handle.classList.contains('c-icon--move')
                        ? this.showAlertPopup()
                        : handle.classList.contains('c-icon--move');
                },
            });
        });
    }

    private onDropModel(value) {
        const positions = value.sourceModel.map((landing, index) => {
            return { id: landing.id, position: index };
        });

        const model = {
            site_id: this._st.idSite,
            positions: positions,
        };

        this._api.put('agenda/landings/reorder', model).subscribe(
            () => {
            },
            () => {
                this.landings = this.landings.sort(((a, b) => a['position'] - b['position']));
            },
        );

    }

    // private checkLangsStatus(languagesStatus) {
    //     let status = false;
    //
    //     Object.keys(languagesStatus).map(index => {
    //         if (languagesStatus[index].filled) {
    //             status = true;
    //         }
    //     });
    //
    //     return status;
    // }
}
