import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SingeltonService} from '../../../../core/services/singelton.service';

@Component({
    selector: 'app-social-box',
    templateUrl: './social-box.component.html',
})
export class SocialBoxComponent implements OnInit, OnChanges {
    @Input('type') type: string;
    @Input('account') account: any = null;
    @Input() data: any;

    @Input() form: FormGroup;
    // @Input() isEditable: boolean = true;

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() delete: EventEmitter<void> = new EventEmitter<void>();
    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();

    public accountData: any;

    constructor( private _fb: FormBuilder, private _st: SingeltonService) {
    }

    ngOnInit(): void {
        this.init();
    }

    init(): void {
        this.form = this._fb.group({
            name: ['', Validators.required],
            requires_approval: [false],
        });

        if (this.data !== null) {
            this.form.get('name').setValue(this.data.name);
            if (this.data.moderation_type) {
                this.form.get('requires_approval').setValue(this.data.moderation_type === 'white-list');
            }
        }
    }

    onUpdatedField() {
        this.accountData = {
            site_id: this._st.idSite,
            name: this.form.get('name').value,
            type: this.type,
            data: this.form.get('data').value,
            moderation_type: this.form.get('requires_approval').value ? 'white-list' : 'black-list'
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        const inputType: SimpleChange = changes.type;

        if (inputType && inputType.currentValue !== inputType.previousValue) {
            this.init();
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }

    onSave(): void {
        if ( this.form.get('data').valid && this.form.get('name').valid) {
            this.save.emit(this.accountData);
        }
    }

    onDelete(): void {
        this.delete.emit();
    }

}
