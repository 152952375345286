import { Injectable } from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {HttpParams} from '@angular/common/http';
import {SingeltonService} from '../../../core/services/singelton.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SocialAccountModel, SocialAccountType} from '../models/socialAccount.model';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class SocialApiService {

    private baseURL: string = 'social/';

    constructor( private _api: ApiService,
                 private _st: SingeltonService) {
    }

    getAccounts(refresh: boolean = false): Observable<any> {
        const params: HttpParams = new HttpParams()
            .append('filter_site_id', this._st.idSite);

        return this._api.get(this.baseURL + 'accounts', {params: params});
    }

    getAccount(socialAccountId: string, refresh: boolean = false): Observable<SocialAccountModel> {
        return this._api.get(`${ this.baseURL }accounts/${ socialAccountId }`);
    }

    createAccount(data: any): Observable<SocialAccountModel> {
        return this._api.post(this.baseURL + 'accounts', data);
    }

    updateAccount(socialAccountId: string, data: SocialAccountModel): Observable<SocialAccountModel> {
        return this._api.put(`${ this.baseURL }accounts/${ socialAccountId }`, data);
    }

    deleteAccount(socialAccountId: string): Observable<any> {
        return this._api.delete(`social/accounts/${ socialAccountId }`);
    }

    getAuthorizationUrl(siteId: string, accountType: SocialAccountType): Observable<any> {
        const params: HttpParams = new HttpParams()
            .append('site_id', siteId);
        return this._api.get(this.baseURL + accountType + '/authorization', { params: params });
    }

    checkAuthorizationAccountStatus(accountType: SocialAccountType, siteId: string, code: any): Observable<any> {
        return this._api.post(
            this.baseURL + accountType + '/authorization/status',
            {
                site_id: siteId,
                code: code
            });
    }

    showItemFeedAccount(socialAccountId: string, siteId: string, feedId: string): Observable<any> {
        return this._api.post(
            `${ this.baseURL }accounts/${ socialAccountId }/moderate/show`,
            {
                site_id: siteId,
                item_id: feedId,
            });
    }

    hideItemFeedAccount(socialAccountId: string, siteId: string, feedId: string): Observable<any> {
        return this._api.post(
            `${ this.baseURL }accounts/${ socialAccountId }/moderate/hide`,
            {
                site_id: siteId,
                item_id: feedId,
            }
        );
    }

    getAccountFeed(socialAccountId: string): Observable<any> {
        return this._api.get(`${ this.baseURL }accounts/${ socialAccountId }/feed`);
    }

    private sortItems(a, b) {
        if (a.type === 'facebook' && b.type === 'facebook') {
            return a.created_at > b.created_at;
        } else if (a.type === 'facebook' && b.type !== 'facebook') {
            return -1;
        } else if (a.type === 'twitter' && b.type === 'facebook') {
            return 1;
        } else if (a.type === 'twitter' && b.type === 'twitter') {
            return a.created_at > b.created_at;
        } else if (a.type === 'twitter' && b.type === 'instagram') {
            return -1;
        } else if (a.type === 'instagram' && b.type !== 'instagram') {
            return 1;
        } else if (a.type === 'instagram' && b.type === 'instagram') {
            return a.created_at > b.created_at;
        }
    }
}
