import { Injectable } from '@angular/core';
import {SingeltonService} from '../../../core/services/singelton.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SocialAccountModel} from '../models/socialAccount.model';
import {SocialApiService} from './socialApi.service';

@Injectable({ providedIn: 'root' })
export class SocialAccountsService {

    private _accountsSource = new BehaviorSubject<SocialAccountModel[]>([]);
    private accounts: SocialAccountModel[] = [];
    public accounts$ = this._accountsSource.asObservable();

    constructor( private _api: SocialApiService ) {
    }

    getAccounts(refresh: boolean = false): void {
        this._api.getAccounts(refresh).subscribe( accounts => {
            if (accounts.data.length > 0) {
                this.accounts = accounts.data;
                this._accountsSource.next(this.accounts);
            }
        });
    }
}
