import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { TextsDeclarations } from 'app/modules/tools/components/texts/text.declarations';
import { UrlsDeclarations } from 'app/modules/tools/components/urls/urls.declarations';
import { TooltipModule } from 'ngx-tooltip';
import { InfoComponent } from './components/info/info.component';
import { LanguageComponent } from './components/language/language.component';
import { LogComponent } from './components/log/log.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ToolsComponent } from './tools.component';
import { ToolsRouting } from './tools.routing';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(ToolsRouting),
        TooltipModule,
        TranslateModule,
    ],
    declarations: [
        InfoComponent,
        LanguageComponent,
        LogComponent,
        SettingsComponent,
        TextsDeclarations,
        ToolsComponent,
        UrlsDeclarations,
    ],
    exports: [
        RouterModule,
    ],
})
export class ToolsModule {
}
