import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
})
export class MediaComponent {

    constructor(private _route: ActivatedRoute, private _utils: UtilsService, private _st: SingeltonService) {
        this._st.resetAll();
        this._st.setIdSite(this._route.snapshot.paramMap.get('idsite'));
        this._utils.getAlldata(this._st.idSite).subscribe(res => this._st.setAllData(res));
    }

}
