import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-panel-form-input',
    templateUrl: './panel-form-input.component.html',
})
export class PanelFormInputComponent implements OnInit {
    @Input() br: boolean = true;
    @Input() control: AbstractControl;
    @Input() disabledFields: boolean = false;
    @Input() element: any;
    @Input() label: string = null;
    @Output() updated: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit(): void {
        this.changeValidation ( this.disabledFields || this.element.readOnly );
    }

    ngOnChanges(changes: SimpleChanges) {
        if( changes.disabledFields !== undefined ) {
            this.changeValidation( changes.disabledFields.currentValue === true );
        }
    }

    onUpdated(): void {
        if (this.control.valid) {
            this.updated.emit(this.control.value);
        }
    }

    private changeValidation(value) {
        if( !value ) {
            this.control.enable();
        } else {
            this.control.disable();
        }
    }
}
