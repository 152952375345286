import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../../core/services/api.service';
import {SingeltonService} from '../../../../../core/services/singelton.service';
import {Router} from '@angular/router';
import {SocialApiService} from '../../../services/socialApi.service';
import {SocialAccountType} from '../../../models/socialAccount.model';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-social-box-instagram',
    templateUrl: './social-box-instagram.component.html',
})
export class SocialBoxInstagramComponent implements OnInit, OnDestroy {

    @Input() form: FormGroup;
    @Input() data: any = null;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();

    protected $subs: Subscription[] = [];
    public redirectUrl: any = null;

    constructor(
        private _fb: FormBuilder,
        private _api: ApiService,
        private _st: SingeltonService,
        private _router: Router,
        private _social: SocialApiService
    ) { }

    ngOnInit(): void {

        this.form.addControl('data', this._fb.group({
            instagram_access_token: ['', Validators.required],
        }));

        if (this.data !== null) {
            this.form.get('data').get('instagram_access_token').setValue(this.data.data.instagram_access_token);
        } else {
            this._social.getAuthorizationUrl(this._st.idSite, SocialAccountType.INSTAGRAM)
                .subscribe(data => {
                    this.redirectUrl = encodeURI(data.redirect);
                });
        }
    }

    onUpdated(field: string, value: any, group: any = null): void {
        if (group) {
            this.form.get(group + '.' + field).setValue(value);
        } else {
            this.form.controls[field].setValue(value);
        }

        this.update.emit({name: this.form.controls.name.value});
    }

    ngOnDestroy() {
        this.$subs.forEach((subscription) => subscription.unsubscribe());
    }
}
