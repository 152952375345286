import {Component, OnDestroy} from '@angular/core';
import {SingeltonService} from '../../core/services/singelton.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../core/services/utils.service';
import {ApiService} from '../../core/services/api.service';
import {SocialAccountsService} from './services/socialAccounts.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {EditAccountComponent} from './pages/edit-account/edit-account.component';
import {AddAccountComponent} from './pages/add-account/add-account.component';

@Component({
    selector: 'app-social',
    templateUrl: './social.component.html',
})
export class SocialComponent implements OnDestroy {
    public items: any [] = [];
    public itemSelected: any = null;
    public socialList: any[] = [];

    protected $subs: Subscription[] = [];

    constructor(
        private _utils: UtilsService,
        private _st: SingeltonService,
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _social: SocialAccountsService) {

        this._st.resetAll();
        this._st.setIdSite(this._route.snapshot.paramMap.get('idsite'));

        this.$subs.push(this._utils.getAlldata(this._st.idSite).subscribe((siteData) => {
            const social = siteData.modules.find(item => item.key === 'social');
            this.socialList = social && social.active ? social['sub-modules'] : [];
            this.socialList = this.socialList.filter(item => item['active']);
        }));

        this.$subs.push(this._route.firstChild.params.subscribe( params => {
            if ( params['account'] !== undefined ) {
                this.itemSelected = params['account'];
            }
        }));

        this._social.getAccounts();
        this.$subs.push(this._social.accounts$.subscribe(accounts => {
            this.items = accounts;
            if (!this.itemSelected && this.items.length > 0) {
                this.onSelect(this.items[0].id);
            }
        }));
    }

    onActivate(componentReference) {
        if (componentReference instanceof EditAccountComponent) {
            componentReference.delete.subscribe((data) => {
                this.itemSelected = null;
                this._social.getAccounts();
            });
        }

        if (componentReference instanceof AddAccountComponent) {
            componentReference.create.subscribe((data) => {
                this.itemSelected = null;
                this._social.getAccounts();
            });
        }

        /* if (componentReference instanceof AuthorizationComponent) {
            this.itemSelected = null;
        } */
    }


    onSelect(socialAccountId: string): void {
        this.itemSelected = socialAccountId;
        this._router.navigate([this._st.idSite + '/social/', socialAccountId]);
    }

    onAddAccount(socialType) {
        this.itemSelected = null;
        this._router.navigate([this._st.idSite + '/social/new-account/', socialType]);
    }

    ngOnDestroy() {
        this.$subs.forEach((subscription) => subscription.unsubscribe());
    }
}
