import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {ApiService} from '../../../../core/services/api.service';
import {SingeltonService} from '../../../../core/services/singelton.service';

@Component({
    selector: 'app-authorization',
    templateUrl: './authorization.component.html',
    styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {
    public authorizationMessage = null;

    constructor(private _route: ActivatedRoute,
                private _st: SingeltonService,
                private _api: ApiService,
                private _router: Router) {

        this._route.queryParams.subscribe(data => {
            this._api.post('social/' + this._route.snapshot.paramMap.get('provider') + '/authorization/status', {
                site_id: data['state'],
                code: data['code']
            })
                .subscribe(response => {
                    if (response == null || response.success == false) {
                        // error message
                        this.authorizationMessage = 'ko';
                    } else {
                        this.authorizationMessage = 'ok';
                        this._router.navigate(
                            ['/' + response.data.site_id + '/social/' + response.data.id],
                        );
                    }
                });
        });
    }

    ngOnInit() {

    }
}
