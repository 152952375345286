import {
    Component,
    EventEmitter, OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../core/services/api.service';
import {SingeltonService} from '../../../../core/services/singelton.service';
import {SocialAccountService} from '../../services/socialAccount.service';
import {AlertPopupService} from '../../../../core/services/alert-popup.service';
import {TranslateService} from '@ngx-translate/core';
import {SocialAccountModel} from '../../models/socialAccount.model';
import {SocialApiService} from '../../services/socialApi.service';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-account',
    templateUrl: './edit-account.component.html',
    styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit, OnDestroy {
    public accountId: any = null;
    public configurationData: any = null;
    public showConfiguration: boolean = false;
    public feed: any = null;
    public lang: any;
    public data: any = null;

    protected $subs: Subscription[] = [];
    @Output() delete: EventEmitter<any> = new EventEmitter();

    constructor(private _route: ActivatedRoute,
                private _api: ApiService,
                private _st: SingeltonService,
                private _router: Router,
                private _social: SocialAccountService,
                private _apiSocial: SocialApiService,
                private _modal: AlertPopupService,
                private _lng: TranslateService) {

        this._lng.get('config').subscribe(res => this.lang = res);

        this.$subs.push(this._social.account$.subscribe(account => {
            if ( account && this.accountId !== null) {
                this.setData(this._social.getAccount(this.accountId));
            }
        }));
    }

    setData(socialAccountData) {
        if (socialAccountData !== null) {
            this.data = socialAccountData;
            this.setConfigurationData(this.data);

            if ( socialAccountData.feed !== null) {
                this.feed = socialAccountData.feed;
            }
        }
    }

    setConfigurationData(data) {
        this.configurationData = {
            data: data,
            type: data.type,
        };
    }

    ngOnInit() {
        this.$subs.push(this._route.params.subscribe( params => {
            this.accountId = params['account'];
            this.showConfiguration = false;
            this.setData(this._social.getAccount(this.accountId));
            this.getFeed();
        }));
    }

    onRefresh() {
        this.getFeed(true);
    }

    onBack() {
        this.showConfiguration = false;
    }

    onConfig(reverse: boolean = false): void {
        this.setConfigurationData(this.data);
        this.showConfiguration = !reverse;
    }

    onSave(data: SocialAccountModel): void {
        this._social.updateAccount(this.accountId, Object.assign(this.data, data));
    }

    onDelete(): void {
        const fun = res => {
            if (res) {
                this._apiSocial.deleteAccount(this.accountId).toPromise().then( response => {
                    this.delete.emit(this.accountId);
                });
            }
        };

        this._modal.show({
            title: this.lang.delete.title,
            content: this.lang.delete.content,
            buttonConfirmType: 'delete',
            buttonConfirmText: this.lang.delete.buttonConfirmText,
            buttonCancelType: 'ghost',
            buttonCancelText: this.lang.delete.buttonCancelText,
        }, fun.bind(this));
    }

    getFeed(refresh: boolean = false) {
        this.feed = this._social.getFeed(this.accountId, refresh);
    }

    ngOnDestroy() {
        this.$subs.forEach((subscription) => subscription.unsubscribe());
    }
}
