import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FilterModel } from 'app/core/models/filter.model';
import { ApiService } from 'app/core/services/api.service';

@Component({
    selector: 'app-log',
    templateUrl: './log.component.html',
})
export class LogComponent implements OnInit {
    public log: FilterModel = null;

    constructor(private _api: ApiService) {
    }

    ngOnInit(): void {
        this.getLogList(1);
    }

    showMoreItems(): void {
        if (this.log.to > 1 && this.log.current_page < this.log.last_page) {
            this.getLogList(this.log.current_page++, true);
        }
    }

    private getLogList(page: number, concat: boolean = false): void {
        let params = new HttpParams();

        if (page) {
            params = params.append('page', page.toString());
        }

        this._api.get('activity-log', { params: params })
            .subscribe(data => this.generateLogList(data, concat));

    }

    private generateLogList(data: FilterModel, concat: boolean): void {
        if (this.log === null) {
            this.log = data;
        }

        this.log.data = concat
            ? this.log.data.concat(data.data)
            : data.data;
    }
}
