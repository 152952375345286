import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {SocialAccountService} from '../../services/socialAccount.service';
import {SingeltonService} from '../../../../core/services/singelton.service';

@Component({
    selector: 'app-feed',
    templateUrl: './feed.component.html',
})
export class FeedComponent implements OnInit {
    @Input('accountId') accountId: string;
    @Input('feed') feed: any = [];
    @Input('account_type') account_type: any;
    @Output() config: EventEmitter<void> = new EventEmitter<void>();

    constructor(private _social: SocialAccountService,
                private _st: SingeltonService) {
    }

    ngOnInit(): void { }

    onShow(item, i): void {
        this._social.showItemFeedAccount(this.accountId, this._st.idSite, item.id, i);
    }

    onHide(item, i): void {
        this._social.hideItemFeedAccount(this.accountId, this._st.idSite, item.id, i);
    }
}
