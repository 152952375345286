import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SocialAccountModel} from '../../models/socialAccount.model';
import {SocialAccountService} from '../../services/socialAccount.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {SingeltonService} from '../../../../core/services/singelton.service';
import {SocialApiService} from '../../services/socialApi.service';

@Component({
    selector: 'app-account',
    templateUrl: './add-account.component.html',
    styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit, OnDestroy {
    public type: any = null;
    public boxData: any = null;
    protected $account: Subscription = null;

    @Output() create: EventEmitter<void> = new EventEmitter();

    constructor(
        private _route: ActivatedRoute,
        private _social: SocialAccountService,
        private _apiSocial: SocialApiService,
        private _router: Router,
        private _st: SingeltonService
    ) {
        this.$account = this._route.params.subscribe(params => {
            this.type = params['provider'];
            this.boxData = null;
        });
    }

    ngOnInit() {

    }

    onCancel(): void {
        this._router.navigate([this._st.idSite + '/social/']);
    }

    onSave(data: SocialAccountModel): void {
        this._apiSocial.createAccount(data).toPromise().then( response => {
            this.create.emit();
        });
    }

    ngOnDestroy() {
        this.$account.unsubscribe();
    }
}
