import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel } from 'app/core/models/allData.model';
import { FilterModel } from 'app/core/models/filter.model';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { IMyDpOptions } from 'mydatepicker';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-news-list',
    templateUrl: './news-list.component.html',
})
export class NewsListComponent implements OnDestroy {
    public activeElements: number = 0;
    public categories: any[] = [];
    public configObs$: Subscription = new Subscription();
    public defaultLang: string = '';
    public formFilters: FormGroup;
    public generalLang: any;
    public myDatePickerOptions: IMyDpOptions;
    public news: any[];
    public newsLang: any;
    public pagination: FilterModel;
    public urlPreview: any = null;
    private siteDomain;
    private siteLangs;

    constructor(
        private _api: ApiService,
        private _fb: FormBuilder,
        private _lng: TranslateService,
        private _modal: AlertPopupService,
        private _session: SessionService,
        private _st: SingeltonService,
        private _utils: UtilsService,
    ) {
        this._lng.get('general').subscribe(res => this.generalLang = res);
        this._lng.get('news').subscribe(res => this.newsLang = res);

        this.myDatePickerOptions = {
            dateFormat: 'dd/mm/yyyy',
            openSelectorOnInputClick: true,
            editableDateField: false,
            firstDayOfWeek: 'mo',
            showSelectorArrow: false,
            alignSelectorRight: true,
        };

        this.formFilters = this._fb.group({
            name: [''],
            date_from: [''],
            date_to: [''],
            categories: [null],
        });

        this._utils.getAlldata(this._st.idSite).subscribe(data => {
            this.siteLangs = data.languages;
            this.siteDomain = data.domain;
            const a: LanguageModel[] = data.languages.filter(l => l.default);
            this.defaultLang = a[0].language;
        });

        this.getNews();
        this.getCategorizationsObs().subscribe();
    }

    ngOnDestroy(): void {
        this.configObs$.unsubscribe();
    }

    getParsedDate(date) {
        if (date) {
            const dateObj = new Date(date);
            return `${ dateObj.getDate() }/${ (dateObj.getMonth() + 1) }/${ dateObj.getFullYear() }`;
        }

        return '';
    }

    getCategories(news) {
        if (news.categories) {
            const categories = news.categories.map((cat) => {
                return cat.label;
            });
            return categories.join(', ');
        }

        return '';
    }

    onSearch(): void {
        this.pagination = null;
        this.getNews();
    }

    onReset(): void {
        this.formFilters.reset();
        this.onSearch();
    }

    onNext(): void {
        this.pagination.current_page++;
        this.getNews();
    }

    onPrev(): void {
        this.pagination.current_page--;
        this.getNews();
    }

    onCreate() {
        this._api.post('news', { site_id: this._st.idSite }).subscribe(data => {
            this._st.setConfigPanel({ ...data, alternates: data.urls, persistData: true });
            this.configObs$ = this._st.getConfigPanelObs().subscribe(res => {
                data.urls = res.slice(2);
                this.configObs$.unsubscribe();
                this._st.setConfigPanel(null);
                this.onEdit(data);
            });
        });
    }

    onEdit(data) {
        const urlPreviewTemp = {};
        const lng = this._session.getLng();
        const filter = data.urls.filter(url => url.language === lng);
        if (filter.length) {
            data.urls.map(url => urlPreviewTemp[url.language] = url);
        }
        this._api.get('news/' + data['id'] + '/highlight').subscribe(res => {
            this._st.setShowModule(false);
            this.urlPreview = urlPreviewTemp;
            this._st.setPanel({ data: res, src: 'news', ref: data.id });
        });
    }

    onClickPreview(event: any): void {
        this._st.setPanel(event);
    }

    onBackPreview(): void {
        this._st.setShowModule(true);
        this.getNews();
        this.urlPreview = null;
    }

    onActivate(event, status) {
        if (status) {
            this.saveNewsStatus(event, status);
            // this._api.get(`news/${ event.id }/highlight`).subscribe(data => {
            //     if (!this.checkLangsStatus(data['languagesStatus'])) {
            //         this._modal.show({
            //             content: this.newsLang.enable_news.content,
            //             buttonCancelType: 'ghost',
            //             buttonCancelText: this.newsLang.enable_news.buttonCancelText,
            //         }, false);
            //
            //         event.page.active = !status;
            //     } else {
            //         this.saveNewsStatus(event);
            //     }
            // });
        } else {
            this.saveNewsStatus(event, status);
        }
    }

    onRemove(news) {
        const fun = answer => {
            if (answer) {
                this._api.delete(`news/${ news.id }`)
                    .subscribe(() => this.news = this.news.filter((e) => e.id !== news.id));
            }
        };

        this._modal.show({
            title: this.newsLang.delete.title,
            content: this.newsLang.delete.content,
            buttonConfirmType: 'delete',
            buttonConfirmText: this.newsLang.delete.buttonConfirmText,
            buttonCancelType: 'ghost',
            buttonCancelText: this.newsLang.delete.buttonCancelText,
        }, fun.bind(this, news));

    }

    private getNews(): void {
        const values: any = this.formFilters.getRawValue();
        let params = new HttpParams()
            .append('filter_site_id', this._st.idSite)
            .append('order_field', 'date')
            .append('order_direction', 'desc');

        if (values.name) {
            params = params.append('filter_name', values.name);
        }
        if (values.date_from) {
            params = params.append('filter_date_from', this._utils.parseDatepicker(values.date_from));
        }
        if (values.date_to) {
            params = params.append('filter_date_to', this._utils.parseDatepicker(values.date_to));
        }
        if (values.categories) {
            params = params.append('filter_categories', values.categories);
        }
        if (this.pagination && this.pagination.current_page) {
            params = params.append('page', this.pagination.current_page.toString());
        }

        this._api.get('news', { params: params }).subscribe((data) => {
            this.pagination = data;
            this.news = data.data;

            this.news.forEach(element => {
                if (element.page.active) {
                    this.activeElements = this.activeElements + 1;
                }
            });
        });
    }

    private getCategorizationsObs(): Observable<any> {
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        return this._api.get('categories/news/News', { params: params }).pipe(map(data => {
            const categories = data.find((categorization) => categorization.name === 'categories');
            this.categories = categories ? categories.categories : [];
        }));
    }

    private saveNewsStatus(news, status): void {
        news.page.active = status;
        this._api.put(`pages/${ news.page.id }`, news.page).subscribe();
    }

    // private checkLangsStatus(languagesStatus) {
    //     let status = false;
    //
    //     Object.keys(languagesStatus)
    //         .map(function (index) {
    //             if (languagesStatus[index].filled) {
    //                 status = true;
    //             }
    //         });
    //
    //     return status;
    // }
}
