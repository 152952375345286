import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-social-box-twitter',
    templateUrl: './social-box-twitter.component.html',
})
export class SocialBoxTwitterComponent implements OnInit {
    @Input() data: any = null;
    @Input() form: FormGroup;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _fb: FormBuilder
    ) {
    }

    ngOnInit(): void {

        this.form.addControl('data', this._fb.group({
            twitter_account_id: ['', Validators.required],
            twitter_consumer_key: ['', Validators.required],
            twitter_consumer_secret: ['', Validators.required],
            twitter_access_token: ['', Validators.required],
            twitter_access_token_secret: ['', Validators.required],
        }));

        if (this.data !== null) {
            this.form.get('data').get('twitter_account_id').setValue(this.data.data.twitter_account_id);
            this.form.get('data').get('twitter_consumer_key').setValue(this.data.data.twitter_consumer_key);
            this.form.get('data').get('twitter_consumer_secret').setValue(this.data.data.twitter_consumer_secret);
            this.form.get('data').get('twitter_access_token').setValue(this.data.data.twitter_access_token);
            this.form.get('data').get('twitter_access_token_secret').setValue(this.data.data.twitter_access_token_secret);
        }
    }

    onUpdated(field: string, value: any, group: any = null): void {
        if (group) {
            this.form.get(group + '.' + field).setValue(value);
        } else {
            this.form.controls[field].setValue(value);
        }

        this.update.emit({name: this.form.controls.name.value});
    }
}
