import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { AgendaModule } from 'app/modules/agenda/agenda.module';
import { EnvironmentModule } from 'app/modules/environment/environment.module';
import { MediaModule } from 'app/modules/media/media.module';
import { SidebarModule } from 'ng-sidebar';
import { DragulaModule } from 'ng2-dragula';
import { PreviewComponent } from './components/preview/preview.component';
import { PreviewContainerComponent } from './preview.component';
import { PreviewRouting } from './preview.routing';

@NgModule({
    imports: [
        AgendaModule,
        BrowserAnimationsModule,
        CommonModule,
        CoreModule,
        DragulaModule,
        MediaModule,
        FormsModule,
        RouterModule.forChild(PreviewRouting),
        SidebarModule,
        TranslateModule,
        EnvironmentModule,
    ],
    declarations: [
        PreviewComponent,
        PreviewContainerComponent,
    ],
    exports: [
        RouterModule,
    ],
})

export class PreviewModule {
}
