import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PanelDataModel } from 'app/core/models/panelData.model';

@Component({
    selector: 'app-panel-form-group',
    templateUrl: './panel-form-group.component.html',
})
export class PanelFormGroupComponent {
    @Input() control: FormGroup;
    @Input() disabledFields: boolean = false;
    @Input() element: PanelDataModel;
    @Input() lng: string;
    @Output() updated: EventEmitter<any> = new EventEmitter<any>();

    onUpdated(event: any, keyChain: string): void {
        this.updated.emit({ value: event, keyChain: keyChain });
    }

}
