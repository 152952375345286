import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { MediaFileDropComponent } from 'app/modules/media/components/media-file-drop/media-file-drop.component';
import { MediaTabsComponent } from 'app/modules/media/components/media-tabs/media-tabs.component';
import { MediaTagsComponent } from 'app/modules/media/components/media-tags/media-tags.component';
import { ImageCropperModule } from 'ng2-img-cropper';
import { PopoverModule } from 'ng2-popover';
import { FileDropModule } from 'ngx-file-drop';
import { MediaDocComponent } from './components/media-doc/media-doc.component';
import { MediaImgComponent } from './components/media-img/media-img.component';
import { MediaPanelComponent } from './components/media-panel/media-panel.component';
import { MediaVideoComponent } from './components/media-video/media-video.component';
import { MediaComponent } from './media.component';
import { MediaRouting } from './media.routing';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FileDropModule,
        FormsModule,
        ImageCropperModule,
        PopoverModule,
        ReactiveFormsModule,
        RouterModule.forChild(MediaRouting),
        TranslateModule,
    ],
    declarations: [
        MediaComponent,
        MediaDocComponent,
        MediaFileDropComponent,
        MediaImgComponent,
        MediaPanelComponent,
        MediaTabsComponent,
        MediaTagsComponent,
        MediaVideoComponent,
    ],
    exports: [
        MediaDocComponent,
        MediaImgComponent,
        MediaVideoComponent,
        RouterModule,
    ],
})
export class MediaModule {
}

