import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';
import { TabsDeclarations } from 'app/core/components/tabs/tabs.declarations';
import { MyDatePickerModule } from 'mydatepicker';
import { DragulaModule } from 'ng2-dragula';
import { DropdownModule } from 'ngx-dropdown';
import { NgxTinymceModule } from 'ngx-tinymce';
import { TooltipModule } from 'ngx-tooltip';
import { BuyComponent } from './components/buy/buy.component';
import { CategoryGroupComponent } from './components/category-group/category-group.component';
import { CoreFormDeclarations } from './components/form/form.declarations';
import { IframeComponent } from './components/iframe/iframe.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MenuDirectives } from './components/menu/menu.directives';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NoContentComponent } from './components/no-content/no-content.component';
import { PopupComponent } from './components/popup/popup.component';
import { RightPanelDirectives } from './components/right-panel/right-panel.directives';
import { CoreTableDeclarations } from './components/table/table.declarations';
import { UiDeclarations } from './components/ui/ui.declarations';
import { VideoUrlValidatorDirective } from './directives/video.directive';
import { GetAvailableText } from './pipes/getAvailableText.pipe';
import { MenuAddNamePipe } from './pipes/menu-add-name.pipe';
import { SanitizeUrlPipe } from './pipes/sanitizeUrl.pipe';
import { AutocompleteOffDirective } from './directives/autocomplete-off.directive';

@NgModule({
    imports: [
        AgmCoreModule,
        CommonModule,
        DragulaModule,
        DropdownModule,
        FormsModule,
        MyDatePickerModule,
        NgxTinymceModule.forRoot({ baseURL: './assets/tinymce/' }),
        ReactiveFormsModule,
        RouterModule,
        ToasterModule,
        TooltipModule,
        TranslateModule,
    ],
    declarations: [
        // Components
        BuyComponent,
        CategoryGroupComponent,
        IframeComponent,
        LoadingComponent,
        NavbarComponent,
        NoContentComponent,
        PopupComponent,

        // Group Components
        CoreFormDeclarations,
        CoreTableDeclarations,
        MenuDirectives,
        RightPanelDirectives,
        TabsDeclarations,
        UiDeclarations,

        // Directives
        VideoUrlValidatorDirective,
        AutocompleteOffDirective,

        // Pipes
        GetAvailableText,
        MenuAddNamePipe,
        SanitizeUrlPipe,
    ],
    exports: [
        // Components
        BuyComponent,
        CategoryGroupComponent,
        IframeComponent,
        LoadingComponent,
        NavbarComponent,
        NoContentComponent,
        PopupComponent,

        // Group Components
        CoreFormDeclarations,
        CoreTableDeclarations,
        MenuDirectives,
        RightPanelDirectives,
        TabsDeclarations,
        UiDeclarations,

        // Directives
        VideoUrlValidatorDirective,
        AutocompleteOffDirective,

        // Pipes
        GetAvailableText,
        MenuAddNamePipe,
        SanitizeUrlPipe,
    ],
})
export class CoreModule {
}
