import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-table-filters',
    templateUrl: './table-filters.component.html',
})
export class TableFiltersComponent {
    @Output() reset: EventEmitter<void> = new EventEmitter<void>();
    @Output() search: EventEmitter<void> = new EventEmitter<void>();
}
