import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-social-box-header',
    templateUrl: './social-box-header.component.html',
})
export class SocialBoxHeaderComponent {
    @Input('account_type') account_type: string;
    @Input('account') account: any = null;
    @Input('configPage') configPage: any;
    @Output() back: EventEmitter<void> = new EventEmitter<void>();
    @Output() refresh: EventEmitter<void> = new EventEmitter<void>();
    @Output() config: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    onRefresh(): void {
        this.refresh.emit();
    }

    onBack(): void {
        this.back.emit();
    }

    onConfig(): void {
        this.config.emit();
    }
}
