import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel } from 'app/core/models/allData.model';
import { FilterModel } from 'app/core/models/filter.model';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ApiService } from 'app/core/services/api.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { DragulaService } from 'ng2-dragula';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-services-list',
    templateUrl: './services-list.component.html',
})
export class ServicesListComponent implements OnInit, OnDestroy {
    @Input() select: boolean = false;
    public activeElements: number = 0;
    public configObs$: Subscription = new Subscription();
    public defaultLang: string;
    public dragulaObservable;
    public filtered: boolean = false;
    public formFilters: FormGroup;
    public generalLang: any;
    public modelList: any[] = [];
    public pagination: FilterModel;
    public services: any[] = [];
    public servicesLang: any;
    public hideFiltersTargetZones: boolean = true;
    public siteDomain;
    public siteLangs;
    public targetList: any[] = [];
    public urlPreview: any = null;

    constructor(
        private _api: ApiService,
        private _dragula: DragulaService,
        private _fb: FormBuilder,
        private _lng: TranslateService,
        private _modal: AlertPopupService,
        private _session: SessionService,
        private _st: SingeltonService,
        private _utils: UtilsService,
    ) {
        this._lng.get('general').subscribe(res => this.generalLang = res);
        this._lng.get('services').subscribe(res => this.servicesLang = res);

        this.formFilters = this._fb.group({
            name: [null],
            categories: [null],
            zones: [null],
        });

        this._utils.getAlldata(this._st.idSite).subscribe(data => {
            this.siteLangs = data.languages;
            this.siteDomain = data.domain;
            const a: LanguageModel[] = data.languages.filter(l => l.default);
            this.defaultLang = a[0].language;
        });

        this.getServices();
        this.getCategorizations();

        this.initDragula(['list']);
        this.dragulaObservable = this._dragula.dropModel('list').subscribe((value) => this.onDropModel(value));
    }

    ngOnInit(): void {
        try {
            const filter1 = this._st.allData.modules.filter(mod => mod.key === 'services');
            const filter2: any[] = filter1[0]['sub-modules'].filter(sub => sub.key === 'services-list');
            this.hideFiltersTargetZones = filter2[0].attributes && filter2[0].attributes['hide-filters'];
        } catch (e) {
            this.hideFiltersTargetZones = true;
        }
    }

    ngOnDestroy(): void {
        this._dragula.destroy('list');
        this.dragulaObservable.unsubscribe();
        this.configObs$.unsubscribe();
    }

    getCategories(service, type): string {
        if (service.categories) {
            const categorization = service.categories.filter((cat) => cat.categorization === type);
            const categories = categorization.map((cat) => cat.label);
            return categories.join(', ');
        }
        return '';
    }

    onSearch(): void {
        this.pagination = null;
        this.getServices();
    }

    onNext(): void {
        this.pagination.current_page++;
        this.getServices(true);
    }

    onReset(): void {
        this.formFilters.reset();
        this.onSearch();
    }

    onCreate() {
        this._api.post('services', { site_id: this._st.idSite }).subscribe(data => {
            this._st.setConfigPanel({ ...data, alternates: data.urls, persistData: true });
            this.configObs$ = this._st.getConfigPanelObs().subscribe(res => {
                data.urls = res.slice(2);
                this.configObs$.unsubscribe();
                this._st.setConfigPanel(null);
                this.onEdit(data);
            });
        });
    }

    onEdit(data) {
        const urlPreviewTemp = {};
        const lng = this._session.getLng();
        const filter = data.urls.filter(url => url.language === lng);
        if (filter.length) {
            data.urls.map(url => urlPreviewTemp[url.language] = url);
        }
        this._api.get('services/' + data['id'] + '/highlight').subscribe(res => {
            this._st.setShowModule(false);
            this.urlPreview = urlPreviewTemp;
            this._st.setPanel({ data: res, src: 'services', ref: data.id });
        });
    }

    onClickPreview(event: any): void {
        this._st.setPanel(event);
    }

    onBackPreview(): void {
        this._st.setShowModule(true);
        this.getServices();
        this.getCategorizations();
        this.urlPreview = null;
    }

    onSelect(point): void {
        console.log(point);
        // this.fishhStateService.servicesModal.next({ action: 'select', type: 'points', data: point });
    }

    onActivate(point, status): void {
        if (status) {
            this.saveServiceStatus(point, status);
            // this._api.get(`services/${ point.id }/highlight`).subscribe(data => {
            //     if (!this.checkLangsStatus(data['languagesStatus'])) {
            //         this._modal.show({
            //             content: this.servicesLang.enable_point.content,
            //             buttonCancelType: 'ghost',
            //             buttonCancelText: this.servicesLang.enable_point.buttonCancelText,
            //         }, false);
            //
            //         point.page.active = !status;
            //     } else {
            //         this.saveServiceStatus(point, status);
            //     }
            // });
        } else {
            this.saveServiceStatus(point, status);
        }
    }

    onRemove(point): void {
        const fun = answer => {
            if (answer) {
                this._api.delete('services/' + point.id)
                    .subscribe(() => this.services = this.services.filter((e) => e.id !== point.id));
            }
        };

        this._modal.show({
            title: this.servicesLang.delete.title,
            content: this.servicesLang.delete.content,
            buttonConfirmType: 'delete',
            buttonConfirmText: this.servicesLang.delete.buttonConfirmText,
            buttonCancelType: 'ghost',
            buttonCancelText: this.servicesLang.delete.buttonCancelText,
        }, fun.bind(this, point));

    }

    showAlertPopup(): void {
        this._modal.show({
            content: this.servicesLang.disabled_reorder.content,
            buttonCancelType: 'ghost',
            buttonCancelText: this.servicesLang.disabled_reorder.buttonCancelText,
        }, false);
    }

    private getServices(concat: boolean = false): void {
        const values: any = this.formFilters.getRawValue();
        let params = new HttpParams().append('filter_site_id', this._st.idSite);

        if (values.name) {
            params = params.append('filter_name', values.name);
        }
        if (values.categories) {
            params = params.append('filter_categories', values.categories);
        }
        if (values.zones) {
            params = params.append('filter_zones', values.zones);
        }
        if (concat) {
            params = params.append('page', this.pagination.current_page.toString());
        }

        this._api.get('services', { params: params }).subscribe(data => {
            this.services = concat ? this.services.concat(data.data) : data.data;
            this.activeElements = 0;
            data.data.forEach(element => element.page.active ? this.activeElements + 1 : null);
        });
    }

    private getCategorizations(): Observable<any> {
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        return this._api.get('categories/services/Service', { params: params }).pipe(
            map(data => {
                const targetList = data.find(categorization => categorization.name === 'target');
                this.targetList = targetList ? targetList.categories : [];

                const modelList = data.find(categorization => categorization.name === 'model');
                this.modelList = modelList ? modelList.categories : [];
            }),
        );
    }

    private saveServiceStatus(item, status): void {
        item.page.active = status;
        this._api.put(`pages/${ item.page.id }`, item.page).subscribe();
    }

    private initDragula(list: string[]): void {
        list.forEach(name => {
            if (this._dragula.find(name) !== undefined) {
                this._dragula.destroy(name);
            }

            this._dragula.createGroup(name, {
                moves: (el, source, handle): any => {
                    if (this.filtered && handle.classList.contains('c-icon--move')) {
                        this.showAlertPopup();
                    } else {
                        return handle.classList.contains('c-icon--move');
                    }
                },
            });
        });
    }

    private onDropModel(value): void {
        const positions = value.sourceModel.map((item, index) => {
            return { id: item.id, position: index };
        });
        const model = {
            site_id: this._st.idSite,
            positions: positions,
        };

        this._api.put('services/reorder', model)
            .subscribe((data) => {
                this.services = data.data;
                this.activeElements = 0;
                data.data.forEach(element => element.page.active ? this.activeElements + 1 : null);
            }, () => {
                this.services = this.services.sort(((a, b) => a['position'] - b['position']));
            });
    }
}
