import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { ServicesComponent } from './services.component';

export const ServicesRouting: Routes = [
    {
        path: ':idsite/services', component: ServicesComponent, canActivate: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'services-list', canActivate: [AuthGuard] },
            { path: 'services-list', component: ServicesListComponent, canActivate: [AuthGuard] },
        ],
    },
];
