import { TableAddButtonComponent } from 'app/core/components/table/table-add-button/table-add-button.component';
import { TableFiltersComponent } from 'app/core/components/table/table-filters/table-filters.component';
import { TableMoreButtonComponent } from 'app/core/components/table/table-more-button/table-more-button.component';
import { TableNoResultsComponent } from 'app/core/components/table/table-no-results/table-no-results.component';
import { TablePaginationComponent } from 'app/core/components/table/table-pagination/table-pagination.component';


export const CoreTableDeclarations = [
    TableAddButtonComponent,
    TableFiltersComponent,
    TableMoreButtonComponent,
    TableNoResultsComponent,
    TablePaginationComponent,
];
