import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertPopupService {
    public dataObservable;
    private callBackFunction;

    constructor() {
        this.dataObservable = new Subject<any>();
        this.dataObservable.asObservable();
    }

    show(data: Object, callBackFunction) {
        const dataToPopup = {
            'data': data,
            'action': 'show',
        };

        if (callBackFunction) {
            this.callBackFunction = callBackFunction;
        }

        this.dataObservable.next(dataToPopup);
    }

    hide() {
        const dataToPopup = { 'action': 'hide' };

        this.dataObservable.next(dataToPopup);
    }

    answer(data) {
        if (data) {
            this.callBackFunction(data);
        }
    }
}
