import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PanelDataModel } from 'app/core/models/panelData.model';
import { SingeltonService } from 'app/core/services/singelton.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-panel-form-video',
    templateUrl: './panel-form-video.component.html',
})
export class PanelFormVideoComponent implements OnDestroy {
    @Input() control: FormGroup;
    @Input() disabledFields: boolean = false;
    @Input() element: PanelDataModel;
    @Input() lng: string = null;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    protected $subs: Subscription[] = [];

    constructor(private _st: SingeltonService) {
        this.$subs.push(this._st.getVideoObs().subscribe(res => {
            if (res && !res.lng) {
                if (res.data.keyChain === this.element.keyChain) {
                    Object.keys(res.data.value[this.lng])
                        .map(key => this.update.emit({ value: res.data.value[this.lng][key], key: key }));
                    this._st.setShowIframe(true);
                    this._st.setVideo(null);
                }
            }
        }));
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }

    onUpdated(value: any, key: string): void {
        if (this.control.valid) {
            this.update.emit({ value: value, key: key });
        }
    }

    onEdit(): void {
        this._st.setShowIframe(false);
        this._st.setVideo({ element: this.element, lng: this.lng });
    }

    onRemove(): void {
        this.update.emit({ key: 'title', value: '' });
        this.update.emit({ key: 'alt', value: '' });
        this.update.emit({ key: 'source', value: '' });
        this.update.emit({ key: 'video_code', value: '' });
        this.update.emit({ key: 'url', value: '' });
        this.update.emit({ key: 'thumb', value: '' });
    }
}
