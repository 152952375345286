import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { MediaModel } from '../../models/media.model';
import { MediaService } from '../../services/media.service';

@Component({
    selector: 'app-media-video',
    templateUrl: './media-video.component.html',
})
export class MediaVideoComponent implements OnInit, OnDestroy {
    @Input() select: boolean = false;
    public activeTags: any[] = [];
    public dataVideo: any = null;
    public defaultLang: string;
    public showFilter: boolean = false;
    public siteSelected: string = '';
    public tagList: any = [];
    public tooltipData: any;
    public totalRes: any;
    public videoList: MediaModel[] = [];

    constructor(
        private _service: MediaService,
        private _session: SessionService,
        private _st: SingeltonService,
    ) {
    }

    ngOnInit(): void {
        this.showFilter = false;
        setTimeout(() => {
            this.showFilter = true;
        });
        this.resetTooltip();
        this.defaultLang = this._session.getLng();
        this._service.getSiteTags(this._st.idSite).subscribe(res => this.tagList = res);
        this.siteSelected = this._st.idSite;
        this.getFilteredVideos(this.siteSelected, this.activeTags, 1, false);
    }

    ngOnDestroy(): void {
        document.body.style.overflow = 'auto';
    }

    onFilter(filter: { params: HttpParams, concat: boolean }): void {
        this._service.getItems(this._st.idSite, filter.params)
            .subscribe(res => this.generateMediaList(res, filter.concat));
    }

    onClosePanel(reset: boolean): void {
        this.dataVideo = null;
        if (reset) {
            this.ngOnInit();
        }
    }

    onNew(source): void {
        this.dataVideo = {
            data: { thumb: '', name: [], description: [] },
            site_id: this.siteSelected,
            source: source,
            tags: [],
            type: 'video',
        };
    }

    onEdit(data): void {
        if (this.select) {
            this.selectVideo(data);
        } else {
            this.dataVideo = data;
        }
    }

    showTooltip(data): void {
        this.tooltipData = data;
    }

    resetTooltip(): void {
        this.tooltipData = {
            data: { name: '', source: '', thumb: '', video_code: '' },
            tags: [],
        };
    }

    selectVideo(video): void {
        const d: { element: any, lng: string } = this._st.video;
        d.element.value[d.lng].source = video.data['source'];
        d.element.value[d.lng].video_code = video.data['video_code'];
        d.element.value[d.lng].url = video.data['url'];
        d.element.value[d.lng].thumb = video.data['thumb'] || '';
        d.element.value[d.lng].title = video.data['name'][d.lng] || '';
        this._st.setVideo({ data: d.element, site: this.siteSelected });
    }

    backMedia(): void {
        this._st.setVideo(null);
        this._st.setShowIframe(true);
    }

    private getFilteredVideos(idSite, tags, page, concat): void {
        this._service.getFilteredItems(idSite, 'video', tags, page, false)
            .subscribe(data => this.generateMediaList(data, concat));
    }

    private generateMediaList(data, concat): void {
        this.totalRes = data;
        data.data.forEach(element => this._service.generateIframeVideoUrl(element));
        concat
            ? this.videoList = this.videoList.concat(data.data)
            : this.videoList = data.data;
    }
}
