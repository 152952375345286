import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PanelDataModel } from 'app/core/models/panelData.model';

@Component({
    selector: 'app-panel-form-tiny',
    templateUrl: './panel-form-tiny.component.html',
})
export class PanelFormTinyComponent implements OnInit, OnChanges {
    @Input() lng: string;
    @Input() control: AbstractControl;
    @Input() disabledFields: boolean = false;
    @Input() element: PanelDataModel;
    @Output() updated: EventEmitter<any> = new EventEmitter<any>();
    public model: any;
    public config: any;

    ngOnInit(): void {
        this.model = this.control.value;

        const tinyConf: string = 'bold italic underline strikethrough | bullist numlist | link copy paste pastetext | code indent outdent formats';
        const tinyPlugins: string[] = ['link', 'paste', 'lists', 'code'];
        // Full
        // 'bold italic underline strikethrough | bullist numlist | link copy paste pastetext | code indent outdent formats image insertfile';
        // ['link', 'paste', 'lists', 'code', 'image']
        this.config = {
            branding: false,
            force_br_newlines: false,
            force_p_newlines: true,
            forced_root_block: 'p',
            height: '300',
            menu: {},
            plugins: tinyPlugins,
            resize: true,
            // skin_url: 'assets/skins/lightgray',
            toolbar: tinyConf,
        };
    }

    ngOnChanges(changes: any): void {
        if (changes.hasOwnProperty('lng')) {
            this.model = this.control.value;
        }
    }

    onUpdated(event): void {
        this.control.setValue(event);
        this.updated.emit(this.control.value);
    }

}
