import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-table-add-button',
    templateUrl: './table-add-button.component.html',
})
export class TableAddButtonComponent {
    @Input('name') name: string;
    @Output() create: EventEmitter<void> = new EventEmitter<void>();
}
