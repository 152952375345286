import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageModel } from 'app/core/models/allData.model';
import { PanelDataAttributes } from 'app/core/models/panelData.model';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { CustomValidators } from 'app/core/validators/custom.validators';

@Injectable({ providedIn: 'root' })
export class RightPanelService {

    constructor(private _st: SingeltonService, private _fb: FormBuilder, private _session: SessionService) {
    }

    buildForm(data: any): FormGroup {
        const form: FormGroup = new FormGroup({});
        data.elements.map(el => {
            switch (el.type) {
                case 'categories':
                case 'date':
                case 'input':
                case 'moduleHighlight':
                case 'select':
                case 'textArea':
                case 'tiny':
                case 'checkbox':
                    form.addControl(el.keyChain, this.formGroupBasicLngs(el));
                    break;

                case 'link':
                    form.addControl(el.keyChain, this.formGroupLink(el));
                    break;

                case 'mapPoint':
                    form.addControl(el.keyChain, this.formGroupMap(el));
                    break;

                case 'image':
                    form.addControl(el.keyChain, this.formGroupImg(el));
                    break;

                case 'video':
                    form.addControl(el.keyChain, this.formGroupVideo(el));
                    break;

                case 'document':
                    form.addControl(el.keyChain, this.formGroupDoc(el));
                    break;

                case 'group':
                    form.addControl(el.keyChain, this.formGroupGroup(el));
                    break;

                case 'eventDate':
                    form.addControl(el.keyChain, this.formGroupEvenetDate(el));
                    break;

                case 'heading':
                    form.addControl(el.keyChain, this.formGroupHeading(el));
                    break;

                default:
                    break;
            }
        });

        return form;
    }

    private formGroupBasicLngs(el: any): FormGroup {
        const tempForm = new FormGroup({});
        this._st.allData.languages.map(lng => {
            tempForm.addControl(lng.language, this.formControl(el.value[lng.language], el, lng));
        });
        return tempForm;
    }

    private formGroupHeading(el: any): FormGroup {
        const tempForm = new FormGroup({});
        this._st.allData.languages.map(lng => {
            tempForm.addControl(lng.language, new FormGroup({
                title: this.formControl(el.value[lng.language].title, el, lng),
                tag: this.formControl(el.value[lng.language].tag, el, lng),
            }));
        });
        return tempForm;
    }

    private formGroupLink(el: any): FormGroup {
        const tempForm = new FormGroup({});
        this._st.allData.languages.map(lng => {
            tempForm.addControl(lng.language, new FormGroup({
                destination: this.formControl(el.value[lng.language].destination, el, lng),
                href: this.formControl(el.value[lng.language].href, el, lng, [CustomValidators.web]),
                target: this.formControl(el.value[lng.language].target, el, lng),
                title: this.formControl(el.value[lng.language].title, el, lng),
            }));
        });
        return tempForm;
    }

    private formGroupMap(el: any): FormGroup {
        const tempForm = new FormGroup({});
        this._st.allData.languages.map(lng => {
            tempForm.addControl(lng.language, new FormGroup({
                address: this._fb.control(el.value[lng.language].address, []),
                lat: this.formControl(el.value[lng.language].lat, el, lng),
                lng: this.formControl(el.value[lng.language].lng, el, lng),
            }));
        });
        return tempForm;
    }

    private formGroupImg(el: any): FormGroup {
        const tempForm = new FormGroup({});
        this._st.allData.languages.map(lng => {
            tempForm.addControl(lng.language, new FormGroup({
                alt: this._fb.control(el.value[lng.language].alt, []),
                cropData: this._fb.control(el.value[lng.language].cropData, []),
                cropped: this._fb.control(el.value[lng.language].cropped, []),
                image_crop: this._fb.control(el.value[lng.language].image_crop, []),
                original: this.formControl(el.value[lng.language].original, el, lng),
                path: this._fb.control(el.value[lng.language].path, []),
                refresh: this._fb.control(el.value[lng.language].refresh, []),
                src: this._fb.control(el.value[lng.language].src, []),
                title: this.formControl(el.value[lng.language].title, el, lng),
            }));
        });
        return tempForm;
    }

    private formGroupEvenetDate(el: any): FormGroup {
        const tempForm = new FormGroup({});
        this._st.allData.languages.map(lng => {
            tempForm.addControl(lng.language, new FormGroup({
                date_from: this._fb.control(el.value[lng.language].date_from, []),
                date_to: this._fb.control(el.value[lng.language].date_to, []),
                periodicity: this.formControl(el.value[lng.language].periodicity, el, lng),
                week_day: this._fb.control(el.value[lng.language].week_day, []),
            }));
        });
        return tempForm;
    }

    private formGroupVideo(el: any): FormGroup {
        const tempForm = new FormGroup({});
        this._st.allData.languages.map(lng => {
            tempForm.addControl(lng.language, new FormGroup({
                alt: this._fb.control(el.value[lng.language].alt, []),
                source: this._fb.control(el.value[lng.language].source, []),
                thumb: this._fb.control(el.value[lng.language].thumb, []),
                title: this.formControl(el.value[lng.language].title, el, lng),
                url: this.formControl(el.value[lng.language].url, el, lng),
                video_code: this._fb.control(el.value[lng.language].video_code, []),
            }));
        });
        return tempForm;
    }

    private formGroupDoc(el: any): FormGroup {
        const tempForm = new FormGroup({});
        this._st.allData.languages.map(lng => {
            tempForm.addControl(lng.language, new FormGroup({
                extension: this._fb.control(el.value[lng.language].extension, []),
                path: this._fb.control(el.value[lng.language].path, []),
                title: this.formControl(el.value[lng.language].title, el, lng),
                url: this.formControl(el.value[lng.language].url, el, lng),
            }));
        });
        return tempForm;
    }

    private formGroupGroup(el: any): FormGroup {
        const tempForm = new FormGroup({});

        el.elements.map(element => {
            const lngs = new FormGroup({});
            this._st.allData.languages.map(lng => lngs.addControl(lng.language, this.formControl(element.value[lng.language], element, lng)));
            tempForm.addControl(element.keyChain, lngs);
        });

        return tempForm;
    }

    private formControl(value: any, el: any, lng: LanguageModel, validators: any[] = []): FormControl {
        const attr: PanelDataAttributes = el.attributes;

        if (attr.required && !attr.hidden && this._session.getLng() === lng.language) {
            validators.push(Validators.required);
        }

        if (attr.maxLength) {
            validators.push(Validators.maxLength(+attr.maxLength));
        }

        if (attr.pattern) {
            validators.push(Validators.pattern(attr.pattern));
        }

        return this._fb.control(value, validators);
    }

}
