import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormOptionsModel } from 'app/core/models/formOptions.model';

@Component({
    selector: 'app-form-map',
    templateUrl: './form-map.component.html',
})
export class FormMapComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() formOptions: FormOptionsModel = {};
    @Input() item: any;
    @Output() updated: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
        if (!(this.item.value && this.item.value.lat && this.item.value.lng)) {
            this.onBlur(this.item.data.default_value);
        }
    }

    onBlur(event: any): void {
        this.form.controls[this.item.key].setValue(event.coords);
        this.updated.emit(this.form.controls[this.item.key].value);
    }
}
