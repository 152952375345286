import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { AgendaComponent } from 'app/modules/agenda/agenda.component';
import { MyDatePickerModule } from 'mydatepicker';
import { DragulaModule } from 'ng2-dragula';
import { MediaModule } from '../media/media.module';
import { AgendaRouting } from './agenda.routing';
import { AgendaLandingsListComponent } from './components/agenda-landings-list/agenda-landings-list.component';
import { AgendaListComponent } from './components/agenda-list/agenda-list.component';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        DragulaModule,
        FormsModule,
        ReactiveFormsModule,
        MediaModule,
        MyDatePickerModule,
        RouterModule.forChild(AgendaRouting),
        TranslateModule,
        TranslateModule.forChild(),
    ],
    declarations: [
        AgendaComponent,
        AgendaLandingsListComponent,
        AgendaListComponent,
    ],
    exports: [
        AgendaListComponent,
        RouterModule,
    ],
})
export class AgendaModule {
}
