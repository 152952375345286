import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';
import { SocialComponent } from './social.component';
import { AuthorizationComponent } from './pages/authorization/authorization.component';
import { HomeComponent } from './pages/home/home.component';
import { AddAccountComponent } from './pages/add-account/add-account.component';
import { EditAccountComponent } from './pages/edit-account/edit-account.component';

export const SocialRouting: Routes = [
    {
        path: ':idsite/social', component: SocialComponent, canActivate: [AuthGuard],
        children: [
            { path: '', component: HomeComponent, canActivate: [AuthGuard]},
            { path: ':account', component: EditAccountComponent, canActivate: [AuthGuard]},
            { path: 'new-account/:provider', component: AddAccountComponent, canActivate: [AuthGuard]},
        ]
    },
    {
        path: 'social/:provider/authorization', component: AuthorizationComponent, canActivate: [AuthGuard]
    }
];
