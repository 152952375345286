import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';

@Component({
    selector: 'app-texts',
    templateUrl: './texts.component.html',
})
export class TextsComponent implements OnInit {
    public langList: any[];

    constructor(
        private _api: ApiService,
        private _st: SingeltonService,
    ) {
    }

    ngOnInit() {
        this.getLanguagesList();
    }

    private getLanguagesList() {
        const params: HttpParams = new HttpParams()
            .append('filter_site_id', this._st.idSite);

        // Order langs by name asc
        this._api.get('site-languages', { params: params })
            .subscribe((data: any) => {
                this.langList = data['data'].sort((a, b) => (a.name > b.name)
                    ? 1
                    : ((b.name > a.name) ? -1 : 0),
                );

            });
    }
}
