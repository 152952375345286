import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuModel } from 'app/core/models/menu.model';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-menu-item-add',
    templateUrl: './menu-item-add.component.html',
})
export class MenuItemAdd implements OnInit, OnDestroy {
    @Input() item: MenuModel;

    constructor(private _api: ApiService) {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
    }
}
