import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PanelDataModel } from 'app/core/models/panelData.model';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';

@Component({
    selector: 'app-panel-form-categories',
    templateUrl: './panel-form-categories.component.html',
})
export class PanelFormCategoriesComponent implements OnInit {
    @Input() control: AbstractControl;
    @Input() element: PanelDataModel;
    @Input() disabledFields: boolean = false;
    @Output() updatedAll: EventEmitter<any> = new EventEmitter<any>();
    public data: any[] = [];
    public categories: any[] = [];

    constructor(private _api: ApiService, private _st: SingeltonService) {
    }

    ngOnInit(): void {
        const params = new HttpParams().append('filter_site_id', this._st.idSite);
        this._api.get(this.element.attributes.apiSource, { params: params })
            .subscribe(res => this.checkInitialCategories(res));
    }

    onUpdatedAll(value: any[]): void {
        this.updatedAll.emit(value);
    }

    public getSelectedCategories(categories: any[], categoriesSelected: any) {
        categories.map((category) => {
            if(category.active == true){
                categoriesSelected.push({
                    categorization: category.categorization,
                    name: category.name,
                    label: category.label
                });
            }

            if (category.children.length > 0) {
                categoriesSelected.concat(this.getSelectedCategories(category.children, categoriesSelected));
            }
        });

        return categoriesSelected;
    }

    updateCategories(event: boolean | any, mainModel: any, model: any): void {
        let ret: any[] = [];
        if (mainModel.multi_option) {
            model.active = (event === true);
            let categories = [];

            this.data.map((categorization) => {
                categories.concat(this.getSelectedCategories(categorization.categories, categories));
            });

            this.onUpdatedAll(categories);
        } else {
            // TODO
        }
    }

    private checkInitialCategories(res: any[]): void {
        let categoriesList: any[] = [];

        this.categories = this.control.value;

        res.map((item, index) => {
            item.categories = this.checkCategoriesActive(item.name, index, item.categories);
            categoriesList.push(item);
        });

        this.data = categoriesList;
    }

    private checkCategoriesActive(name: string, parentKey: any, categories: any[]): any[] {
        for (let i = 0; i < categories.length; i++) {
            categories[i].position = parentKey + '.' + i;
            if (categories[i].children.length > 0) {
                categories[i].children = this.checkCategoriesActive(name, categories[i].position, categories[i].children);
            }

            var element = categories[i];
            var categoryExists = this.categories.find((item) => {
               return element.name == item.name && element.categorization == item.categorization;
            });

            categories[i].active = (categoryExists !== undefined);
        }

        return categories;
    }
}
