import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-table-no-results',
    templateUrl: './table-no-results.component.html',
})
export class TableNoResultsComponent {
    @Input('title') title: string = 'general.no_result.title';
    @Input('content') content: string = 'general.no_result.content';
}
