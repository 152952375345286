import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/core/services/api.service';
import { CustomValidators } from 'app/core/validators/custom.validators';

@Component({
    selector: 'app-profile-password',
    templateUrl: './profile-password.component.html',
})
export class ProfilePasswordComponent implements OnInit {
    @Input() disable: boolean;
    public form: FormGroup;

    constructor(private _fb: FormBuilder, private _api: ApiService) {
    }

    ngOnInit(): void {
        this.form = this._fb.group({
            old: ['', Validators.required],
            pass: ['', Validators.required],
            repeat: ['', [Validators.required, CustomValidators.equal('pass')]],
        });
    }

    onSave(): void {
        if (this.form.valid) {

            const model: any = {
                old_password: this.form.get('old').value,
                new_password: this.form.get('pass').value,
            };

            this._api.put('users/update-password', model).subscribe();
        }
    }

}
