import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { SocialInputComponent } from './components/social-input/social-input.component';
import { SocialComponent } from './social.component';
import { SocialRouting } from './social.routing';
import { AuthorizationComponent } from './pages/authorization/authorization.component';
import { HomeComponent } from './pages/home/home.component';
import { AddAccountComponent } from './pages/add-account/add-account.component';
import { EditAccountComponent } from './pages/edit-account/edit-account.component';
import { SocialBoxHeaderComponent } from './components/social-box-header/social-box-header.component';
import {SocialBoxDeclarations} from './components/social-box/social-box.declarations';
import {FeedDeclarations} from './components/feed/feed.declarations';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(SocialRouting),
        TranslateModule,
    ],
    declarations: [
        SocialComponent,
        SocialBoxHeaderComponent,
        SocialBoxDeclarations,
        FeedDeclarations,
        SocialInputComponent,
        AuthorizationComponent,
        HomeComponent,
        AddAccountComponent,
        EditAccountComponent,
    ],
    exports: [
        RouterModule,
    ],
})
export class SocialModule {
}
