import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SocialAccountModel, SocialAccountType} from '../models/socialAccount.model';
import {SocialApiService} from './socialApi.service';

@Injectable({ providedIn: 'root' })
export class SocialAccountService {
    private _accountSource = new BehaviorSubject<SocialAccountModel>(null);
    private account: SocialAccountModel = null;
    public account$ = this._accountSource.asObservable();

    constructor( private _api: SocialApiService) {
    }

    getAccount(socialAccountId: string): SocialAccountModel {
        if (!this.account || this.account.id !== socialAccountId)  {
            this._api.getAccount(socialAccountId).subscribe(account => {
                this.account = account;
                this._accountSource.next(this.account);
            });
        }

        return this.account;
    }

    createAccount(data: any) {
        this._api.createAccount(data).subscribe(
            account => {
                this.account = account;
                this._accountSource.next(this.account);
            }
        );
    }

    updateAccount(socialAccountId: string, data: any) {
        this._api.updateAccount(socialAccountId, data).subscribe(
            account => {
                this.account = account;
                this._accountSource.next(this.account);
            }
        );
    }

    deleteAccount(socialAccountId: string) {
        this._api.deleteAccount(socialAccountId).subscribe(
            response => {
                this.account = null;
                this._accountSource.next(this.account);
            }
        );
    }

    checkAccountStatus(accountType: SocialAccountType, siteId: string, code: any): void {
        this._api.checkAuthorizationAccountStatus(accountType, siteId, code).subscribe( response => {
            if (response == null || response.success == false) {
                return false;
            }

            return true;
        });
    }

    showItemFeedAccount(socialAccountId: string, siteId: string, feedId: string, index): void {
        this._api.showItemFeedAccount(socialAccountId, siteId, feedId).subscribe( response => {
            this.account.feed[index].isBlack = false;
            const item = this.account.black_list.indexOf(feedId);
            this.account.black_list.splice(item, 1);
            this.account.white_list.push(feedId);
            this._accountSource.next(this.account);
        });
    }

    hideItemFeedAccount(socialAccountId: string, siteId: string, feedId: string, index: any) {
        this._api.hideItemFeedAccount(socialAccountId, siteId, feedId).subscribe( response => {
            this.account.feed[index].isBlack = true;

            const item = this.account.white_list.indexOf(feedId);
            this.account.white_list.splice(item, 1);
            this.account.black_list.push(feedId);
            this._accountSource.next(this.account);
        });
    }

    getFeed(socialAccountId: string, refresh: boolean = false) {
        if (!this.account || this.account.id !== socialAccountId || refresh)  {
            this._api.getAccountFeed(socialAccountId).subscribe(
                feed => {
                    if (feed && feed.data) {
                        this.account.feed = this.addWhiteAndBlackList(feed.data, this.account);
                        this._accountSource.next(this.account);
                    }
                });
        }

        return this.account;
    }

    private addWhiteAndBlackList(data, accountData): any {
        if (data) {
            data.forEach((element, index) => {
                let isBlack = (accountData && accountData.moderation_type !== undefined && accountData.moderation_type === 'white-list');

                if (accountData && accountData.black_list && accountData.black_list.length > 0) {
                    const isBlackList = accountData.black_list.find((item) => {
                        return item === element.id;
                    });

                    if (isBlackList) {
                        isBlack = true;
                    }
                }

                if (accountData && accountData.white_list && accountData.white_list.length > 0) {
                    const isWhiteList = accountData.white_list.find((item) => {
                        return item === element.id;

                    });

                    if (isWhiteList) {
                        isBlack = false;
                    }
                }

                data[index]['isBlack'] = isBlack;
            });
            return data;
        } else {
            return [];
        }
    }
}
