import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormOptionsModel } from 'app/core/models/formOptions.model';
import { ApiService } from 'app/core/services/api.service';
import { FormService } from 'app/core/services/form.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { SiteSettingsModel } from 'app/modules/tools/models/siteSettings.model';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {
    public data: SiteSettingsModel[];
    public form: FormGroup;
    public formOptions: FormOptionsModel = {};
    public response: SiteSettingsModel[];

    constructor(
        private _api: ApiService,
        private _fb: FormBuilder,
        private _form: FormService,
        private _lng: TranslateService,
        private _st: SingeltonService,
        private _toast: ToastService,
    ) {
    }

    ngOnInit(): void {
        this.formOptions = { labelPosition: 'left' };
        this.getData();
    }

    onCancel(): void {
        this.getData();
    }

    onSave(): void {
        if (this.form.valid) {
            const model: any = this._form.sanitizeValues(this.form);
            this._api.put(`sites/${ this._st.idSite }/settings`, model)
                .subscribe(
                    (res: SiteSettingsModel[]) => this.buildData(res, true),
                );
        }
    }

    private getData(): void {
        this._api.get(`sites/${ this._st.idSite }/settings`)
            .subscribe(
                (res: SiteSettingsModel[]) => this.buildData(res),
            );
    }

    private buildData(res: SiteSettingsModel[], alertSuccess: boolean = false): void {
        if (alertSuccess) {
            this._lng.get('message.vars')
                .subscribe(res => this._toast.showMessage(res));
        }
        this.response = res;
        this.data = res.filter(a => a.visible);
        const controls: any = this._form.setForm(this.data);
        this.form = this._fb.group(controls);
    }
}
