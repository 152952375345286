import { AgmCoreModule } from '@agm/core';
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { AppRouting } from 'app/app.routing';
import { CoreModule } from 'app/core/core.module';
import { AuthInterceptor } from 'app/core/services/auth.interceptor';
import { AgendaModule } from 'app/modules/agenda/agenda.module';
import { AuthModule } from 'app/modules/auth/auth.module';
import { EnvironmentModule } from 'app/modules/environment/environment.module';
import { LeadsModule } from 'app/modules/leads/leads.module';
import { MediaModule } from 'app/modules/media/media.module';
import { NewsModule } from 'app/modules/news/news.module';
import { PreviewModule } from 'app/modules/preview/preview.module';
import { ProfileModule } from 'app/modules/profile/profile.module';
import { ResetModule } from 'app/modules/reset/reset.module';
import { ServicesModule } from 'app/modules/services/services.module';
import { SocialModule } from 'app/modules/social/social.module';
import { ToolsModule } from 'app/modules/tools/tools.module';
import { environment } from 'environments/environment';
import { SidebarModule } from 'ng-sidebar';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { DropdownModule } from 'ngx-dropdown';
import { AppComponent } from './app.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,

        // Plugins
        DropdownModule,
        ToasterModule,
        DragulaModule,
        SidebarModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AgmCoreModule.forRoot({ apiKey: environment.apikey_gmap }),

        // Modules
        ResetModule,
        AuthModule,
        LeadsModule,
        MediaModule,
        ProfileModule,
        SocialModule,
        ToolsModule,
        AgendaModule,
        EnvironmentModule,
        NewsModule,
        PreviewModule,
        ServicesModule,
        CoreModule,

        AppRouting,
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ToasterService,
        DragulaService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
