import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FilterModel } from 'app/core/models/filter.model';
import { ApiService } from 'app/core/services/api.service';
import { LoadingService } from 'app/core/services/loading.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { LanguageModel, LanguageModelBERequest, LanguageTableModel } from 'app/modules/tools/models/language.model';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
})
export class LanguageComponent implements OnInit {
    public list: LanguageTableModel[] = [];
    public alternativeList: { value: string, name: string }[] = [];
    public showSave: boolean = false;
    private siteLng: FilterModel;
    private allLng: LanguageModel[] = [];

    constructor(private _api: ApiService, private _st: SingeltonService, private _loading: LoadingService) {
    }

    ngOnInit(): void {
        forkJoin(this.getSiteLanguageObs(), this.getAllLanguagesObs())
            .subscribe(() => this.onCancel());
    }

    onCancel(): void {
        this.list = this.buildList();
        this.buildAlternativeList();
    }

    onSave(): void {
        const changes: LanguageTableModel[] = this.buildChanges();
        changes.length === 0 ? this.showSave = false : this.parseToBE(changes);
    }

    buildAlternativeList(pos: number = null): void {
        this._loading.showLoading();
        setTimeout(() => {
            let defaultDisabled: boolean = false;
            let onlyone: number = 0;
            this.alternativeList = [];
            this.showSave = pos !== null;

            // Check if default is actually false
            if (pos !== null && this.list[pos].default) {
                this.list[pos].default = false;
                defaultDisabled = true;
            }

            // Build alternative list with cms active
            // Reset all webs with cms = false
            // Set first active cms if default was disabled now
            this.list.map((item, i) => {
                if (item.cms) {
                    onlyone++;
                    this.alternativeList.push({
                        value: item.iso2_code,
                        name: item.name,
                    });
                    if (item.web && defaultDisabled) {
                        this.list[i].default = true;
                        defaultDisabled = false;
                    }
                } else {
                    this.list[i].web = false;
                }
            });

            // If it's the only one set de default value for alternative list
            if (pos !== null && onlyone === 1) {
                this.list[pos].alternative = this.list[pos].iso2_code;
            }

            if (pos !== null && !this.list[pos].cms) {
                this.list[pos].web = false;
                this.list[pos].default = false;
            }


            this._loading.hideLoading();
        });
    }

    onWeb(pos: number): void {
        this.showSave = true;
        if (!this.list[pos].web) {
            if (this.list[pos].default) {
                for (let i = 0; i < this.list.length; i++) {
                    if (this.list[i].cms && this.list[i].web) {
                        this.list[i].default = true;
                        break;
                    }
                }
            }
            this.list[pos].default = false;
        }
    }

    setDefault(pos: number): void {
        this.showSave = true;
        this.list.map((item, i) => this.list[i].default = false);
        this.list[pos].default = true;
    }

    private buildList(): LanguageTableModel[] {
        const list: LanguageTableModel[] = [];

        this.allLng.forEach(language => {
            const siteLangInfo: LanguageModel = this.siteLng.data.find(lang => lang.iso2_code === language.iso2_code);

            if (siteLangInfo) {
                list.push({
                    id: siteLangInfo.id,
                    iso2_code: siteLangInfo.iso2_code,
                    name: language.name,
                    cms: true,
                    web: !!(siteLangInfo.active || +siteLangInfo.active === 1),
                    default: !!(siteLangInfo.default || +siteLangInfo.default === 1),
                    alternative: siteLangInfo.alternative_iso2_code,
                    original_alternative: language.alternative_iso2_code,
                });
            } else {
                list.push({
                    id: null,
                    iso2_code: language.iso2_code,
                    name: language.name,
                    cms: false,
                    web: false,
                    default: false,
                    alternative: language.alternative_iso2_code,
                    original_alternative: language.alternative_iso2_code,
                });
            }
        });

        return list;
    }

    private buildChanges(): LanguageTableModel[] {
        const actual: LanguageTableModel[] = this.list.slice();
        const ret: LanguageTableModel[] = [];

        this.buildList()
            .map((item, i) => {
                if (item.cms !== actual[i].cms) {
                    actual[i].type = actual[i].cms
                        ? 'create'
                        : 'delete';
                    ret.push(actual[i]);
                } else {
                    if (JSON.stringify(item) !== JSON.stringify(actual[i])) {
                        actual[i].type = 'update';
                        ret.push(actual[i]);
                    }
                }
            });

        return ret;

    }

    private parseToBE(list: LanguageTableModel[]) {
        const be: LanguageModelBERequest[] = [];
        list.map(item => {
            be.push({
                action: item.type,
                active: item.web,
                alternative_iso2_code: item.alternative,
                default: item.default,
                exists: item.cms,
                id: item.id,
                iso2_code: item.iso2_code,
                name: item.name,
                original_alternative: item.original_alternative,
                site_id: this._st.idSite,
            });
        });

        const model: any = { bunch: be, site_id: this._st.idSite };

        this._api.post('site-languages/bunch', model)
            .subscribe(() => this.ngOnInit());
    }

    private getSiteLanguageObs(): Observable<FilterModel> {
        const params: HttpParams = new HttpParams()
            .append('filter_site_id', this._st.idSite);

        return this._api.get('site-languages', { params: params }).pipe(
            map((res: FilterModel) => this.siteLng = res),
        );
    }

    private getAllLanguagesObs(): Observable<LanguageModel[]> {
        const params: HttpParams = new HttpParams()
            .append('order_field', 'name')
            .append('order_direction', 'asc');

        return this._api.get('languages', { params: params }).pipe(
            map((res: LanguageModel[]) => this.allLng = res),
        );
    }
}
