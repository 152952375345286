import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-panel-environment-list',
    templateUrl: './panel-environment-list.component.html',
})
export class PanelEnvironmentListComponent implements OnInit, OnDestroy {
    @Input() data: any;
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    public dataMain: any;
    public showContent: boolean = false;
    public showList: boolean = false;
    public listAddType: string = null;
    protected $subs: Subscription[] = [];

    constructor(
        private _api: ApiService,
        private _toast: ToastService,
        private _alert: AlertPopupService,
        private _lng: TranslateService,
        private _st: SingeltonService,
    ) {
        this.$subs.push(this._st.getEnviromentObs().subscribe(res => {
            this.close.emit(res === null);
            if (res && res.res) {
                this.onAdd(res.res, res.special);
                this._st.setEnviroment(null);
            }
        }));
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }

    ngOnInit(): void {
        if (this.data.data.type === 'environmentListPanel' && this.data.data.elements.length && this.data.data.elements.length === 1 && this.data.data.attributes.length === 1) {
            this.dataMain = { ...this.data };
            this.dataMain.list = { ...this.data.data };
            this.dataMain.data = this.data.data.elements[0];
            this.showContent = true;
            this.showList = false;
        } else if (this.data.data.type === 'environmentListPanel' && this.data.data.elements.length && this.data.data.elements.length > 0) {
            this.dataMain = { ...this.data };
            this.dataMain.list = { ...this.data.data };
            this.dataMain.data = { ...this.data.data };
            if (this.data.data.attributes.allowPoints) {
                this.listAddType = 'points';
            } else if (this.data.data.attributes.allowLandings) {
                this.listAddType = 'landings';
            } else if (this.data.data.attributes.allowRoutes) {
                this.listAddType = 'routes';
            }
            this.showContent = true;
            this.showList = true;
        } else {
            this.showContent = false;
            if (this.data.data.attributes.allowLandings) {
                setTimeout(() => {
                    this._st.setEnviroment({ landings: true });
                }, 10);
            } else if (this.data.data.attributes.allowPoints) {
                setTimeout(() => {
                    this._st.setEnviroment({ points: true });
                }, 10);
            } else if (this.data.data.attributes.allowRoutes) {
                setTimeout(() => {
                    this._st.setEnviroment({ routes: true });
                }, 10);
            }
        }

    }

    onAdd(item: any, special: boolean): void {
        const model: any = JSON.parse(JSON.stringify(this.data.data));
        model.elements = special ? this.data.data.elements.slice() : [];
        model.elements.push(item);
        this._api.put(`pages/${ this.data.page }/content/${ this.data.ref }`, model).subscribe(() => {
            this._toast.showMessage(special ? 'message.highlight' : 'message.remove', true);
            this._st.setRefreshIframe();
        });
    }

    onDelete(): void {
        const fun: any = resp => {
            if (resp) {
                const model: any = JSON.parse(JSON.stringify(this.data.data));
                model.elements = [];
                this._api.put(`pages/${ this.data.page }/content/${ this.data.ref }`, model).subscribe(() => {
                    this._toast.showMessage('message.remove', true);
                    this._st.setRefreshIframe();
                    this._st.setPanel(null);
                });
            }
        };

        this._lng.get('panels').subscribe(r => this._alert.show({
            title: r.delete_highlight,
            content: r.delete_highlight_text,
            buttonConfirmType: 'delete',
            buttonConfirmText: r.delete_highlight_confirm,
            buttonCancelType: 'ghost',
            buttonCancelText: r.delete_highlight_cancel,
        }, fun.bind(this)));
    }

}
