import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { MyDatePickerModule } from 'mydatepicker';
import { LeadsItemsComponent } from './components/leads-items/leads-items.component';
import { LeadsListComponent } from './components/leads-list/leads-list.component';
import { LeadsComponent } from './leads.component';
import { LeadsRouting } from './leads.routing';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(LeadsRouting),
        FormsModule,
        MyDatePickerModule,
        TranslateModule,
        CoreModule,
    ],
    declarations: [
        LeadsComponent,
        LeadsListComponent,
        LeadsItemsComponent,
    ],
    exports: [
        RouterModule,
    ],
})
export class LeadsModule {
}
