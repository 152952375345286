import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../../core/services/api.service';
import {SessionService} from '../../../../../core/services/session.service';

@Component({
    selector: 'app-social-box-linkedin',
    templateUrl: './social-box-linkedin.component.html',
})
export class SocialBoxLinkedinComponent implements OnInit {
    @Input() data: any = null;
    @Input() form: FormGroup;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    public lang: any;
    public api: string;
    public current_url: string;

    constructor(
        private _api: ApiService,
        private _fb: FormBuilder,
        private _session: SessionService,
    ) {
        this.api = this._session.getApi();
        this.current_url = window.location.href;
    }

    ngOnInit(): void {

        this.form.addControl('data', this._fb.group({
            linkedin_client_id: ['', Validators.required],
            linkedin_client_secret: ['', Validators.required],
            linkedin_company_id: ['', Validators.required],
        }));

        if (this.data !== null) {
            this.form.get('data').get('linkedin_client_id').setValue(this.data.linkedin_client_id);
            this.form.get('data').get('linkedin_client_secret').setValue(this.data.linkedin_client_secret);
            this.form.get('data').get('linkedin_company_id').setValue(this.data.linkedin_company_id);
            this.form.get('data').get('instagram_access_token').setValue(this.data.data.instagram_access_token);
        }
    }

    onUpdated(field: string, value: any, group: any = null): void {
        if (group) {
            this.form.get(group + '.' + field).setValue(value);
        } else {
            this.form.controls[field].setValue(value);
        }

        this.update.emit({name: this.form.controls.name.value});
    }
}
