import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[validateVideoUrl][ngModel],[validateVideoUrl][formControl]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => VideoUrlValidatorDirective), multi: true },
    ],
})
export class VideoUrlValidatorDirective implements Validator {

    constructor() {
    }

    validate(c: AbstractControl): { [key: string]: any } {

        const v = c.value;

        const VIDEO_REGEXP = [
            /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i,
            /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g,
        ];
        let valid = null;
        let found = false;
        if (v !== null && v !== '') {
            VIDEO_REGEXP.forEach(element => {
                const match = v.match(element);
                if (match && !found) {
                    valid = null;
                    found = true;
                }
            });
            if (!found) {
                valid = { 'videoUrl': 'Video URL format is incorrect' };
            }
        }

        return valid;
    }
}
