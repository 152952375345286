import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterModel } from 'app/core/models/filter.model';
import { ApiService } from 'app/core/services/api.service';
import { DateService } from 'app/core/services/date.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LeadsService {

    constructor(private _api: ApiService, private _date: DateService) {
    }

    getHotels(idSite: string): Observable<any> {
        const params: HttpParams = new HttpParams().append('filter_site_id', idSite);
        return this._api.get('hotels', { params: params });
    }

    getFilteredItems(idSite: string, type: string, data: FilterModel, dateFrom: string = '', dateTo: string = ''): Observable<FilterModel> {
        let params: HttpParams = new HttpParams()
            .append('filter_site_id', idSite)
            .append('filter_type', type)
            .append('page', '' + (data && data.current_page || 1));

        if (dateFrom !== '') {
            params = params.append('filter_date_from', this._date.parseDatepicker(dateFrom));
        }

        if (dateTo !== '') {
            params = params.append('filter_date_to', this._date.parseDatepicker(dateTo));
        }

        return this._api.get('leads', { params: params });
    }

    deleteLeadById(id: string): Observable<any> {
        return this._api.delete('leads/' + id, { responseType: 'text' });
    }

    deleteLeadMultiple(idSite: string, elements: any): Observable<any> {
        const elementsFormat: any = JSON.parse(JSON.stringify(elements));

        elementsFormat.forEach((element, index) => elementsFormat[index]['action'] = 'delete');

        const body = { site_id: idSite, bunch: elementsFormat };

        return this._api.post('leads/delete-bunch', body);
    }

    deleteFilteredItems(idSite: string, type: string, dateFrom: string = '', dateTo: string = ''): Observable<any> {
        let params: HttpParams = new HttpParams()
            .append('filter_site_id', idSite)
            .append('filter_type', type);

        if (dateFrom !== '') {
            params = params.append('filter_date_from', dateFrom);
        }

        if (dateTo !== '') {
            params = params.append('filter_date_to', dateTo);
        }

        return this._api.delete('leads/delete-filtered', { params: params, responseType: 'text' });
    }

    exportBunch(idSite, elements): Observable<any> {
        elements.map(element => delete element.content.checked);

        const model: any = { site_id: idSite, bunch: elements };

        const httpParams: any = {
            responseType: 'blob',
        };

        return this._api.post('leads/export-bunch', model, httpParams);
    }

    exportFilteredItems(idSite: string, type: string, dateFrom: string = '', dateTo: string = ''): Observable<any> {
        let params: HttpParams = new HttpParams()
            .append('filter_site_id', idSite)
            .append('filter_type', type);

        if (dateFrom !== '') {
            params = params.append('filter_date_from', this._date.parseDatepicker(dateFrom));
        }

        if (dateTo !== '') {
            params = params.append('filter_date_to', this._date.parseDatepicker(dateTo));
        }

        return this._api.get('leads/export-filtered', { params: params, responseType: 'blob' });
    }

}
