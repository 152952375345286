import { FormAutoComponent } from 'app/core/components/form/form-auto/form-auto.component';
import { FormInputComponent } from 'app/core/components/form/form-input/form-input.component';
import { FormMapComponent } from 'app/core/components/form/form-map/form-map.component';
import { FormSelectCountryComponent } from 'app/core/components/form/form-select-country/form-select-country.component';
import { FormSelectComponent } from 'app/core/components/form/form-select/form-select.component';

export const CoreFormDeclarations = [
    FormAutoComponent,
    FormInputComponent,
    FormMapComponent,
    FormSelectComponent,
    FormSelectCountryComponent,
];
