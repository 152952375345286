import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ResetComponent } from 'app/modules/reset/reset.component';
import { ResetRouting } from 'app/modules/reset/reset.routing';

@NgModule({
    imports: [
        RouterModule,
        RouterModule.forChild(ResetRouting),
    ],
    declarations: [
        ResetComponent,
    ],
    exports: [
        RouterModule,
    ],
})
export class ResetModule {
}
