import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormOptionsModel } from 'app/core/models/formOptions.model';
import { FormService } from 'app/core/services/form.service';

@Component({
    selector: 'app-category-group',
    templateUrl: './category-group.component.html',
})
export class CategoryGroupComponent implements OnInit {
    @Input() data: any[] = [];
    @Input() form: FormGroup;
    @Input() formOptions: FormOptionsModel = {};
    @Output() formChanges: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    public items: any[][] = [];

    constructor(private _form: FormService) {
    }

    ngOnInit(): void {
        this.items = this._form.setCategories(this.data);
    }

    onUpdated(key: any, value: any): void {
        this.form.controls[key].setValue(value);
        this.formChanges.emit(this.form);
    }

}
