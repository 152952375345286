import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-c-float-bar',
    templateUrl: './c-float-bar.component.html',
})
export class CFloatBarComponent {
    @Input() disabledSave: boolean = false;
    @Input() showCancel: boolean = true;
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() save: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    onSave(): void {
        if (!this.disabledSave) {
            this.save.emit();
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }

}
