import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core/core.module';
import { DragulaModule } from 'ng2-dragula';
import { MediaModule } from '../media/media.module';
import { EnviromentListComponent } from './components/enviroment-list/enviroment-list.component';
import { LandingsListComponent } from './components/landings-list/landings-list.component';
import { PointsListComponent } from './components/points-list/points-list.component';
import { RoutesListComponent } from './components/routes-list/routes-list.component';
import { EnvironmentComponent } from './environment.component';
import { EnvironmentRouting } from './environment.routing';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        DragulaModule,
        FormsModule,
        MediaModule,
        ReactiveFormsModule,
        RouterModule.forChild(EnvironmentRouting),
        TranslateModule,
    ],
    declarations: [
        EnvironmentComponent,
        LandingsListComponent,
        PointsListComponent,
        RoutesListComponent,
        EnviromentListComponent,
    ],
    exports: [
        LandingsListComponent,
        PointsListComponent,
        RoutesListComponent,
        RouterModule,
    ],
})
export class EnvironmentModule {
}
