import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-routes-list',
    templateUrl: './routes-list.component.html',
})
export class RoutesListComponent {
    @Input() select: boolean = false;
    public lang: any;
    public dataCallback: any;

    constructor(private _lng: TranslateService) {
        this._lng.get('route').subscribe(res => this.lang = res);

        this.dataCallback = {
            template: 'environment-route-view-template',
            moduleType: 'routes',
            modulePage: 'environment/routes',
            sourceModule: 'environmentRoute',
        };
    }
}
