import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';
import { AgendaComponent } from './agenda.component';
import { AgendaLandingsListComponent } from './components/agenda-landings-list/agenda-landings-list.component';
import { AgendaListComponent } from './components/agenda-list/agenda-list.component';

export const AgendaRouting: Routes = [
    {
        path: ':idsite/agenda', component: AgendaComponent, canActivate: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'agenda-list', canActivate: [AuthGuard] },
            { path: 'agenda-list', component: AgendaListComponent, canActivate: [AuthGuard] },
            { path: 'landing-list', component: AgendaLandingsListComponent, canActivate: [AuthGuard] },
        ],
    },
];
