import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginDataModel, Site } from 'app/core/models/loginData.model';
import { ApiService } from 'app/core/services/api.service';
import { AuthService } from 'app/core/services/auth.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { SelectSiteModel } from 'app/modules/auth/models/selectSite.model';

@Component({
    selector: 'app-select-site',
    templateUrl: './select-site.component.html',
})
export class SelectSiteComponent implements OnInit {
    public sites: Site[] = [];
    public form: FormGroup;

    constructor(
        private _api: ApiService,
        private _auth: AuthService,
        private _fb: FormBuilder,
        private _router: Router,
        private _session: SessionService,
        private _st: SingeltonService,
        private _toast: ToastService,
    ) {
        this.form = this._fb.group({
            site: ['', Validators.required],
            defaultSite: [false],
        });
    }

    ngOnInit(): void {
        this._st.resetAll();
        const login: LoginDataModel = this._session.getLogin();

        login === null
            ? this._auth.logout()
            : this.sites = login.sites;

        this.checkDefault(this.sites);
    }

    onSubmit(): void {
        const values: SelectSiteModel = this.form.getRawValue();
        values.defaultSite
            ? this.setDefaultSite(values.site)
            : this.goToSite(values.site);
    }


    private goToSite(siteID: string): void {
        this._st.setIdSite(siteID);
        this._router.navigate([siteID + '/preview']);
    }

    private checkDefault(sites: Site[]): void {
        for (let i = 0; i < sites.length; i++) {
            if (sites[i].default) {
                this.goToSite(sites[i].id);
                break;
            }
        }

        if (this.sites.length === 1) {
            this.goToSite(sites[0].id);
        }
    }

    private setDefaultSite(siteID: string): void {
        this._api.put('users/default-site', { site_id: siteID })
            .subscribe(
                () => this.goToSite(siteID),
                err => this._toast.showError(err),
            );
    }

}
