import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PanelDataModel } from 'app/core/models/panelData.model';
import { LoadingService } from 'app/core/services/loading.service';
import { SessionService } from 'app/core/services/session.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-iframe',
    templateUrl: './iframe.component.html',
})
export class IframeComponent implements OnInit, OnDestroy {
    @ViewChild('iframeWrapper') iframeWrapper: ElementRef;
    @Input() showBack: boolean = false;
    @Input() route: any = null;
    @Output() back: EventEmitter<void> = new EventEmitter<void>();
    @Output() data: EventEmitter<any> = new EventEmitter<any>();
    public eventData: any = null;
    public lang: string = '';
    public url: string = null;
    public showAll: boolean = true;
    protected $subs: Subscription[] = [];
    private readonly defaultLng: string = null;

    constructor(private _st: SingeltonService, private _session: SessionService, private _loading: LoadingService) {
        this.$subs.push(this._st.getShowIframeObs().subscribe(res => this.showAll = res));
        this.$subs.push(this._st.getRefreshIframeObs().subscribe(() => this.refresh()));
        this.$subs.push(this._st.getRouteObs().subscribe(res => {
            this.route = res;
            this.buildUrl(null);
        }));

        this.defaultLng = this._session.getLng();
        window.addEventListener('message', (event: MessageEvent) => this.onClick(event), false);
    }

    ngOnInit(): void {
        this.buildUrl(null);
        window.scrollTo(0, 0);
        this.onResize();
    }

    ngOnDestroy(): void {
        this.$subs.map(sub => sub.unsubscribe());
    }

    onResize(): void {
        this.iframeWrapper.nativeElement.style.width = document.body.clientWidth + 'px';
        this.iframeWrapper.nativeElement.style.height = window.innerHeight - 90 + 'px';
        this._loading.hideLoading();
    }

    onClick(event: MessageEvent): void {
        this.eventData = null;
        if (event.origin === this._st.allData.domain) {
            this.eventData = event.data.data.url;
            if (event.data.action === 'open-panel') {
                const data: any = this.getRefData(event);
                this.data.emit({
                    data: data,
                    url: event.data.data.url,
                    ref: event.data.ref,
                    page: event.data.data.page.id,
                });
            }
        }
    }

    buildUrl(lng: string): void {
        if (lng === null) {
            lng = this.route !== null
                ? `${ this.defaultLng }/${ this.route[this.defaultLng].url }`
                : this.defaultLng;
        }
        this.url = `${ this._st.allData.domain }/cms/${ lng }` + '?' + new Date().getTime();
    }

    refresh(): void {
        this._loading.showLoading();
        this.url += '?' + new Date().getTime();
    }

    private getRefData(event: MessageEvent): PanelDataModel[] {
        let content: PanelDataModel[] = event.data.data.content;
        event.data.ref.split('.').map((ref, i) => content = this.getRefDataRecursive(ref, content, i));

        return content;
    }

    private getRefDataRecursive(ref: string, data: PanelDataModel[] | any, i: number): PanelDataModel[] {
        if (i === 0) {
            for (let a = 0; a < data.length; a++) {
                if (data[a].key === ref) {
                    return data[a];
                }
            }
        } else {
            for (let a = 0; a < data.elements.length; a++) {
                if (data.elements[a].key === ref) {
                    return data.elements[a];
                }
            }
        }
        return data;
    }
}
