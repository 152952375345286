import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'menuAddName' })
export class MenuAddNamePipe implements PipeTransform {

    transform(value: any): any {
        switch (value.sonsPageTemplate) {
            case 'offer-view-template':
                return 'menu.offer';

            case 'room-view-template':
                return 'menu.room';

            case 'venue-view-template':
                return 'menu.saloon';

            case 'hotel-view-template':
                return 'menu.hotel';

            case 'event-view-template':
                return 'menu.event';

            case 'restaurant-view-template':
                return 'menu.restaurant';

            default:
                return 'menu.page';
        }
    }

}
