import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup} from '@angular/forms';

@Component({
    selector: 'app-panel-form-heading',
    templateUrl: './panel-form-heading.component.html',
})
export class PanelFormHeadingComponent implements OnInit {
    @Input() control: FormGroup;
    @Input() disabledFields: boolean = false;
    @Input() element: any;
    @Input() label: string = null;
    @Output() updated: EventEmitter<any> = new EventEmitter<any>();
    @Output() updatedAll: EventEmitter<any> = new EventEmitter<any>();
    public modelTag: string = null;

    constructor() { }

    ngOnInit(): void {
        this.setData(this.control);
    }

    setData(control): void {
        this.modelTag = control.controls['tag'].value;
    }

    onUpdated(key: string): void {
        if (this.control.controls[key].valid) {
            this.updated.emit({ value: this.control.controls[key].value, key: key });
        }
    }

    onChangeModel(value: any, key: string): void {
        this.modelTag = value;

        this.control.controls[key].setValue(value);
        this.updatedAll.emit({ key: key, value: value });
    }
}
