import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { SelectSiteComponent } from './components/select-site/select-site.component';

export const AuthRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'select-site', component: SelectSiteComponent, canActivate: [AuthGuard] },
    { path: '', pathMatch: 'full', redirectTo: 'select-site' },
    // { path: '**', redirectTo: 'select-site', pathMatch: 'full' },
];
