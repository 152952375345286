import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-c-sidebar',
    templateUrl: './c-sidebar.component.html',
})
export class CSidebarComponent implements OnInit {
    @Input() navList: any[];
    @Input() prefixItem: string = 'key';
    @Input() prefixName: string;
    @Output() selected: EventEmitter<any> = new EventEmitter<any>();
    public active: number = 0;

    constructor() {
    }

    ngOnInit(): void {
    }

    onClick(nav: any, pos: number): void {
        this.active = pos;
        this.selected.emit(nav);
    }

}
