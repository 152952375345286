import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from 'app/core/services/api.service';
import { AuthService } from 'app/core/services/auth.service';
import { SessionService } from 'app/core/services/session.service';
import { LoginModel } from 'app/modules/auth/models/login.model';

const helper = new JwtHelperService();

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    public form: FormGroup;
    public login: boolean = false;
    public submit: boolean = false;

    constructor(
        private _api: ApiService,
        private _auth: AuthService,
        private _fb: FormBuilder,
        private _router: Router,
        private _session: SessionService,
    ) {
        this.form = this._fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.checkApi();
        helper.isTokenExpired(this._session.getToken()) ? this.notLogged() : this._router.navigate(['select-site']);
    }

    onSubmit(): void {
        this._session.setAlertOn();
        this.submit = true;
        if (this.form.valid) {
            this.login = true;
            const model: LoginModel = this.form.getRawValue();
            this._api.post('users/login', model, null, true).subscribe(res => {
                this.login = false;
                this._auth.login(res);
            });
        }
    }

    private notLogged(): void {
        this._auth.logout(false);
    }

    private checkApi(): void {
        const api: string = this._session.getApi();
        if (!api) {
            this._router.navigate(['']);
        }
    }

}
