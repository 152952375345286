import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PanelDataModel } from 'app/core/models/panelData.model';

@Component({
    selector: 'app-panel-form-checkbox',
    templateUrl: './panel-form-checkbox.component.html',
})
export class PanelFormCheckboxComponent {
    @Input() control: AbstractControl;
    @Input() disabledFields: boolean = false;
    @Input() element: any;
    @Input() label: string = null;
    @Output() updated: EventEmitter<string> = new EventEmitter<string>();

    onUpdated(): void {
        if (this.control.valid) {
            this.updated.emit(this.control.value);
        }
    }
}
