import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { UtilsService } from 'app/core/services/utils.service';

@Component({
    selector: 'app-urls-import-export',
    templateUrl: './urls-import-export.component.html',
})
export class UrlsImportExportComponent {
    @Output() imported: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _api: ApiService,
        private _st: SingeltonService,
        private _toast: ToastService,
        private _utils: UtilsService,
    ) {
    }

    onImport(e): void {
        const file = e.target.files[0];

        const formData: FormData = new FormData();
        formData.append('file', file);

        this._api.post('uploads', formData).subscribe(data => {
            this.uploadedFile(data, e);
        });
    }

    onExport(): void {
        const httpParams: any = {
            params: new HttpParams().append('filter_site_id', this._st.idSite),
            responseType: 'blob',
        };

        this._api.get('redirects/export', httpParams).subscribe(data => {
                this._utils.downloadFile(data, 'redirections.xlsx');
                this._toast.showMessage('message.export', true);
            },
        );
    }

    private uploadedFile(fileData, file) {
        file.target.value = '';

        const model: any = {
            site_id: this._st.idSite,
            upload_id: fileData.id,
        };

        this._api.post('redirects/import', model).subscribe(
            (data) => {
                if ( data ) {
                    this._toast.showMessage('message.import', true);
                    this.imported.emit();
                } else {
                    this._toast.showMessage('error.import', true, 'error');
                }
            }
        );
    }


}
