import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterModel } from 'app/core/models/filter.model';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ToastService } from 'app/core/services/toast.service';
import { UtilsService } from 'app/core/services/utils.service';
import { IMyDpOptions } from 'mydatepicker';
import { mergeMap } from 'rxjs/operators';
import { LeadsService } from '../../services/leads.service';

@Component({
    selector: 'app-leads-list',
    templateUrl: './leads-list.component.html',
})
export class LeadsListComponent implements OnInit {
    @Input('type') type: string;
    public checkedAll: boolean = false;
    public dateFrom: any = null;
    public dateTo: any = null;
    public globalData: FilterModel = null;
    public idSite: string = null;
    public leadItem: any = null;
    public leadLang: any = null;
    public myDatePickerOptions: IMyDpOptions;
    public noElements: boolean = null;

    constructor(
        private _alert: AlertPopupService,
        private _lng: TranslateService,
        private _route: ActivatedRoute,
        private _service: LeadsService,
        private _toast: ToastService,
        private _utils: UtilsService,
    ) {
        this.idSite = this._route.snapshot.params['idsite'] || null;
        this.myDatePickerOptions = {
            // other options...
            dateFormat: 'dd/mm/yyyy',
            openSelectorOnInputClick: true,
            editableDateField: false,
            firstDayOfWeek: 'mo',
            showSelectorArrow: false,
        };

        this._lng.get('leads').subscribe((res) => this.leadLang = res);
    }

    ngOnInit(): void {
        this.getItems();
    }

    onFilter(): void {
        this.globalData.current_page = 1;
        this.getItems();
    }

    onNextPage(): void {
        this.globalData.current_page++;
        this.getItems();
    }

    onPreviousPage(): void {
        this.globalData.current_page--;
        this.getItems();
    }

    onExport(): void {
        const selectedList: any[] = this.getSelectedList();

        if (selectedList.length > 0) {
            this._service.exportBunch(this.idSite, selectedList).subscribe((data) => {
                this._utils.downloadFile(data, `leads.xlsx`);
                this._toast.showMessage('message.lead_export', true);
            });
        } else {
            this._service.exportFilteredItems(this.idSite, this.type, this.dateFrom, this.dateTo).subscribe((data) => {
                this._utils.downloadFile(data, `leads.xlsx`);
                this._toast.showMessage('message.lead_export', true);
            });
        }
    }

    toggleCheck(index): void {
        this.globalData.data[index].content.checked = !this.globalData.data[index].content.checked;
    }

    checkAll(): void {
        this.checkedAll = !this.checkedAll;
        this.globalData.data.forEach((element, index) => this.globalData.data[index].content.checked = this.checkedAll);
    }

    onSelectLead(lead): void {
        this.leadItem = lead;
    }

    onDeleteAll(): void {
        const selectedList = this.getSelectedList();
        const message: string = selectedList.length > 0
            ? this.leadLang.delete_all.content_selection + this.type + '?'
            : this.leadLang.delete_all.content_no_selection + this.type + '?';

        this._alert.show(
            {
                title: this.leadLang.delete_all.title,
                content: message,
                buttonConfirmType: 'delete',
                buttonConfirmText: this.leadLang.delete_all.buttonConfirmText,
                buttonCancelType: 'ghost',
                buttonCancelText: this.leadLang.delete_all.buttonCancelText,
            },
            this.deleteAllFunction.bind(this),
        );
    }

    onDeleteLead(index): void {
        this._alert.show(
            {
                title: this.leadLang.delete.title,
                content: this.leadLang.delete.content,
                buttonConfirmType: 'delete',
                buttonConfirmText: this.leadLang.delete.buttonConfirmText,
                buttonCancelType: 'ghost',
                buttonCancelText: this.leadLang.delete.buttonCancelText,
            },
            this.deleteLead.bind(this, index),
        );
    }

    onDeleteLeadById(id: string): void {
        this.globalData.data.forEach((element, index) => {
            if (element.id === id) {
                this.deleteLead(index, true);
                this.leadItem = null;
                return true;
            }
        });
    }

    private getItems(): void {
        this._service.getFilteredItems(this.idSite, this.type, this.globalData, this.dateFrom, this.dateTo)
            .subscribe((data: FilterModel) => {
                this.noElements = data.data && data.data.length === 0 && this.noElements === null;

                data.data.forEach((element, index) => {
                    if (element && element['date']) {
                        data.data[index].content['date_reservation'] = new Date(element.content.date);
                    }
                    data.data[index].content['date'] = new Date(element.created_at);
                    data.data[index].content['checked'] = false;
                });

                this.globalData = data;
            });
    }

    private deleteLead(index, answer): void {
        if (answer) {
            this._service.deleteLeadById(this.globalData.data[index].id)
                .subscribe(() => {
                    this.globalData.data.splice(index, 1);
                    this._lng.get('message.lead_remove')
                        .subscribe((res) => this._toast.showMessage(res));
                });
        }
    }

    private deleteAllFunction(answer): void {
        if (answer) {
            const selectedList: any[] = [];
            this.globalData.data.map(element => {
                if (element.content.checked) {
                    selectedList.push(element);
                }
            });
            if (selectedList.length > 0) {
                this._service.deleteLeadMultiple(this.idSite, selectedList)
                    .pipe(mergeMap(() => this._lng.get('message.lead_remove')))
                    .subscribe(res => this.afterDeleteAll(res));
            } else {
                this._service.deleteFilteredItems(this.idSite, this.type, this.dateFrom, this.dateTo)
                    .pipe(mergeMap(() => this._lng.get('message.lead_remove')))
                    .subscribe(res => this.afterDeleteAll(res));
            }
        }
    }

    private getSelectedList(): any[] {
        const selectedList: any[] = [];
        this.globalData.data.map(item => {
            if (item.content.checked) {
                selectedList.push(item);
            }
        });
        return selectedList;
    }

    private afterDeleteAll(res: any): void {
        this._toast.showMessage(res);
        this.globalData.current_page = 1;
        this.checkedAll = false;
        this.getItems();
    }

}
