import { Injectable } from '@angular/core';
import { LoginDataModel } from 'app/core/models/loginData.model';

@Injectable({ providedIn: 'root' })
export class SessionService {
    private apiName: string = 'urlAPIResolution';
    private lng: string = 'default_language';
    private loginName: string = 'loggedUser';
    private tokenName: string = 'token';
    private alertName: string = 'alerts';


    clearSession(): void {
        localStorage.removeItem(this.tokenName);
        localStorage.removeItem(this.loginName);
    }

    // Default lng
    setLng(value: string): void {
        this.setSession(this.lng, value);
    }

    getLng(): string {
        return this.getSession(this.lng);
    }

    // Token
    setToken(value: string): void {
        this.setSession(this.tokenName, value);
    }

    getToken(): string {
        return this.getSession(this.tokenName);
    }

    // Alert
    setAlertOn(): void {
        this.setSession(this.alertName, 'enabled');
    }

    setAlertOff(): void {
        this.setSession(this.alertName, 'disabled');
    }

    getAlert(): string {
        return this.getSession(this.alertName);
    }

    // Login
    setLogin(value: LoginDataModel): void {
        this.setSession(this.loginName, JSON.stringify(value));
    }

    getLogin(): LoginDataModel {
        return JSON.parse(this.getSession(this.loginName));
    }

    // Api
    setApi(value: string): void {
        this.setSession(this.apiName, value);
    }

    getApi(): string {
        return this.getSession(this.apiName);
    }

    private setSession(name: string, value: string): void {
        localStorage.setItem(name, value);
    }

    private getSession(name: string): string {
        const session: any = localStorage.getItem(name);
        return session || null;
    }
}

