import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/services/auth.guard';
import { LandingsListComponent } from './components/landings-list/landings-list.component';
import { PointsListComponent } from './components/points-list/points-list.component';
import { RoutesListComponent } from './components/routes-list/routes-list.component';
import { EnvironmentComponent } from './environment.component';

export const EnvironmentRouting: Routes = [
    {
        path: ':idsite/environment', component: EnvironmentComponent, canActivate: [AuthGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'points', canActivate: [AuthGuard] },
            { path: 'points', component: PointsListComponent, canActivate: [AuthGuard] },
            { path: 'routes', component: RoutesListComponent, canActivate: [AuthGuard] },
            { path: 'landings', component: LandingsListComponent, canActivate: [AuthGuard] },
        ],
    },
];
