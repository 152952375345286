import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-landings-list',
    templateUrl: './landings-list.component.html',
})
export class LandingsListComponent {
    @Input() select: boolean = false;
    public lang: any;
    public dataCallback: any;

    constructor(private _lng: TranslateService) {
        this._lng.get('landing').subscribe(res => this.lang = res);

        this.dataCallback = {
            template: 'environment-landing-view-template',
            moduleType: 'landings',
            modulePage: 'environment/landings',
            sourceModule: 'environmentLanding',
        };
    }
}
