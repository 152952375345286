import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormOptionsModel } from 'app/core/models/formOptions.model';

@Component({
    selector: 'app-form-input',
    templateUrl: './form-input.component.html',
})
export class FormInputComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() formOptions: FormOptionsModel = {};
    @Input() item: any;
    @Input() autocompleteItem: any;
    @Output() updated: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
        this.setAutocomplete();
    }

    onBlur(): void {
        this.updated.emit(this.form.controls[this.item.key].value);
    }

    setAutocomplete(): void {
        this.autocompleteItem = this.item.key;
        if (this.item.key.includes('password')) {
            this.autocompleteItem = 'new-password';
        }
        if (this.item.key.includes('username')) {
            this.autocompleteItem = this.item.key.replace('username', 'new-username');
        }
    }
}
