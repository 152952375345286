import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PanelDataModel } from 'app/core/models/panelData.model';

@Component({
    selector: 'app-panel-form-select',
    templateUrl: './panel-form-select.component.html',
})
export class PanelFormSelectComponent {
    @Input() control: AbstractControl;
    @Input() disabledFields: boolean = false;
    @Input() element: PanelDataModel;
    @Output() updatedAll: EventEmitter<string> = new EventEmitter<string>();

    onUpdatedAll(value: string): void {
        this.updatedAll.emit(value);
    }
}
