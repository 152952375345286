import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LanguageModel } from 'app/core/models/allData.model';
import { SingeltonService } from 'app/core/services/singelton.service';

@Component({
    selector: 'app-panel-form-lang-bar',
    templateUrl: './panel-form-lang-bar.component.html',
})
export class PanelFormLangBarComponent implements OnInit {
    @Output() lang: EventEmitter<string> = new EventEmitter<string>();
    public active: LanguageModel;
    public languages: LanguageModel[];

    constructor(private _st: SingeltonService) {
        this.languages = this._st.allData.languages;
        const defaultLng: LanguageModel[] = this.languages.filter(lng => lng.default === 1 || lng.default);
        this.active = defaultLng[0] || null;
    }

    ngOnInit(): void {
        this.onLang(this.active);
    }

    onLang(event: any): void {
        this.lang.emit(event.language);
        this.active = event;
    }

}
