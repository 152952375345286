import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteModel } from 'app/core/models/site.model';
import { SingeltonService } from 'app/core/services/singelton.service';
import { UtilsService } from 'app/core/services/utils.service';
import { TagModel } from 'app/modules/media/models/tag.model';
import { MediaService } from 'app/modules/media/services/media.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-media-tags',
    templateUrl: './media-tags.component.html',
})
export class MediaTagsComponent implements OnInit {
    @Input('noResult') noResult: string;
    @Input('type') type: string;
    @Input() list: any;
    @Output() filter: EventEmitter<{ params: HttpParams, concat: boolean }> = new EventEmitter<{ params: HttpParams, concat: boolean }>();
    @Output() site: EventEmitter<string> = new EventEmitter<string>();
    public actualPage: number = 1;
    public pendingActive: boolean = false;
    public siteList: SiteModel;
    public siteModel: string;
    public tagList: TagModel[] = [];
    private activeTags: string[] = [];
    private idSite: string;

    constructor(private _service: MediaService, private _st: SingeltonService, private _utils: UtilsService) {
    }

    ngOnInit(): void {
        this.idSite = this._st.idSite;

        forkJoin(
            this._service.getSiteTags(this.idSite).pipe(map(res => this.tagList = res))
        ).subscribe();
        /*forkJoin(
            this._service.getSiteTags(this.idSite).pipe(map(res => this.tagList = res)),
            this._utils.getSites().pipe(map(res => {
                this.siteList = res;
                this.siteModel = this._st.idSite;
            })),
        ).subscribe(() => this.onSelectSite(this.idSite));*/
    }

    onFilter(isPending: boolean = false, tag: any = null, concat: boolean = false): void {
        let params = new HttpParams()
            .append('filter_site_id', this.idSite)
            .append('page', this.actualPage.toString())
            .append('filter_type', this.type);

        if (isPending) {
            this.pendingActive = !this.pendingActive;
        }

        if (this.pendingActive) {
            params = params.append('filter_pending', '1');
        }

        if (tag) {
            this.actualPage = 1;
            tag.active = !tag.active;
            this.activeTags.push(tag.tag);
        }

        if (tag && tag.hasOwnProperty('active') && !tag.active) {
            const copy: string[] = [];
            this.activeTags.map(t => {
                if (t !== tag.tag) {
                    copy.push(t);
                }
            });
            this.activeTags = copy.slice();
        }

        if (this.activeTags.length > 0) {
            params = params.append('filter_tags', this.activeTags.join());
        }

        this.filter.emit({ params: params, concat: concat });

    }

    /*onSelectSite(site): void {
        this.activeTags = [];
        this.actualPage = 1;
        this.idSite = site;
        this.pendingActive = false;
        this.site.emit(site);
        this._service.getSiteTags(this.idSite).pipe(map(res => {
            this.tagList = res;
            this.tagList.map((tag, i) => this.tagList[i].active = false);
            this.onFilter();
        })).subscribe();
        // this.tagList.map((tag, i) => this.tagList[i].active = false);
        // this.onFilter();
    }*/

    onShowMore() {
        this.actualPage++;
        this.onFilter(false, null, true);
    }

}
