import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FilterModel } from 'app/core/models/filter.model';
import { AlertPopupService } from 'app/core/services/alert-popup.service';
import { ApiService } from 'app/core/services/api.service';
import { SingeltonService } from 'app/core/services/singelton.service';
import { ToastService } from 'app/core/services/toast.service';
import { CustomValidators } from 'app/core/validators/custom.validators';

@Component({
    selector: 'app-urls-table',
    templateUrl: './urls-table.component.html',
})
export class UrlsTableComponent implements OnInit {
    public data: FilterModel;
    public form: FormGroup;
    public formTable: FormArray;
    public items: any[];
    public itemsDeleted: any[] = [];
    public itemsUpdated: any[] = [];
    public showForm: boolean = false;
    public showTable: boolean = false;
    private lang: any;

    constructor(
        private _api: ApiService,
        private _fb: FormBuilder,
        private _lng: TranslateService,
        private _modal: AlertPopupService,
        private _st: SingeltonService,
        private _toast: ToastService,
    ) {
        this._lng.get('config').subscribe(res => this.lang = res);
    }

    ngOnInit(): void {
        this.getList(null, true);
    }

    getList(next: boolean, reset: boolean = false): void {
        if (reset) {
            this.form = null;
            this.showForm = false;
            this.itemsDeleted = [];
            this.itemsUpdated = [];
        } else {
            this.checkUpdates();
        }

        let params: HttpParams = new HttpParams()
            .append('filter_site_id', this._st.idSite)
            .append('order_field', 'created_at')
            .append('order_direction', 'desc');

        if (reset) {
            params = params.append('page', '1');
        } else {
            params = params.append('page', (next ? this.data.current_page + 1 : this.data.current_page - 1).toString());
        }


        this._api.get('redirects', { params: params }).subscribe((data: FilterModel) => this.buildList(data));
    };

    onDelete(item): void {
        const fun: Function = (resp) => {
            if (resp) {
                this.deleteItem(item);
            }
        };

        this._modal.show({
            title: this.lang.url.delete.title,
            content: this.lang.url.delete.content,
            buttonConfirmType: 'delete',
            buttonConfirmText: this.lang.url.delete.buttonConfirmText,
            buttonCancelType: 'ghost',
            buttonCancelText: this.lang.url.delete.buttonCancelText,
        }, fun.bind(this));
    }

    onDeleteAll(): void {
        const fun: Function = (resp) => {
            if (resp) {
                this.data.data.map(item => this.deleteItem(item));
            }
        };

        this._modal.show({
            title: this.lang.url.delete_all.title,
            content: this.lang.url.delete_all.content,
            buttonConfirmType: 'delete',
            buttonConfirmText: this.lang.url.delete_all.buttonConfirmText,
            buttonCancelType: 'ghost',
            buttonCancelText: this.lang.url.delete_all.buttonCancelText,
        }, fun.bind(this));
    }

    onShowFrom(): void {
        this.form = this._fb.group({
            active: [false, []],
            from: ['', [Validators.required, CustomValidators.web]],
            to: ['', [Validators.required, CustomValidators.web]],
            code: ['301', [Validators.required]],
        });

        this.showForm = true;
    }

    onCancel(): void {
        this.getList(null, true);
    }

    onSave(): void {
        this.checkUpdates();
        const model: any = {
            site_id: this._st.idSite,
            bunch: [...this.itemsDeleted, ...this.itemsUpdated],
        };

        if (this.showForm) {
            model.bunch.push({
                action: 'create',
                site_id: this._st.idSite,
                from: this.form.get('from').value,
                to: this.form.get('to').value,
                code: this.form.get('code').value,
                active: this.form.get('active').value,
            });
        }

        this._api.post('redirects/bunch', model).subscribe(() => {
            this._toast.showMessage('message.urls', true);
            this.ngOnInit();
        });

    }

    onEdit(pos: number) {
        this.items[pos].edit = !this.items[pos].edit;
        this.formTable.markAsDirty();
    }

    private checkUpdates(): void {
        const formValues: any[] = this.formTable.getRawValue();
        this.items.map((item, a) => {
            item = { ...item, ...formValues[a] };
            for (let i = 0; i < this.data.data.length; i++) {
                if (this.data.data[i].id === item.id && JSON.stringify(this.data.data[i]) !== JSON.stringify(item)) {
                    this.itemsUpdated.push({
                        action: 'update',
                        id: item.id,
                        site_id: this._st.idSite,
                        from: item.from,
                        to: item.to,
                        code: item.code,
                        active: item.active,
                    });
                    break;
                }
            }
        });
    }

    private deleteItem(item: any): void {

        this.checkUpdates();

        this.itemsDeleted.push({
            action: 'delete',
            id: item.id,
        });

        // Check no repeat on updated
        const updatesNew: any = [];
        this.itemsUpdated.map(up => {
            if (up.id !== item.id) {
                updatesNew.push(up);
            }
        });
        this.itemsUpdated = updatesNew.slice();

        this.buildList(this.data);
    }

    private buildList(data: FilterModel): void {
        this.showTable = false;
        this.data = JSON.parse(JSON.stringify(data));
        this.items = [];
        const ret: any[] = [];
        this.formTable = new FormArray([]);

        data.data.map(item => {

            // Check delete
            let deleted: boolean = false;
            for (let i = 0; i < this.itemsDeleted.length; i++) {
                if (item.id === this.itemsDeleted[i].id) {
                    deleted = true;
                    break;
                }
            }

            // Updated
            if (!deleted) {
                let updated: boolean = false;
                for (let i = 0; i < this.itemsUpdated.length; i++) {
                    if (item.id === this.itemsUpdated[i].id) {
                        ret.push({
                            ...item,
                            from: this.itemsUpdated[i].from,
                            to: this.itemsUpdated[i].to,
                            code: this.itemsUpdated[i].code,
                            active: this.itemsUpdated[i].active,
                        });
                        updated = true;
                        break;
                    }
                }
                if (!updated) {
                    ret.push(item);
                }
            }
        });

        this.items = ret.slice();

        // Build Form table
        this.items.map(item => {
            this.formTable.push(this._fb.group({
                active: [item.active],
                from: [item.from, [Validators.required, CustomValidators.web]],
                to: [item.to, [Validators.required, CustomValidators.web]],
                code: [item.code, [Validators.required]],
            }));
        });

        if (this.itemsUpdated.length !== 0 || this.itemsDeleted.length !== 0) {
            this.formTable.markAsDirty();
        }

        setTimeout(() => {
            this.showTable = true;
        }, 10);

    }


}
